import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Button,
  Text,
  Input,
  Divider,
  useToast,
  Checkbox,
  Alert,
  AlertIcon,
  HStack,
} from "@chakra-ui/react";

import { SmallCloseIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { inviteMember } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button20 from "../Common/G2Button20";
import { getChildUsers } from "../../Store/Actions/onboardAction";

const InviteModal = ({ isIAddopen, closeIAddModal, groupId }) => {

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toast = useToast();

  const content = useSelector((state) => state.Create.content);
  const groupList = useSelector((state) => state.Create.groupList);
  const childUsers = useSelector((state) => state.Onboard.childUsers);

  const [email, setEmail] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);


  useEffect(() => {
    dispatch(getChildUsers());
  }, []);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(inputEmail));
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    if (isEmptyValue(inputValue)) {
      setValidEmail(true);
    } else {
      validateEmail(inputValue);
    }
  };

  const handleUserSelect = (id) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter((val) => val != id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const handleGroupSelect = (id) => {
    if (selectedGroups.includes(id)) {
      setSelectedGroups(selectedGroups.filter((val) => val != id));
    } else {
      setSelectedGroups([...selectedGroups, id]);
    }
  };

  const handleSaveClick = () => {
    if (selectedGroups.length == 0 || selectedUsers.length == 0) {
      g2Toast({ toast, status: "error", title: "Please selected a Group and a User" });
      return;
    }
    dispatch(inviteMember(toast, selectedUsers, selectedGroups, setSelectedUsers, setSelectedGroups, closeIAddModal, groupId));
  };

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  /*
                <Text w="100%" className="block text-sm font-semibold mt-5 mb-2" style={{ color: `${G2_color_3}` }}>Email</Text>
                <Input w="100%" className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6" style={{ background: `${G2_color_1}`, color: `${G2_color_3}`, borderRadius: "2.5px", }} value={email} onChange={handleEmailChange} />
  {!isValidEmail && <Alert status="error" variant="solid" height="40px" borderRadius="md" className="mb-2 text-black">
    <AlertIcon />
    Invalid email address!
  </Alert>}
  */

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isIAddopen}>
        <ModalOverlay />
        <ModalContent style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
          <ModalBody>
            {
              //JSON.stringify(childUsers)
            }
            <Box w="100%" className="flex-box">
              <Box w="100%" className="flex-box justify-between centering-h mb-[10px]">
                <Box className="flex-box text-left">
                  <Text className="block text-lg font-semibold" style={{ color: `${G2_color_3}` }}>Invite member</Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon className="exit-icon" style={{ color: `${G2_color_3}` }} onClick={closeIAddModal} />
                </Box>
              </Box>

              <Box w="100%" className="mb-3 invite-child-users">
                <HStack justifyContent="space-between">
                  <Text className="block text-sm font-semibold mb-2" style={{ color: `${G2_color_3}` }}>
                    Users
                  </Text>
                  <Text>Missing a User? Add them <NavLink to="/billing">Here</NavLink></Text>
                </HStack>
                {
                  //JSON.stringify(childUsers)
                }
                {childUsers.map((r) =>
                  <Box key={r.id} w="100%" className="invite-child-user">
                    <Checkbox size="md" className="mr-5" borderColor="blue.500" colorScheme="green" isChecked={selectedUsers.includes(r.userId)} onChange={() => handleUserSelect(r.userId)}>
                      {r.name}
                    </Checkbox>
                  </Box>
                )}
              </Box>

              <Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" className="mb-3" />

              <Box w="100%" className="mb-3 invite-groups">
                <Text w="100%" className="block text-sm font-semibold mb-2" style={{ color: `${G2_color_3}` }}>
                  Groups
                </Text>
                {groupList?.map((val, i) => (
                  <Box key={i} w="100%" className="invite-group">
                    <Checkbox size="md" className="mr-5" borderColor="blue.500" colorScheme="green" isChecked={selectedGroups.includes(val._id)} onChange={() => handleGroupSelect(val._id)}>
                      {val.title}
                    </Checkbox>
                  </Box>
                ))}
              </Box>

              <Box w="100%" className="mt-5">
                <G2Button20 disabled={selectedGroups.length == 0 || selectedUsers.length == 0} widthVal="100%" heightVal="40px" text="Add to Groups" onClick={handleSaveClick} />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteModal;
