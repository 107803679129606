import React from 'react';
import G2CKEditor from './G2CKEditor';

const WYSIWYGEditor = ({ value, setValue, ...rest }) => {

    return <G2CKEditor {...rest} value={value} setValue={setValue} />

};


export default WYSIWYGEditor;