
import React, { useState } from "react";
import { useStateContext } from "../ContextProvider/Context";
import { Box, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";



const InnerLeftSideBar = ({ isOpen, setIsOpen, children }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    return <Box w="100%" h="100%" style={{ background: "#49688f", color: "#ffffff" }}>
        {isOpen == true && <Box w="100%" h="100%" className="pb-5">
            <Box w="100%" h="80px" className="centering">
                <IconButton isRound={true} className="normal-hover" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label="Done" fontSize="20px" icon={<ArrowBackIcon />} onClick={() => setIsOpen(!isOpen)} />
            </Box>
            {children}
        </Box>}

        {isOpen != true && <Box w="100%" h="100%" className="pb-5">
            <Box w="100%" h="80px" className="centering">
                <IconButton isRound={true} className="normal-hover" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label="Done" fontSize="20px" icon={<ArrowForwardIcon />} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        </Box>}
    </Box>
}

export default InnerLeftSideBar;