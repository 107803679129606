import React, { useCallback, useRef } from "react";
import { Box, HStack, Input, ListItem, Text, UnorderedList, useToast, VStack } from "@chakra-ui/react";
import G2Button26 from "../Common/G2Button26";
import { getFileUrl, isFile, removeAt } from "../../helpers/general";
import { FaTrash } from "react-icons/fa";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { removeCardAttachment } from "../../Store/Actions/createAction";

const UploadArea = ({ attachments, setAttachments, title, accept = ".*" }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const toast = useToast();

    const handleFileChange = (files) => {
        setAttachments([...files]);
    };

    const removeAttachment = useCallback((index) => {
        const item = attachments[index];
        if (!isFile(item)) {
            dispatch(removeCardAttachment(toast, item.id));
        }
        let temp = _.cloneDeep(attachments);
        temp = temp.filter((r, i) => i != index);
        setAttachments(temp);
    }, [attachments, setAttachments]);

    return <Box w="100%" p="15px">
        <VStack w="100%">
            <G2Button26 text={title} onClick={() => fileInputRef.current.click()} />
            <Input type="file" multiple={true} ref={(element) => fileInputRef.current = element} accept={accept} onChange={(e) => handleFileChange(e.target.files)} style={{ display: "none" }} />
            {(attachments ?? []).length > 0 && <UnorderedList w="100%">
                {(attachments ?? []).map((r, i) => <ListItem key={i}>
                    <HStack w="100%">
                        {isFile(r) && <Text>{isFile(r) ? r.name : r.name}</Text>}
                        {!isFile(r) && <Text><a target="_blank" href={getFileUrl(r.file)}>{isFile(r) ? r.name : r.name}</a></Text>}
                        <FaTrash onClick={() => removeAttachment(i)} />
                    </HStack>
                </ListItem>)}
            </UnorderedList>}
        </VStack>
    </Box>
}

export default UploadArea;