import React, { useState, useEffect } from "react";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";

import { SmallCloseIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";

const HistoryModal = (props) => {
  const { isHOpen, upgrade, closeHistoryModal } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isHOpen}>
        <ModalOverlay />
        <ModalContent
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box
                w="100%"
                className="flex-box justify-between centering-h mb-[10px]"
              >
                <Box className="flex-box text-left">
                  <Text
                    className="block text-lg font-semibold"
                    style={{ color: `${G2_color_3}` }}
                  >
                    View history
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon
                    className="exit-icon"
                    style={{ color: `${G2_color_3}` }}
                    onClick={closeHistoryModal}
                  />
                </Box>
              </Box>
              <Divider
                borderColor={G2_theme_mode == "light" ? "black" : "white"}
                orientation="horizontal"
                className="mb-[10px]"
              />
              <TableContainer
                className="mt-5"
                css={{ color: `${G2_color_3}`, borderRadius: "10px" }}
              >
                <Table>
                  <Thead>
                    <Tr>
                      <Th w="50px" css={{ color: `${G2_color_3}` }}>
                        No
                      </Th>
                      <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                        Time
                      </Th>
                      <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                        Amount
                      </Th>
                      <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                        Comment
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {upgrade.map((val, i) => (
                      <Tr key={i}>
                        <Td textAlign="center">{i + 1}</Td>
                        <Td textAlign="center">
                          {val.time.slice(0, 19).replace("T", " ")}
                        </Td>
                        <Td textAlign="center">{val.amount + " %"}</Td>
                        <Td textAlign="center">{val.comment}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HistoryModal;
