import React from "react";
import { DeleteIcon } from '@chakra-ui/icons';

import { useStateContext } from '../../ContextProvider/Context';

const G2Button19 = ({ widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="btn-small rounded-sm bg-[#e53e3e] lineHeightrounded-[8px] text-center font-semibold text-white text-sm shadow-sm hover:bg-[#c12f2f]"
    >
      <DeleteIcon className="mr-2" />{text}
    </button>
  );
}

export default G2Button19;