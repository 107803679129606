import React from "react";
import { useNavigate } from "react-router-dom";

const PricingTier = ({
  title,
  description,
  features,
  buttonText = "CREATE ACCOUNT",
  isForm = false,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!isForm) {
      navigate("/signUp", { state: { tierType: title } }); // Pass the tier type to the signup page
    }
  };

  return (
    <div
      className="bg-[var(--orange)] px-8 py-5 rounded-lg flex flex-col"
      id="end"
    >
      <h2 className="text-white text-2xl font-bold mb-4">{title}</h2>
      <p className="text-white mb-5">{description}</p>

      <ul className="text-white mb-5 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <span className="mr-2">•</span>
            <span>{feature}</span>
          </li>
        ))}
      </ul>

      <button
        onClick={handleButtonClick}
        className="w-full bg-dark-blue text-white py-3 px-6 rounded hover:bg-[#2a956e] transition-colors"
      >
        {isForm ? "FILL IN FORM" : buttonText}
      </button>
    </div>
  );
};

const PricingTiers = () => {
  const tiers = [
    {
      title: "LITE",
      description: "School Students, Apprentices, Job Seekers, Parents.",
      features: [
        "My Dashboard",
        "Passport",
        "Messages",
        "Learn",
        "Jobs",
        "Events",
        "Goals",
      ],
    },
    {
      title: "PERSONAL",
      description:
        "Trades Workers, Tradies, Managers, Business Owners, Support People.",
      features: [
        "My Dashboard",
        "Passport",
        "Messages",
        "Create (3x courses)",
        "Learn",
        "Marketplace",
        "Connect",
        "Jobs",
        "Events",
        "Goals",
      ],
    },
    {
      title: "BUSINESS",
      description:
        "Trade Businesses, Content Creators, Paid Mentors, Trade Brands/Sponsors.",
      features: [
        "My Dashboard",
        "Profile",
        "Messages",
        "Create (50x courses)",
        "Learn",
        "Marketplace",
        "Connect",
        "Jobs",
        "Events",
        "Goals",
        "Affiliate",
        "Control Panel (Manage your users features)",
      ],
    },
    {
      title: "NON-FOR-PROFIT",
      description:
        "Job Service Organisations, Industry Associations, Apprenticeship Organisations.",
      features: ["Contact us to build better industries."],
      isForm: true,
    },
  ];

  return (
    <div className="w-full mt-10 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {tiers.map((tier, index) => (
            <PricingTier
              key={index}
              title={tier.title}
              description={tier.description}
              features={tier.features}
              isForm={tier.isForm}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingTiers;
