import React from "react";
import { Box, Select, Text } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";

import G2Input from "../Common/G2Input";
import G2Label2 from "../Common/G2Label2";
import G2Label4 from "../Common/G2Label4";

import { industryData } from "../../constant";

const IndustryDetailsForm = ({
  data,
  handleFieldChange1,
  handleFieldChange2,
}) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box width="100%" h="50px"></Box>
      <Box className={`font-bold text-[18px] text-start`}>Industry Details</Box>
      <Box width="100%" h="30px"></Box>
      <Box className="grid grid-cols-1 gap-x-10 sm:grid-cols-2">
        <Box className="mt-3">
          <G2Label4
            className="block text-sm font-semibold leading-6"
            label="Select Industry"
          />
          <Box className="mt-1">
            <Select
              name="industry"
              value={data.industry}
              onChange={handleFieldChange2}
              style={{
                background: `${
                  G2_theme_mode == "light" ? "#FFFFFF" : "#272a3d"
                }`,
              }}
            >
              {industryData.map((val, index) => (
                <option
                  key={index}
                  value={val}
                  className="bg-gray-500"
                  style={{
                    background: `${
                      G2_theme_mode == "light" ? "#FFFFFF" : "#272a3d"
                    }`,
                  }}
                >
                  {val}
                </option>
              ))}
              <option
                value="Others"
                className="bg-gray-500"
                style={{
                  background: `${
                    G2_theme_mode == "light" ? "#FFFFFF" : "#272a3d"
                  }`,
                }}
              >
                Others
              </option>
            </Select>
          </Box>
        </Box>
        {data.industry == "Others" ? (
          <G2Input
            type="text"
            label="Write Industry name here"
            name="customIndustry"
            value={data.customIndustry}
            onChange={handleFieldChange1}
          />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default IndustryDetailsForm;
