import React from "react";

import { useStateContext } from '../../ContextProvider/Context';

import preview from "../../assets/marketplace/preview.png";
import save from "../../assets/marketplace/save.png";

const G2Button13 = ({ widthVal, heightVal, text, icon, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="bg-[var(--orange)] text-white hover:bg-[#2a956e] rounded-sm flex justify-center items-center text-center font-semibold"
    >
      <img
        width="30px"
        height="30px"
        className="responsive-img pr-2"
        alt="icon"
        src={`${icon == "preview" ? preview : save}`}
      />
      {text}
    </button>
  );
}

export default G2Button13;