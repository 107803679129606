const initialState = {
    invitation: [],
    memberList: [],
    group: {},
    contentList: [],
    content: {},
    unitList: [],
    cardList: [],
    card: {},
    activity: {},
    attachment: ""
};

const learnReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LEARN_GET_INVITATION":
            return {
            ...state,
            invitation: action.payload
            };
        
        case "LEARN_GET_MEMBERLIST":
            return {
            ...state,
            memberList: action.payload
            };
        case "LEARN_SET_GROUP":
            return {
            ...state,
            group: action.payload
            };
        case "LEARN_GET_CONTENTLIST":
            return {
            ...state,
            contentList: action.payload
            };
        case "LEARN_SET_CONTENT":
            return {
            ...state,
            content: action.payload
            };
        case "LEARN_GET_UNITLIST":
            return {
            ...state,
            unitList: action.payload
            };
        case "LEARN_GET_CARDLIST":
            return {
            ...state,
            cardList: action.payload
            };
        case "LEARN_SET_CARD":
            return {
            ...state,
            card: action.payload
            };
        case "LEARN_GET_ACTIVITY":
            return {
            ...state,
            activity: action.payload
            };
        case "LEARN_GET_ATTACHMENT":
            return {
            ...state,
            attachment: action.payload
            };
        default:
            return state;
    }
}

export default learnReducer;