import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const sendChatMessage = (toast, targetType, targetId, text) => {
    return (dispatch) => {
        g2AxiosT
            .post("?class=ControllerChat&method=sendChat", { targetType, targetId, message: text })
            .then((res) => {
                dispatch({ type: "GET_CHATLOGS", payload: res.data.chatLogs, channel: (targetType ?? "") + '|' + (targetId ?? "") });
                g2Toast({ toast, status: "success", title: "Posted" });
            })
            .catch((error) => {
                g2Toast({ toast, status: "error", title: "Authentication failed!" });
                console.error("Error getting chat data:", error);
            });
    };
};

const getChatMessages = (toast, targetType, targetId) => {
    return (dispatch) => {
        g2AxiosT
            .post("?class=ControllerChat&method=getChatLogs", { targetType, targetId })
            .then((res) => {
                dispatch({ type: "GET_CHATLOGS", payload: res.data?.chatLogs ?? [], channel: (targetType ?? "") + '|' + (targetId ?? "") });
            })
            .catch((error) => {
                g2Toast({ toast, status: "error", title: "Authentication failed!" });
                console.error("Error getting chat data:", error);
            });
    };
};

const getChatChannels = (toast) => {
    return (dispatch) => {
        g2AxiosT
            .get("?class=ControllerChat&method=getChatChannels")
            .then((res) => {
                dispatch({ type: "GET_CHATCHANNELS", payload: res.data.channels });
            })
            .catch((error) => {
                g2Toast({ toast, status: "error", title: "Authentication failed!" });
                console.error("Error getting chat data:", error);
            });
    };
};

export {
    sendChatMessage,
    getChatMessages,
    getChatChannels
};
