import { Box, Card, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { useStateContext } from "../ContextProvider/Context";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import { useNavigate, useParams } from "react-router-dom";
import { forgotPasswordRequest, verifyEmail } from "../Store/Actions/authAction";
import { useDispatch } from "react-redux";
import _ from "lodash";
import G2Button6 from "../Components/Common/G2Button6";

function ForgottenPasswordPage() {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const { token } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        password1: "",
        password2: "",
    });

    const getValue = function (data, path, defaultValue) {
        return _.get(data, path) ?? defaultValue;
    }

    const changeValue = function (data, path, value, set) {
        let temp = _.cloneDeep(data);
        temp = _.set(temp, path, value);
        set(temp);
    }

    const isValidPassword = function (data) {
        return data.password1.length > 5 && data.password1 == data.password2;
    };

    const resetPassword = function () {
        dispatch(forgotPasswordRequest(toast, navigate, data.password1, token));
    }

    return <Box
        minH="100vh"
        style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
        <Navbar title="Verify Email" />
        <Box w="100%" className="flex-box">
            <Box className="universal-body-w flex-box">
                <Box w="100%" className="setting-box mt-[70px] mb-[30px]">
                    <Card p="15px">
                        <label style={{ color: `${G2_color_3}` }} className="block text-sm font-medium text-gray-900">Password</label>
                        <input
                            type="password"
                            value={getValue(data, 'password1', '')}
                            onChange={(e) => changeValue(data, 'password1', e.target.value, setData)}
                            required
                            style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
                            className="my-3 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label style={{ color: `${G2_color_3}` }} className="block text-sm font-medium text-gray-900">Verify Password</label>
                        <input
                            type="password"
                            value={getValue(data, 'password2', '')}
                            onChange={(e) => changeValue(data, 'password2', e.target.value, setData)}
                            required
                            style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
                            className="my-3 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {!isValidPassword(data) && <>
                            <label className="block text-sm font-medium text-red-500">Passwords must be at least 6 characters and they must match</label>
                        </>}
                        {isValidPassword(data) && <><G2Button6 widthVal="100%" text="Change Password" onClick={resetPassword} /></>}
                    </Card>
                </Box>
            </Box>
        </Box>
    </Box>

}

export default ForgottenPasswordPage;