import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Image,
  Input,
  IconButton,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";

import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import plan from "../assets/create/plan.png";

import { useStateContext } from "../ContextProvider/Context";
import { updateSetting1, updateSetting2 } from "../Store/Actions/settingAction";
import g2Toast from "../Custom/g2Toast";

import Navbar from "../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import isEmptyValue from "../Custom/isEmptyValue";

import G2Input from "../Components/Common/G2Input";
import G2Label4 from "../Components/Common/G2Label4";
import StreetAddressInput from "../Components/Common/StreetAddressInput";

import G2Button20 from "../Components/Common/G2Button20";

import camera from "../assets/camera.png";
import { getServerUrl } from "../helpers/location";
import { getFileUrl } from "../helpers/general";
import SocialConnectForm from "../Components/OnBoardingForm/SocialConnectForm";
import _ from "lodash";
import SearchBox from "../Components/Academy/SearchBox";

import badge1 from "../assets/badges/badge-medal-svgrepo-com.svg";
import badge2 from "../assets/badges/badge-saint-patrick-svgrepo-com.svg";
import badge3 from "../assets/badges/badge-svgrepo-com.svg";
import badge4 from "../assets/badges/badges-money-svgrepo-com.svg";
import badge5 from "../assets/badges/medal-badge-svgrepo-com.svg";
import BadgeComponent from "../Components/Academy/Badge";

const badges = [
  {
    id: 1,
    icon: badge1,
    name: "Achiever",
    description: "Awarded for reaching a major milestone.",
  },
  {
    id: 2,
    icon: badge2,
    name: "Explorer",
    description: "Granted to those who discover new areas or features.",
  },
  {
    id: 3,
    icon: badge3,
    name: "Contributor",
    description: "Given for making valuable contributions.",
  },
  {
    id: 4,
    icon: badge4,
    name: "Champion",
    description: "A badge for excelling in a specific field.",
  },
  {
    id: 5,
    icon: badge5,
    name: "Helper",
    description: "Awarded for assisting others effectively.",
  },
];

const SettingPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const user = useSelector((state) => state.Auth.user);
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const [data, setData] = useState({ data: {} });
  const [delAvatar, setDelAvatar] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setData(user);
    console.log(user);
  }, [user]);

  const updateData = (path, value) => {
    let temp = _.cloneDeep(data);
    temp = _.set(temp, path, value);
    setData(temp);
  };

  const onClickFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (event.target.files.length == 0) {
      return;
    }

    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = "." + selectedFile.name.split(".").pop();

    if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
      g2Toast({ toast, status: "error", title: "Invalid file type!" });
      return;
    }

    setAvatarFile(selectedFile);
    setAvatarUrl(URL.createObjectURL(selectedFile));
  };

  const handleAvatarDelete = () => {
    setDelAvatar(true);

    setAvatarFile(null);
    setAvatarUrl(null);
  };

  const handleUpdateClick = () => {
    const postData = {
      name: data.name,
      gender: data.data.gender,
      birthday: data.data.birthday,
      phone: data.data.phone,
      address: data.data.address,
      socialMedia: data.data.socialMedia,
    };

    const formData = new FormData();

    formData.append("file", avatarFile);

    formData.append("data", JSON.stringify(postData));

    if (!isEmptyValue(data.name)) {
      if (!isEmptyValue(avatarUrl)) {
        dispatch(updateSetting1(toast, formData, setDelAvatar));
      } else {
        dispatch(updateSetting2(toast, postData));
      }
    } else {
      g2Toast({ toast, status: "error", title: "Input full name!" });
    }
  };

  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Navbar title="Setting" />
      <Box w="100%" className="flex-box">
        <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>

        <Box className="universal-body-w">
          {/* /////////////////////////////////////////////////////////////////////////// */}

          <Box
            w="100%"
            h="80px"
            bg={G2_color_2}
            className="flex-box centering-h px-[30px] shadow"
            justifyContent="space-between"
          >
            <Box className="flex-box text-left centering-h">
              <Image boxSize="30px" src={plan} alt="icon" />
              <Text fontSize="16px" className="font-semibold mx-[10px]">
                Digital Profile
              </Text>
            </Box>
            <Box className="flex-box text-right centering-h">
              <Box className="mr-5 flex">
                <SearchBox />
              </Box>
            </Box>
          </Box>
          <Box
            className="w-full h-52"
            style={{ background: "#49688f", color: "#ffffff" }}
          >
            <Box w="100%" className="centering-w pt-[50px] pb-[20px]">
              <Box className="onboard-avatar-box centering">
                {avatarUrl == null && delAvatar ? (
                  <Box w="100%" className="centering">
                    <Box w="25px">
                      <Image
                        boxSize="100%"
                        src={camera}
                        alt="avatar"
                        onClick={onClickFileInput}
                        className="normal-hover"
                      />
                      <Input
                        type="file"
                        ref={fileInputRef}
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box w="100%" h="100%" className="relative">
                    <Box w="100%">
                      <Image
                        boxSize="100%"
                        src={!delAvatar ? getFileUrl(user?.avatar) : avatarUrl}
                        alt="avatar"
                      />
                      <IconButton
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        variant="outline"
                        isRound={true}
                        color="black"
                        fontSize="15px"
                        icon={<DeleteIcon />}
                        onClick={handleAvatarDelete}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* /////////////////////////////////////////////////////////////////////////// */}

          <Box w="100%" className="setting-box">
            <Box className="grid grid-cols-2 gap-x-10 sm:grid-cols-2">
              <G2Input
                type="text"
                label="Name"
                name="name"
                value={data.name}
                onChange={(e) => updateData(["name"], e.target.value)}
              />
              <G2Input
                type="email"
                label="Email"
                name="email"
                value={data.email}
                onChange={(e) => updateData(["email"], e.target.value)}
              />
              <Box className="mt-3">
                <G2Label4
                  className="block text-sm font-semibold leading-6"
                  label="Gender"
                />
                <Select
                  className="mt-1"
                  name="gender"
                  value={data?.data?.gender ?? "Male"}
                  onChange={(e) =>
                    updateData(["data", "gender"], e.target.value)
                  }
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                  }}
                >
                  <option
                    value="Male"
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                  >
                    Male
                  </option>
                  <option
                    value="Female"
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                  >
                    Female
                  </option>
                </Select>
              </Box>
              <Box className="mt-3">
                <Text
                  className="block text-sm font-semibold leading-6"
                  style={{ color: `${G2_color_3}` }}
                >
                  Date of birth
                </Text>
                <Box className="mt-1">
                  <Input
                    className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                    type="date"
                    name="birthday"
                    value={data?.data?.birthday ?? ""}
                    onChange={(e) =>
                      updateData(["data", "birthday"], e.target.value)
                    }
                  />
                </Box>
              </Box>
              <G2Input
                type="text"
                label="Phone"
                name="phone"
                value={data?.data?.phone ?? ""}
                onChange={(e) => updateData(["data", "phone"], e.target.value)}
              />
              <StreetAddressInput
                address={data?.data?.address ?? ""}
                setAddress={(v) => updateData(["data", "address"], v)}
                label="Address"
              />
            </Box>
          </Box>

          <Box w="100%" className="setting-box" pt="15px">
            <G2Label4
              className="block text-sm font-semibold leading-6 mb-[15px]"
              label="Socials"
            />
            <SocialConnectForm
              userData={data?.data ?? {}}
              setUserData={(v) => updateData(["data"], v)}
            />
          </Box>

          {/* <Box w="100%" className="setting-box" pt="15px">
            <Text className="font-semibold" fontSize="16px">
              Badge
            </Text>
            <Box display="flex" gap={3} ml={4} mt={2}>
              {badges.map((badge) => (
                <BadgeComponent key={badge.id} badge={badge} />
              ))}
            </Box>
          </Box> */}

          <Box w="100%" className="centering-w mt-[30px]">
            <G2Button20
              widthVal="200px"
              heightVal="40px"
              text="Update with current data"
              onClick={handleUpdateClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingPage;
