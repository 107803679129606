import goal from "../assets/dashboard/goal-icon.png";

import create from "../assets/dashboard/create-icon.png";
import learn from "../assets/dashboard/learn-icon.png";
import marketplace from "../assets/dashboard/marketplace-icon.png";
import life from "../assets/dashboard/life-icon.png";
import academy from "../assets/dashboard/academy-icon.png";

import settings from "../assets/dashboard/settings.png";
import billings from "../assets/dashboard/billings.png";

import todo from "../assets/dashboard/to-do.png";
import cardstofinish from "../assets/dashboard/cards-to-finish.png";
import reviews from "../assets/dashboard/reviews.png";

import { RiBankFill } from "react-icons/ri";
import { ImTrophy, ImTicket, ImBriefcase, ImVideoCamera, ImConnection } from "react-icons/im";
import { FaBuilding, FaCog, FaIdCard, FaLifeRing, FaLink, FaReceipt, FaUnlink } from "react-icons/fa";
import { MdMarkUnreadChatAlt, MdMenuBook, MdShop, MdShop2, MdSpeed } from "react-icons/md";
import _ from "lodash";


const DashboardGroup = [
	{
		icon: MdSpeed,
		text: "Dashboard",
		path: "/dashboard",
	},
	{
		icon: FaIdCard,
		text: "Passport",
		path: "/academy/digital",
	},
	{
		icon: MdMarkUnreadChatAlt,
		text: "Messages",
		path: "/chat",
	},
];

const MainGroup = [
	{
		icon: ImVideoCamera,
		text: "Create",
		path: "/create",
		accessRights: ["create"]
	},
	{
		icon: MdMenuBook,
		text: "Learn",
		path: "/learn",
		accessRights: ["learn"]
	},
	{
		icon: FaBuilding,
		text: "Marketplace",
		path: "/marketplace",
		accessRights: ["marketplace"]
	},
	{
		icon: FaLink,
		text: "Connections",
		path: "/life",
		accessRights: ["connections"]
	},
];

const AcademyGroup = [
	{
		icon: ImTrophy,
		text: "Competitions",
		path: "/academy/leader",
	},
	{
		icon: ImTicket,
		text: "Events",
		path: "/academy/event",
	},
	{
		icon: ImBriefcase,
		text: "Jobs",
		path: "/academy/job",
	},
];

const GoalsGroup = [
	{
		icon: FaLifeRing,
		text: "Goal",
		path: "/goal",
	},
];

const WorkGroup = [
	{
		icon: FaCog,
		text: "To do",
		path: "/dashboard",
	},
	{
		icon: FaCog,
		text: "Cards to finish",
		path: "/dashboard",
	},
	{
		icon: FaCog,
		text: "Reviews",
		path: "/dashboard",
	},
];

const MoneyGroup = [
	{
		icon: FaCog,
		text: "Settings",
		path: "/setting",
	},
	{
		icon: RiBankFill,
		text: "Billings",
		path: "/billing",
	},
	{
		icon: FaReceipt,
		text: "Affiliate",
		path: "/affiliate",
	},
];


const allMenuOptions = [
	DashboardGroup,
	MainGroup,
	AcademyGroup,
	GoalsGroup,
	MoneyGroup,
];

const hasAccessRights = (accessRights, requiredAccessRights) => {
	let results = true;
	if (_.isString(requiredAccessRights)) {
		return accessRights.filter(r => r.name == requiredAccessRights && r.isOn == 1).length > 0;
	}

	for (let requiredAccessRight of requiredAccessRights) {
		results &= hasAccessRights(accessRights, requiredAccessRight);

		if (!results) {
			return false;
		}
	}

	return results;
}

export const getFilteredMenu = (accessRights) => {
	if (accessRights === undefined) {
		return [];
	}

	let menu = [];

	if (accessRights == undefined) {
		accessRights = [];
	}

	for (var group of allMenuOptions) {

		let subMenu = [];
		for (var item of group) {
			if (item.accessRights == undefined) {
				subMenu.push(item);
				continue;
			}

			if (hasAccessRights(accessRights, item.accessRights)) {
				subMenu.push(item);
				continue;
			}

		}

		if (subMenu.length > 0) {
			menu.push(subMenu);
		}
	}

	return menu;
}