import React from "react";

import { useStateContext } from '../../ContextProvider/Context';

const G2InputC = ({ label, name, type, value }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <div className="mt-3" style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <label
          htmlFor="first-name"
          style={{ color: `${G2_color_3}` }}
          className="block text-sm font-semibold leading-6"
        >
          {label}
        </label>
        <div className="mt-1">
          <input
            type={type}
            name={name}
            defaultValue={value}
            readOnly
            id={name}
            style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
          />
        </div>
      </div>
    </div>
  );
}

export default G2InputC;
