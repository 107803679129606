import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const registUserDataWithImage = (
  toast,
  formData,
  activeStep,
  setActiveStep
) => {
  return (dispatch) => {
    g2AxiosT
      .post("/onboard/register/a", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        g2Toast({ toast, status: "success", title: res.data.msg });
        setActiveStep(activeStep + 1);
        dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
        dispatch({
          type: "AUTH_GET_USERDATA",
          payload: res.data.userData,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering user data:", error);
      });
  };
};

const registUserData = (toast, userData, activeStep, setActiveStep) => {
  return (dispatch) => {
    g2AxiosT
      .post("/onboard/register/b", userData)
      .then((res) => {
        g2Toast({ toast, status: "success", title: res.data });
        setActiveStep(activeStep + 1);
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering user data:", error);
      });
  };
};

const getUserTypes = () => {
  return (dispatch) => {
    g2Axios
      .post("/", { "class": "ControllerAccess", "method": "getUserTypes" })
      .then((res) => {
        dispatch({ type: "SYSTEM_USER_TYPES", payload: res.data });
      })
      .catch((error) => {
        console.error("Error failed Getting System Content", error);
      });
  };
}

const getAvailableSubscriptions = () => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "getAvailableSubscriptions" })
      .then((res) => {
        dispatch({ type: "SYSTEM_AVAILABLE_SUBSCRIPTIONS", payload: res.data });
      })
      .catch((error) => {
        console.error("Error failed Getting Available Subscriptions", error);
      });
  };
}


const getUserSubscriptions = () => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "getUserSubscriptions" })
      .then((res) => {
        dispatch({ type: "USER_SUBSCRIPTIONS", payload: res.data });
      })
      .catch((error) => {
        console.error("Error failed Getting User Subscriptions", error);
      });
  };
}

const getChildUsers = () => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "getChildUsers" })
      .then((res) => {
        dispatch({
          type: "CHILD_USERS", payload: res.data
        });
      })
      .catch((error) => {
        console.error("Error failed Getting Child User Subscriptions", error);
      });
  };
}

const setChildUserFunding = (childUserId, isFunded) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "setChildUserFunding", childUserId, isFunded })
      .then((res) => {
        dispatch({
          type: "CHILD_USERS", payload: res.data
        });
      })
      .catch((error) => {
        console.error("Error failed Getting Child User Subscriptions", error);
      });
  };
}

const removeChildUser = (childUserId) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "removeChildUser", childUserId })
      .then((res) => {
        dispatch({
          type: "CHILD_USERS", payload: res.data
        });
      })
      .catch((error) => {
        console.error("Error failed Getting Child User Subscriptions", error);
      });
  };
}

const inviteChildUser = (name, email, parentInitiated) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "inviteChildUser", name, email, parentInitiated })
      .then((res) => {
        dispatch({
          type: parentInitiated ? "CHILD_USERS" : "PARENT_USERS", payload: res.data
        });
      })
      .catch((error) => {
        console.error("Error failed Getting Child User Subscriptions", error);
      });
  };
}

const getParentUsers = (name, email) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "getParentUsers" })
      .then((res) => {
        dispatch({
          type: "PARENT_USERS", payload: res.data
        });
      })
      .catch((error) => {
        console.error("Error failed Getting Child User Subscriptions", error);
      });
  };
}

const updateParentUser = (userParentId, accept) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerBilling", "method": "updateParentUser", userParentId, accept })
      .then((res) => {
        dispatch({
          type: "PARENT_USERS", payload: res.data
        });
      })
      .catch((error) => {
        console.error("Error failed Getting Child User Subscriptions", error);
      });
  };
}



export {
  registUserDataWithImage,
  registUserData,
  getUserTypes,
  getAvailableSubscriptions,
  getUserSubscriptions,
  getChildUsers,
  setChildUserFunding,
  removeChildUser,
  inviteChildUser,
  getParentUsers,
  updateParentUser
};
