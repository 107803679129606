import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Image,
  useToast,
  Input,
  IconButton,
  Textarea,
  AspectRatio,
  Spinner,
  UnorderedList,
  ListItem,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  Badge,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon, DeleteIcon } from "@chakra-ui/icons";
import SocialConnectForm from "../../Components/OnBoardingForm/SocialConnectForm";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";

// import AddModal from "./AddModal";
import plan from "../../assets/create/plan.png";
import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button24 from "../Common/G2Button24";
import G2Button26 from "../Common/G2Button26";
import { getServerUrl } from "../../helpers/location";
import {
  getFileUrl,
  isFile,
  profileUploadTypes,
  removeAt,
} from "../../helpers/general";
import SearchBox from "./SearchBox";
import {
  FaQuestion,
  FaQuestionCircle,
  FaRegThumbsDown,
  FaRegThumbsUp,
} from "react-icons/fa";
import {
  actionFollowRequest,
  uploadExteriorResults,
} from "../../Store/Actions/academyAction";
import _ from "lodash";
import DynamicFileViewer from "../DynamicFileViewer";
import ExternalFileLoader from "../ExternalFileLoader";
import { formatDate } from "../../helpers/formatters";

import BadgeComponent from "./Badge";
import badge1 from "../../assets/badges/badge-medal-svgrepo-com.svg";
import badge2 from "../../assets/badges/badge-saint-patrick-svgrepo-com.svg";
import badge3 from "../../assets/badges/badge-svgrepo-com.svg";
import badge4 from "../../assets/badges/badges-money-svgrepo-com.svg";
import badge5 from "../../assets/badges/medal-badge-svgrepo-com.svg";
import G2Label4 from "../Common/G2Label4";

const badges = [
  {
    id: 1,
    icon: badge1,
    name: "Achiever",
    description: "Awarded for reaching a major milestone.",
  },
  {
    id: 2,
    icon: badge2,
    name: "Explorer",
    description: "Granted to those who discover new areas or features.",
  },
  {
    id: 3,
    icon: badge3,
    name: "Contributor",
    description: "Given for making valuable contributions.",
  },
  {
    id: 4,
    icon: badge4,
    name: "Champion",
    description: "A badge for excelling in a specific field.",
  },
  {
    id: 5,
    icon: badge5,
    name: "Helper",
    description: "Awarded for assisting others effectively.",
  },
];

const DynamicDisplayFile = ({
  file,
  maxW = "400px",
  onClick = () => {},
  showClickToView = true,
}) => {
  return (
    <Box onClick={onClick}>
      <AspectRatio maxW={maxW} ratio={1}>
        <DynamicFileViewer file={file} />
      </AspectRatio>
      {showClickToView == true && (
        <Text textAlign="center" cursor="pointer">
          {" "}
          Click to View
        </Text>
      )}
    </Box>
  );
};

const UploadButton = ({ passport, setPassport, type, boxW1 }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (type, files) => {
    if (files.length == 0) {
      return;
    }
    const maxMB = 50;
    const maxSize = maxMB * 1024 * 1024;
    for (let file of files) {
      if (file.size > maxSize) {
        alert(
          `File '${file.name}' size exceeds the maximum allowed size of ` +
            maxMB +
            " MB."
        );
        return;
      }
    }

    files = Array.from(files);
    let currentFiles = _.get(passport, type.path) ?? [];
    setPassport(type.path, [...currentFiles, ...files]);
  };

  const files = _.get(passport, type.path) ?? [];

  return (
    <Box w={boxW1} className="centering-w">
      <VStack w="100%">
        <G2Button26
          text={type.buttonName}
          onClick={() => fileInputRef.current.click()}
        />
        <Input
          type="file"
          multiple={true}
          ref={(element) => (fileInputRef.current = element)}
          accept={type.accept}
          onChange={(e) => handleFileChange(type, e.target.files)}
          style={{ display: "none" }}
        />
      </VStack>
    </Box>
  );
};

const DgitalPassportForm = ({
  screenSize,
  boxW1,
  boxW2,
  passport,
  setPassport,
  saveCallback,
}) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();
  const [file, setFile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [importFile, setImportFile] = useState(null);
  const fileInputRef = useRef(null);
  const [data, setData] = useState({ data: {} });
  const user = useSelector((state) => state.Auth.user);
  console.log("user", user);

  console.log("passport", passport);

  const [importRecords, setImportRecords] = useState([]);

  useEffect(() => {
    setImportFile(null);
    setImportRecords([]);
  }, [passport]);

  const getContentCount = useCallback(
    (type) => {
      if (passport == undefined) {
        return 0;
      }
      return _.get(passport, type.path)?.length;
    },
    [passport]
  );

  const removePassportAttachment = useCallback(
    (type, index) => {
      let temp = removeAt(passport, [...type.path, index]);
      setPassport(type.path, _.get(temp, type.path));
    },
    [passport, setPassport]
  );

  const handleUpdateClick = () => {
    console.log("+++++++++");
  };

  return (
    <Box
      className="w-full h-full px-[8%]"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%">
        {
          //JSON.stringify(passport)
        }
        {!passport?.isMyProfile && passport?.isActionable && (
          <Box
            w="100%"
            h="80px"
            bg="var(--orange)"
            className="flex-box centering-h px-[30px] shadow"
            justifyContent="space-between"
          >
            <Box className="flex-box text-left centering-h">
              <FaQuestionCircle fontSize="30px" color="white" />
              <Text fontSize="16px" className="font-semibold mx-[10px]">
                {passport?.name} is requesting Access to your Profile
              </Text>
              <Button
                ml="15px"
                onClick={() =>
                  dispatch(actionFollowRequest(toast, passport?.hashId, 1))
                }
              >
                <FaRegThumbsUp />
              </Button>
              <Button
                ml="15px"
                onClick={() =>
                  dispatch(actionFollowRequest(toast, passport?.hashId, 0))
                }
              >
                <FaRegThumbsDown />
              </Button>
            </Box>
          </Box>
        )}

        {/* <Box
          w="100%"
          h="80px"
          bg={G2_color_2}
          className="flex-box centering-h px-[30px] shadow"
          justifyContent="space-between"
        >
          <Box className="flex-box text-left centering-h">
            <Image boxSize="30px" src={plan} alt="icon" />
            <Text fontSize="16px" className="font-semibold mx-[10px]">
              Digital Passport
            </Text>
          </Box>
          <Box className="flex-box text-right centering-h">
            <Box className="mr-5 flex">
              <SearchBox />
            </Box>
          </Box>
        </Box> */}

        <Box w="100%" px="30px" py="20px">
          {(passport?.isAccepted || passport?.isMyProfile) && (
            <>
              {/* About Me Section */}
              <Box w="100%" mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  About Me
                </Text>
                <Textarea
                  w="100%"
                  className="mt-[5px]"
                  borderRadius="5px"
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                    borderRadius: "2.5px",
                  }}
                  size="md"
                  rows={3}
                  value={passport?.profile?.aboutMe ?? ""}
                  onChange={(e) =>
                    setPassport(["profile", "aboutMe"], e.target.value)
                  }
                />
              </Box>

              {/* <Box mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  Work For
                </Text>
                <Box display="flex" gap={3} ml={4} mt={2}>
                  <Image
                    borderRadius="full"
                    bg="white"
                    boxSize="50px"
                    src={getFileUrl(passport?.avatar)}
                    alt="avatar"
                    onClick={() => navigate("/setting")}
                    cursor="pointer"
                  />
                </Box>
              </Box> */}

              <Box mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  {user.type === "Business" ? "Users" : "Work For"}
                </Text>

                {/* Check if user type is "business" and childUsers array exists and has at least one element */}
                {user.type === "Business" && user.childUsers?.length > 0 ? (
                  <Box display="flex" gap={3} ml={4} mt={2}>
                    {user.childUsers.map((childUser) => (
                      <Image
                        key={childUser.id}
                        borderRadius="full"
                        bg="white"
                        boxSize="50px"
                        src={getFileUrl(childUser.avatar)}
                        alt={`${childUser.name}'s avatar`}
                        onClick={() =>
                          navigate(`/academy/digital/${childUser.hashId}`)
                        }
                        cursor="pointer"
                      />
                    ))}
                  </Box>
                ) : (
                  // If not business or no child users, show default avatar or fallback logic here if needed
                  <Box display="flex" gap={3} ml={4} mt={2}>
                    <Image
                      borderRadius="full"
                      bg="white"
                      boxSize="50px"
                      src={getFileUrl(user?.parentUser?.avatar)}
                      alt="avatar"
                      onClick={() =>
                        navigate(`/academy/digital/${user?.parentUser?.hashId}`)
                      }
                      cursor="pointer"
                    />
                  </Box>
                )}
              </Box>

              {/* Socials */}
              <Box w="100%" className="" pt="15px">
                <G2Label4
                  className="block text-sm font-semibold leading-6 mb-[15px]"
                  label="Socials"
                />
                <Box ml="20px">
                  {" "}
                  {/* Adjust the value as needed */}
                  <SocialConnectForm
                    userData={data?.data ?? {}}
                    setUserData={(v) => console.log(v)}
                  />
                </Box>
              </Box>

              {/* Badges */}
              <Box mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  Badge
                </Text>
                <Box display="flex" gap={3} ml={4} mt={2}>
                  {badges.map((badge) => (
                    <BadgeComponent key={badge.id} badge={badge} />
                  ))}
                </Box>
              </Box>

              {/* Years of Experience Section */}
              {/* <Box w="100%" mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  Years of Experience
                </Text>
                <Input
                  w="100%"
                  className="mt-[5px]"
                  value={passport?.profile?.experience}
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                    borderRadius: "2.5px",
                  }}
                  onChange={(e) =>
                    setPassport(["profile", "experience"], e.target.value)
                  }
                />
              </Box> */}

              {/* Portfolio, Cover Letter, Resume, License, etc. */}
              {profileUploadTypes.map((r) => {
                const content = _.get(passport, r.path);
                return (
                  <Box key={r.title} w="100%" mb="20px ">
                    <div
                      className="w-full items-center pl-5"
                      style={{ position: "relative" }}
                    >
                      <Text className="font-semibold" fontSize="16px">
                        {r.title}
                      </Text>
                      <div style={{ float: "right" }}>
                        {passport?.isMyProfile && (
                          <UploadButton
                            key={r.name}
                            type={r}
                            passport={passport}
                            setPassport={setPassport}
                            showFiles={false}
                            boxW1={boxW1}
                          />
                        )}
                      </div>
                    </div>

                    {r.subtitle && passport?.isMyProfile && (
                      <HStack w="100%">
                        <Text className="mt-5 ml-[5px]" fontSize="16px">
                          {r.subtitle}
                        </Text>
                      </HStack>
                    )}

                    <Box w="100%" className="flex">
                      <Box w="100%" className="flex-box">
                        {(content ?? []).map((val, i) => (
                          <Box key={i} w="20%" className="mt-[10px] p-[10px]">
                            <Box
                              w="100%"
                              className="academy-portfolio-box p-[5px]"
                            >
                              <Box w="100%" h="100%" className="relative">
                                <DynamicDisplayFile
                                  file={val}
                                  onClick={() => {
                                    setFile({
                                      heading: r.title,
                                      file: val,
                                    });
                                    onOpen();
                                  }}
                                />
                                {passport.isMyProfile && (
                                  <IconButton
                                    position="absolute"
                                    right="0px"
                                    variant="outline"
                                    isRound
                                    color="black"
                                    fontSize="15px"
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      removePassportAttachment(r, i)
                                    }
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <hr className="my-3 ml-[10%] w-[65%] border-t-[1px] border-gray-500" />
                  </Box>
                );
              })}

              {/* Followers Section */}
              {passport?.requestingUsers?.length > 0 && (
                <Box w="100%" mb="20px">
                  <div
                    className="w-full items-center"
                    style={{ position: "relative" }}
                  >
                    <Text className="font-semibold" fontSize="16px">
                      Followers
                    </Text>
                    <div style={{ float: "right" }}>
                      {passport?.requestingUsers?.length === 0 && (
                        <Badge colorScheme="orange" ml="5px">
                          {passport?.requestingUsers?.length}
                        </Badge>
                      )}
                    </div>
                  </div>

                  <Box w="100%" pt="5px">
                    <VStack w="100%">
                      {passport?.requestingUsers?.map((r, index) => (
                        <HStack w="100%" key={index}>
                          <Button
                            colorScheme="blue"
                            mr="15px"
                            onClick={() =>
                              navigate(`/academy/digital/` + r.hashId)
                            }
                          >
                            View
                          </Button>
                          <Image
                            borderRadius="full"
                            bg="white"
                            boxSize="32px"
                            src={getFileUrl(r?.avatar)}
                            className="my-[5px]"
                            alt="avatar"
                          />
                          <Text fontWeight="bold">{r.name}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>
                  <hr className="my-16 ml-[10%] w-[65%] border-t-[1px] border-gray-500" />
                </Box>
              )}

              {/* Other Results / Import History Section */}
              {passport?.externalResults?.length > 0 && (
                <>
                  <Box w="100%" mb="20px">
                    <div
                      className="w-full items-center pr-[4.5%] pl-[2%]"
                      style={{ position: "relative" }}
                    >
                      <Text className="font-semibold" fontSize="16px">
                        Import History
                      </Text>
                      {passport?.isMyProfile && (
                        <div style={{ float: "right" }}>
                          <HStack w="100%" justifyContent="flex-end">
                            <G2Button26
                              text="Import File"
                              onClick={() => fileInputRef.current.click()}
                            />
                            <Input
                              type="file"
                              multiple={false}
                              ref={(element) =>
                                (fileInputRef.current = element)
                              }
                              accept=".csv"
                              onChange={(e) => setImportFile(e.target.files[0])}
                              style={{ display: "none" }}
                            />
                            {importRecords.length > 0 && (
                              <G2Button26
                                text="Upload Records"
                                onClick={() =>
                                  dispatch(
                                    uploadExteriorResults(toast, importRecords)
                                  )
                                }
                              />
                            )}
                          </HStack>
                        </div>
                      )}
                    </div>

                    {importFile != null ? (
                      <ExternalFileLoader
                        file={importFile}
                        setRecords={setImportRecords}
                      />
                    ) : (
                      <UnorderedList w="100%">
                        {passport?.externalResults?.map((r, index) => (
                          <ListItem w="100%" key={index}>
                            <HStack w="100%" justifyContent="space-between">
                              {r.organisation && <Text>{r.organisation}</Text>}
                              <Text>{r.title}</Text>
                              {r.date && <Text>{formatDate(r.date)}</Text>}
                              {r.certifier && <Text>{r.certifier}</Text>}
                            </HStack>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    )}
                  </Box>
                  <hr className="my-16 ml-[10%] w-[65%] border-t-[1px] border-gray-500" />
                </>
              )}
            </>
          )}
        </Box>

        <Box w="100%" px="30px" py="20px">
          {!passport?.isAccepted && !passport?.isMyProfile && (
            <>
              {/* About Me Section */}
              <Box w="100%" mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  About Me
                </Text>
                <Textarea
                  w="100%"
                  className="mt-[5px]"
                  borderRadius="5px"
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                    borderRadius: "2.5px",
                  }}
                  size="md"
                  rows={3}
                  value={passport?.profile?.aboutMe ?? ""}
                  onChange={(e) =>
                    setPassport(["profile", "aboutMe"], e.target.value)
                  }
                />
              </Box>

              <Box mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  {user.type === "Business" ? "Work For" : "Users"}
                </Text>

                {/* Check if user type is "business" and childUsers array exists and has at least one element */}
                {user.type === "Business" ? (
                  <Box display="flex" gap={3} ml={4} mt={2}>
                    {/* {user.childUsers.map((childUser) => ( */}
                    <Image
                      // key={childUser.id}
                      borderRadius="full"
                      bg="white"
                      boxSize="50px"
                      src={getFileUrl(user.avatar)}
                      alt={`Company`}
                      onClick={() => navigate(`/academy/digital`)}
                      cursor="pointer"
                    />
                    {/* ))} */}
                  </Box>
                ) : (
                  // If not business or no child users, show default avatar or fallback logic here if needed
                  <Box display="flex" gap={3} ml={4} mt={2}>
                    <Image
                      borderRadius="full"
                      bg="white"
                      boxSize="50px"
                      src={getFileUrl(user.avatar)}
                      alt="avatar"
                      onClick={() => navigate("/academy/digital")}
                      cursor="pointer"
                    />
                  </Box>
                )}
              </Box>

              {/* Socials */}
              <Box w="100%" className="" pt="15px">
                <G2Label4
                  className="block text-sm font-semibold leading-6 mb-[15px]"
                  label="Socials"
                />
                <Box ml="20px">
                  {" "}
                  {/* Adjust the value as needed */}
                  <SocialConnectForm
                    userData={data?.data ?? {}}
                    setUserData={(v) => console.log(v)}
                  />
                </Box>
              </Box>

              {/* Badges */}
              <Box mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  Badge
                </Text>
                <Box display="flex" gap={3} ml={4} mt={2}>
                  {badges.map((badge) => (
                    <BadgeComponent key={badge.id} badge={badge} />
                  ))}
                </Box>
              </Box>

              {/* Years of Experience Section */}
              {/* <Box w="100%" mb="20px">
                <Text className="font-semibold" fontSize="16px">
                  Years of Experience
                </Text>
                <Input
                  w="100%"
                  className="mt-[5px]"
                  value={passport?.profile?.experience}
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                    borderRadius: "2.5px",
                  }}
                  onChange={(e) =>
                    setPassport(["profile", "experience"], e.target.value)
                  }
                />
              </Box> */}

              {/* Portfolio, Cover Letter, Resume, License, etc. */}
              {profileUploadTypes.map((r) => {
                const content = _.get(passport, r.path);
                return (
                  <Box key={r.title} w="100%" mb="20px ">
                    <div
                      className="w-full items-center pl-5"
                      style={{ position: "relative" }}
                    >
                      <Text className="font-semibold" fontSize="16px">
                        {r.title}
                      </Text>
                      <div style={{ float: "right" }}>
                        {!passport?.isMyProfile && (
                          <UploadButton
                            key={r.name}
                            type={r}
                            passport={passport}
                            setPassport={setPassport}
                            showFiles={false}
                            boxW1={boxW1}
                          />
                        )}
                      </div>
                    </div>

                    {r.subtitle && passport?.isMyProfile && (
                      <HStack w="100%">
                        <Text className="mt-5 ml-[5px]" fontSize="16px">
                          {r.subtitle}
                        </Text>
                      </HStack>
                    )}

                    <Box w="100%" className="flex">
                      <Box w="100%" className="flex-box">
                        {(content ?? []).map((val, i) => (
                          <Box key={i} w="20%" className="mt-[10px] p-[10px]">
                            <Box
                              w="100%"
                              className="academy-portfolio-box p-[5px]"
                            >
                              <Box w="100%" h="100%" className="relative">
                                <DynamicDisplayFile
                                  file={val}
                                  onClick={() => {
                                    setFile({
                                      heading: r.title,
                                      file: val,
                                    });
                                    onOpen();
                                  }}
                                />
                                {passport.isMyProfile && (
                                  <IconButton
                                    position="absolute"
                                    right="0px"
                                    variant="outline"
                                    isRound
                                    color="black"
                                    fontSize="15px"
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      removePassportAttachment(r, i)
                                    }
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <hr className="my-3 ml-[10%] w-[65%] border-t-[1px] border-gray-500" />
                  </Box>
                );
              })}

              {/* Followers Section */}
              {passport?.requestingUsers?.length > 0 && (
                <Box w="100%" mb="20px">
                  <div
                    className="w-full items-center"
                    style={{ position: "relative" }}
                  >
                    <Text className="font-semibold" fontSize="16px">
                      Followers
                    </Text>
                    <div style={{ float: "right" }}>
                      {passport?.requestingUsers?.length === 0 && (
                        <Badge colorScheme="orange" ml="5px">
                          {passport?.requestingUsers?.length}
                        </Badge>
                      )}
                    </div>
                  </div>

                  <Box w="100%" pt="5px">
                    <VStack w="100%">
                      {passport?.requestingUsers?.map((r, index) => (
                        <HStack w="100%" key={index}>
                          <Button
                            colorScheme="blue"
                            mr="15px"
                            onClick={() =>
                              navigate(`/academy/digital/` + r.hashId)
                            }
                          >
                            View
                          </Button>
                          <Image
                            borderRadius="full"
                            bg="white"
                            boxSize="32px"
                            src={getFileUrl(r?.avatar)}
                            className="my-[5px]"
                            alt="avatar"
                          />
                          <Text fontWeight="bold">{r.name}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>
                  <hr className="my-16 ml-[10%] w-[65%] border-t-[1px] border-gray-500" />
                </Box>
              )}

              {/* Other Results / Import History Section */}
              {passport?.externalResults?.length > 0 && (
                <>
                  <Box w="100%" mb="20px">
                    <div
                      className="w-full items-center pr-[4.5%] pl-[2%]"
                      style={{ position: "relative" }}
                    >
                      <Text className="font-semibold" fontSize="16px">
                        Import History
                      </Text>
                      {passport?.isMyProfile && (
                        <div style={{ float: "right" }}>
                          <HStack w="100%" justifyContent="flex-end">
                            <G2Button26
                              text="Import File"
                              onClick={() => fileInputRef.current.click()}
                            />
                            <Input
                              type="file"
                              multiple={false}
                              ref={(element) =>
                                (fileInputRef.current = element)
                              }
                              accept=".csv"
                              onChange={(e) => setImportFile(e.target.files[0])}
                              style={{ display: "none" }}
                            />
                            {importRecords.length > 0 && (
                              <G2Button26
                                text="Upload Records"
                                onClick={() =>
                                  dispatch(
                                    uploadExteriorResults(toast, importRecords)
                                  )
                                }
                              />
                            )}
                          </HStack>
                        </div>
                      )}
                    </div>

                    {importFile != null ? (
                      <ExternalFileLoader
                        file={importFile}
                        setRecords={setImportRecords}
                      />
                    ) : (
                      <UnorderedList w="100%">
                        {passport?.externalResults?.map((r, index) => (
                          <ListItem w="100%" key={index}>
                            <HStack w="100%" justifyContent="space-between">
                              {r.organisation && <Text>{r.organisation}</Text>}
                              <Text>{r.title}</Text>
                              {r.date && <Text>{formatDate(r.date)}</Text>}
                              {r.certifier && <Text>{r.certifier}</Text>}
                            </HStack>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    )}
                  </Box>
                  <hr className="my-16 ml-[10%] w-[65%] border-t-[1px] border-gray-500" />
                </>
              )}
            </>
          )}
        </Box>
        {/* <Box w="100%" px="30px" py="20px" className="flex-box">
          {(passport?.isAccepted || passport?.isMyProfile) && (
            <>
              <Tabs w="100%">
                <TabList>
                  <Tab>Profile</Tab>
                  {profileUploadTypes.map((r) => (
                    <Tab key={r.title}>
                      {r.title}
                      {getContentCount(r) > 0 && (
                        <Badge colorScheme="green" ml="5px">
                          {getContentCount(r)}
                        </Badge>
                      )}
                    </Tab>
                  ))}
                  <Tab>
                    Followers
                    {passport?.requestingUsers?.length > 0 && (
                      <Badge colorScheme="orange" ml="5px">
                        {passport?.requestingUsers?.length}
                      </Badge>
                    )}
                  </Tab>
                  <Tab>Other Results</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box w="100%">
                      {
                        //JSON.stringify(passport?.isAccepted)
                      }
                      <Text className="font-semibold" fontSize="16px">
                        About me
                      </Text>
                    </Box>
                    <Textarea
                      w="100%"
                      className="mt-[5px]"
                      borderRadius="5px"
                      style={{
                        background: `${G2_color_2}`,
                        color: `${G2_color_3}`,
                        borderRadius: "2.5px",
                      }}
                      size="md"
                      rows={3}
                      value={passport?.profile?.aboutMe ?? ""}
                      onChange={(e) =>
                        setPassport(["profile", "aboutMe"], e.target.value)
                      }
                    />

                    <Box w="100%">
                      <Text className="font-semibold mt-5" fontSize="16px">
                        Years experience
                      </Text>
                    </Box>
                    <Input
                      w="100%"
                      className="mt-[5px]"
                      value={passport?.profile?.experience}
                      style={{
                        background: `${G2_color_2}`,
                        color: `${G2_color_3}`,
                        borderRadius: "2.5px",
                      }}
                      onChange={(e) =>
                        setPassport(["profile", "experience"], e.target.value)
                      }
                    />
                  </TabPanel>
                  {profileUploadTypes.map((r) => {
                    const content = _.get(passport, r.path);
                    return (
                      <TabPanel key={r.title}>
                        <HStack w="100%" justifyContent="space-between">
                          <Text className="font-semibold" fontSize="16px">
                            {r.title}
                          </Text>
                          {passport?.isMyProfile && (
                            <UploadButton
                              key={r.name}
                              type={r}
                              passport={passport}
                              setPassport={setPassport}
                              showFiles={false}
                              boxW1={boxW1}
                            />
                          )}
                        </HStack>
                        {r.subtitle != "" && passport?.isMyProfile && (
                          <HStack w="100%">
                            <Text className="mt-5 ml-[5px]" fontSize="16px">
                              {r.subtitle}
                            </Text>
                          </HStack>
                        )}
                        <Box w="100%" className="flex">
                          <Box w="100%" className="flex-box">
                            {(content ?? [])?.map((val, i) => (
                              <Box
                                key={i}
                                w="20%"
                                className="mt-[20px] p-[10px]"
                              >
                                <Box
                                  w="100%"
                                  className="academy-portfolio-box p-[5px]"
                                >
                                  <Box w="100%" h="100%" className="relative">
                                    <DynamicDisplayFile
                                      file={val}
                                      onClick={() => {
                                        setFile({
                                          heading: r.title,
                                          file: val,
                                        });
                                        onOpen();
                                      }}
                                    />
                                    {passport.isMyProfile && (
                                      <IconButton
                                        position="absolute"
                                        top="10px"
                                        left="10px"
                                        variant="outline"
                                        isRound={true}
                                        color="black"
                                        fontSize="15px"
                                        icon={<DeleteIcon />}
                                        onClick={() =>
                                          removePassportAttachment(r, i)
                                        }
                                      />
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </TabPanel>
                    );
                  })}
                  <TabPanel>
                    {passport?.requestingUsers?.filter((r) => r.isRequesting)
                      ?.length > 0 && (
                      <>
                        <HStack w="100%" justifyContent="space-between">
                          <Text className="font-semibold" fontSize="16px">
                            Requests
                          </Text>
                        </HStack>
                        <Box w="100%" pt="5px">
                          <VStack w="100%">
                            {passport?.requestingUsers
                              ?.filter((r) => r.isRequesting)
                              ?.map((r) => (
                                <HStack w="100%">
                                  <Button
                                    colorScheme="blue"
                                    mr="15px"
                                    onClick={() =>
                                      navigate(`/academy/digital/` + r.hashId)
                                    }
                                  >
                                    View
                                  </Button>
                                  <Image
                                    borderRadius="full"
                                    bg="white"
                                    boxSize="32px"
                                    src={getFileUrl(r?.avatar)}
                                    className="my-[5px]"
                                    alt="avatar"
                                  />
                                  <Text fontWeight="bold">{r.name}</Text>
                                </HStack>
                              ))}
                          </VStack>
                        </Box>
                      </>
                    )}

                    {passport?.requestingUsers?.filter((r) => r.isAccepted)
                      .length > 0 && (
                      <>
                        <HStack
                          w="100%"
                          justifyContent="space-between"
                          mt="15px"
                        >
                          <Text className="font-semibold" fontSize="16px">
                            Followers
                          </Text>
                        </HStack>
                        <Box w="100%" pt="5px">
                          <VStack w="100%">
                            {passport?.requestingUsers
                              ?.filter((r) => r.isAccepted)
                              ?.map((r) => (
                                <HStack w="100%">
                                  <Button
                                    colorScheme="blue"
                                    mr="15px"
                                    onClick={() =>
                                      navigate(`/academy/digital/` + r.hashId)
                                    }
                                  >
                                    View
                                  </Button>
                                  <Image
                                    borderRadius="full"
                                    bg="white"
                                    boxSize="32px"
                                    src={getFileUrl(r?.avatar)}
                                    className="my-[5px]"
                                    alt="avatar"
                                  />
                                  <Text fontWeight="bold">{r.name}</Text>
                                  <Button
                                    colorScheme="orange"
                                    ml="15px"
                                    onClick={() =>
                                      dispatch(
                                        actionFollowRequest(toast, r.hashId, 0)
                                      )
                                    }
                                  >
                                    <FaRegThumbsDown />
                                    <Text ml="5px">Remove Extra</Text>
                                  </Button>
                                </HStack>
                              ))}
                          </VStack>
                        </Box>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <HStack w="100%" justifyContent="space-between">
                      <Text className="font-semibold" fontSize="16px">
                        Import History
                      </Text>
                      {passport?.isMyProfile && (
                        <Box w={boxW1} className="centering-w">
                          <HStack w="100%">
                            <G2Button26
                              text="Import File"
                              onClick={() => fileInputRef.current.click()}
                            />
                            <Input
                              type="file"
                              multiple={false}
                              ref={(element) =>
                                (fileInputRef.current = element)
                              }
                              accept=".csv"
                              onChange={(e) => setImportFile(e.target.files[0])}
                              style={{ display: "none" }}
                            />
                            {importRecords.length > 0 && (
                              <G2Button26
                                text="Upload Records"
                                onClick={() =>
                                  dispatch(
                                    uploadExteriorResults(toast, importRecords)
                                  )
                                }
                              />
                            )}
                          </HStack>
                        </Box>
                      )}
                    </HStack>
                    {
                      //JSON.stringify(importRecords)
                    }
                    {importFile != null && (
                      <ExternalFileLoader
                        file={importFile}
                        setRecords={setImportRecords}
                      />
                    )}
                    {importFile == null && (
                      <UnorderedList w="100%">
                        {passport?.externalResults?.map((r) => (
                          <ListItem w="100%">
                            <HStack w="100%" justifyContent="space-between">
                              {r.organisation != "" && (
                                <Text>{r.organisation}</Text>
                              )}
                              <Text>{r.title}</Text>
                              {r.date != "" && (
                                <Text>{formatDate(r.date)}</Text>
                              )}
                              {r.certifier != "" && <Text>{r.certifier}</Text>}
                            </HStack>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        </Box> */}
      </Box>

      <Box w="100%" className="centering-w mt-[30px]">
        <G2Button20
          widthVal="200px"
          heightVal="40px"
          text="Update with current data"
          onClick={handleUpdateClick}
        />
      </Box>

      <Modal size="full" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reviewing {file?.heading}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack justifyContent="center" alignContent="center">
              <Box h="50vh" w="50vw">
                <DynamicDisplayFile
                  maxW="100%"
                  file={file?.file}
                  showClickToView={false}
                />
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DgitalPassportForm;
