import React, { useCallback, useEffect, useMemo, useState } from 'react';
import G2Button21 from '../Common/G2Button21';
import { useStateContext } from '../../ContextProvider/Context';
import { Box, Checkbox, FormLabel, HStack, Image, Input, Select, Text, Textarea, VStack } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import isEmptyValue from '../../Custom/isEmptyValue';
import right from "../../assets/create/right.png";
import wrong from "../../assets/create/wrong.png";
import { removeAt } from '../../helpers/general';
import WYSIWYGEditor from '../Common/WYSIWYGEditor';
import { shapes } from './data';

const QuizQuestionGeometry = ({ quiz, setQuiz, path, questionActions }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const [svgImage, setSVGImage] = useState("");

    const question = useMemo(() => {
        return _.get(quiz, path);
    }, [quiz]);

    const updateShape = useCallback((index) => {
        const shape = shapes[index];
        let temp = _.cloneDeep(question);
        console.log(shape);
        temp.shape = shape;

        let params = shape.formula.match(/(?<![a-z])[a-z](?![a-z])/ig);
        console.log(shape, params);

        if (params == null) {
            temp.params = {};
        } else {
            if (temp.params == undefined) {
                temp.params = {};
            }

            for (let key of Object.keys(temp.params)) {
                if (!params.includes(key)) {
                    delete temp.params[key];
                }
            }

            for (let key of params) {
                if (temp.params[key] == undefined) {
                    temp.params[key] = { min: 1, max: 100, integer: false };
                }
            }
        }

        questionActions.update(path, temp)
    }, [question, path]);

    const params = useMemo(() => {
        let params = question?.params ?? {};
        return params;
    }, [question]);

    const generatedValues = useMemo(() => {
        let values = {};
        for (let name in params) {
            let gen = params[name];
            let min = _.toInteger(gen.min ?? 1);
            let max = _.toInteger(gen.max ?? 100);
            let value = _.round(_.random(min, max, !(gen.integer ?? true)), 2);
            values[name] = value;
        }
        return values;
    }, [params]);

    const correctAnswer = useMemo(() => {
        let names = Object.keys(generatedValues).join(', ');
        let values = Object.values(generatedValues).join(', ');

        let functionString = `return Math.round(Number(function (${names}) {return (${question?.shape?.formula ?? 0});}(${values}))*100)/100;`;
        try {
            let answer = Function(functionString)() + "";
            return answer;
        } catch (ex) {
            console.log(ex);
            return "Invalid Formula"
        }

    }, [generatedValues, question.formula]);

    useEffect(() => {
        if (question?.shape?.image !== undefined) {

            const imageUrl = shapes.filter(r => r.name == question.shape.name)[0].image;
            fetch(imageUrl).then(async res => {
                let data = await res.text();
                for (let name in generatedValues) {
                    let pattern = new RegExp('(?<=>)(\\s*' + name + '\\s*)(?=<\\/tspan)', 'ig');
                    data = data.replace(pattern, generatedValues[name]);
                }
                setSVGImage(data);
            });
        }
    }, [question?.shape?.image, generatedValues])

    return <Box w="100%" pt="5px">

        <Select placeholder='Select Shape' value={shapes.reduce((s, r, i) => (r.name == question?.shape?.name) ? i : s, {})} onChange={(e) => updateShape(e.target.value)}>
            {shapes.map((r, i) => <option key={i} value={i}>{r.name}</option>)}
        </Select>

        {question.shape !== undefined && <HStack w="100%" justifyContent="center">
            <Box w="100%" display="flex" justifyContent="center" dangerouslySetInnerHTML={{ __html: svgImage }} />
        </HStack>}
        <Box>
            <Text className="block text-md font-semibold mt-5" style={{ color: `${G2_color_3}` }}>Example</Text>

            {/*
            Object.keys(generatedValues).map(r => {
                return <HStack key={r}>
                    <Text>{r}</Text>
                    <Text>{generatedValues[r]}</Text>
                </HStack>
            })
            */}
            <HStack>
                <Text>Correct Answer</Text>
                <Text>{correctAnswer}</Text>
            </HStack>

        </Box>

        <HStack w="100%">
            <Text className="text-md font-semibold mt-5" style={{ color: `${G2_color_3}` }}>Formula</Text>
            <Text className="text-md font-semibold mt-5" style={{ color: `${G2_color_3}` }}>{question?.shape?.formula}</Text>
        </HStack>

        <VStack>
            {Object.keys(params).map(r => {
                return <HStack key={r}>
                    <Text>{r}</Text>
                    <Input bg={G2_color_1} color={G2_color_3} placeholder="Min" size="sm" value={params[r].min ?? 1} onChange={(e) => questionActions.modify(['params', r, 'min'], e.target.value, path)} />
                    <Input bg={G2_color_1} color={G2_color_3} placeholder="Max" size="sm" value={params[r].max ?? 100} onChange={(e) => questionActions.modify(['params', r, 'max'], e.target.value, path)} />
                    <Checkbox size="sm" className="mr-5" borderColor="green.500" isChecked={params[r].integer ?? true} onChange={(e) => questionActions.modify(['params', r, 'integer'], !(params[r].integer ?? true), path)}>Whole Numbers</Checkbox>
                </HStack>
            })}
        </VStack>
    </Box>
};

const QuizQuestionFormula = ({ quiz, setQuiz, path, questionActions }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const question = useMemo(() => {
        return _.get(quiz, path);
    }, [quiz]);

    const updateFormula = useCallback((value) => {
        let temp = _.cloneDeep(question);
        temp.formula = value;
        let params = value.match(/(?<![a-z])[a-z](?![a-z])/ig);
        console.log(value, params);

        if (params == null) {
            temp.params = {};
        } else {
            if (temp.params == undefined) {
                temp.params = {};
            }

            for (let key of Object.keys(temp.params)) {
                if (!params.includes(key)) {
                    delete temp.params[key];
                }
            }

            for (let key of params) {
                if (temp.params[key] == undefined) {
                    temp.params[key] = { min: 1, max: 100, integer: true };
                }
            }
        }

        questionActions.update(path, temp)
    }, [question, path]);

    const params = useMemo(() => {
        let params = question?.params ?? {};
        return params;
    }, [question]);

    const generatedValues = useMemo(() => {
        let values = {};
        for (let name in params) {
            let gen = params[name];
            let min = _.toInteger(gen.min ?? 1);
            let max = _.toInteger(gen.max ?? 100);
            let value = _.round(_.random(min, max, !(gen.integer ?? true)), 2);
            values[name] = value;
        }
        return values;
    }, [params]);

    const correctAnswer = useMemo(() => {
        let names = Object.keys(generatedValues).join(', ');
        let values = Object.values(generatedValues).join(', ');

        let functionString = `return Math.round(Number(function (${names}) {return (${question.formula});}(${values}))*100)/100;`;
        try {
            return Function(functionString)();
        } catch (ex) {
            console.log(ex);
            return "Invalid Formula"
        }

    }, [generatedValues, question.formula]);

    return <Box w="100%" pt="5px">
        {
            //JSON.stringify(question)
        }
        <WYSIWYGEditor w="100%" value={question.question ?? ""} setValue={(value) => questionActions.modify(['question'], value, path)} />
        <Text className="block text-md font-semibold mt-5" style={{ color: `${G2_color_3}` }}>Formula</Text>
        <Input bg={G2_color_1} color={G2_color_3} w="100%" className="mt-2" placeholder="Formula" size="sm" value={question.formula ?? ""} onChange={(e) => updateFormula(e.target.value)} />
        <VStack>
            {Object.keys(params).map(r => {
                console.log(params, r);
                return <HStack key={r}>
                    <Text>{r}</Text>
                    <Input bg={G2_color_1} color={G2_color_3} placeholder="Min" size="sm" value={params[r].min ?? 1} onChange={(e) => questionActions.modify(['params', r, 'min'], e.target.value, path)} />
                    <Input bg={G2_color_1} color={G2_color_3} placeholder="Max" size="sm" value={params[r].max ?? 100} onChange={(e) => questionActions.modify(['params', r, 'max'], e.target.value, path)} />
                    <Checkbox size="sm" className="mr-5" borderColor="green.500" isChecked={params[r].integer ?? true} onChange={(e) => questionActions.modify(['params', r, 'integer'], !(params[r].integer ?? true), path)}>Whole Numbers</Checkbox>
                </HStack>
            })}
        </VStack>
        <Box>
            <Text className="block text-md font-semibold mt-5" style={{ color: `${G2_color_3}` }}>Example</Text>
            {Object.keys(generatedValues).map(r => {
                console.log(params, r);
                return <HStack key={r}>
                    <Text>{r}</Text>
                    <Text>{generatedValues[r]}</Text>
                </HStack>
            })}
            <HStack>
                <Text>Correct Answer</Text>
                <Text>{correctAnswer}</Text>
            </HStack>

        </Box>
    </Box>
}

const QuizQuestionLong = ({ quiz, setQuiz, path, questionActions }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const question = useMemo(() => {
        return _.get(quiz, path);
    }, [quiz]);

    return <Box w="100%" pt="5px">
        {
            //JSON.stringify(question)
        }
        <WYSIWYGEditor w="100%" value={question.question ?? ""} setValue={(value) => questionActions.modify(['question'], value, path)} />
    </Box>
}

const QuizQuestionMultipleChoice = ({ quiz, setQuiz, path, questionActions }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const question = useMemo(() => {
        return _.get(quiz, path);
    }, [quiz]);

    const handleAddAnswer = useCallback(() => {
        let temp = _.cloneDeep(question);
        if (temp.answers == undefined) {
            temp.answers = [];
        }
        temp.answers.push({});
        questionActions.update(path, temp);
    }, [quiz, setQuiz]);

    const handleDeleteAnswer = useCallback((answerPath) => {
        let temp = removeAt(question, answerPath);
        questionActions.update(path, temp);
    }, [quiz, setQuiz]);

    return <Box w="100%" pt="5px">
        {
            //JSON.stringify(question)
        }
        <Input bg={G2_color_1} color={G2_color_3} w="100%" className="mt-2" placeholder="Input a question" size="sm" value={question.question ?? ""} onChange={(e) => questionActions.modify(['question'], e.target.value, path)} />
        <Text className="block text-md font-semibold mt-5" style={{ color: `${G2_color_3}` }}>Answer</Text>

        {question.answers?.map((answer, i) => <Box w="100%" key={i}>
            {
                //JSON.stringify(answer)
            }
            <Box className="relative pr-[35px]">
                <Textarea bg={G2_color_1} color={G2_color_3} w="100%" className="mt-2" size="sm" rows={2} value={answer.text ?? ""} onChange={(e) => questionActions.modify(['answers', i, 'text'], e.target.value, path)} />
                <SmallCloseIcon className="normal-hover absolute top-[7px] right-[5px]" style={{ color: `${G2_color_3}` }} onClick={() => handleDeleteAnswer(['answers', i])} />
                <Image className={`create-unit-ans-btn${question.rightAnswer == answer.text && !isEmptyValue(answer.text) ? 2 : 1} absolute bottom-[1px] right-[0px]`} boxSize="25px" src={right} alt="rightAnswer" onClick={() => questionActions.modify(['rightAnswer'], answer.text, path)} />
            </Box>
        </Box>)}
        <Box w="100%" className="mt-5">
            <G2Button21 widthVal="100%" heightVal="35px" text="Add answer" onClick={handleAddAnswer} />
        </Box>
    </Box>
}

const QuizCreationCard = ({ quiz, setQuiz }) => {

    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const handleAddQuestion = useCallback(() => {
        let temp = _.cloneDeep(quiz);
        if (temp.questions === undefined) {
            temp.questions = [];
        }

        temp.questions.push({});
        setQuiz(temp);
    }, [quiz, setQuiz]);

    const handleDeleteQuestion = useCallback((path) => {
        let temp = removeAt(quiz, path);
        setQuiz(temp);
    }, [quiz, setQuiz]);

    const handleUpdateQuestion = useCallback((path, value) => {
        let temp = _.cloneDeep(quiz);
        temp = _.set(temp, path, value);
        setQuiz(temp);
    }, [quiz, setQuiz]);

    const handleModifyQuestion = useCallback((questionPath, value, path) => {
        let temp = _.cloneDeep(_.get(quiz, path));
        temp = _.set(temp, questionPath, value);
        questionActions.update(path, temp);
    }, [quiz, setQuiz])

    const questionActions = {
        create: handleAddQuestion,
        delete: handleDeleteQuestion,
        update: handleUpdateQuestion,
        modify: handleModifyQuestion,
    };

    const defaultComponentType = "multipleChoice";

    const questionTypes = useMemo(() => {
        return {
            'multipleChoice': { name: "Multiple Choice", component: QuizQuestionMultipleChoice },
            'longForm': { name: "Long", component: QuizQuestionLong },
            'formula': { name: "Formula", component: QuizQuestionFormula },
            'geometry': { name: "Geometry", component: QuizQuestionGeometry },
        };
    }, []);

    const getQuestionComponent = useCallback((type) => {
        if (questionTypes[type] === undefined) {
            return questionTypes[defaultComponentType].component;
        }

        return questionTypes[type].component;
    }, [questionTypes, defaultComponentType]);


    const update = useCallback((path, value) => {
        let temp = _.cloneDeep(quiz);
        temp = _.set(temp, path, value);
        setQuiz(temp);
    }, [quiz, setQuiz]);

    const handleTypeChange = useCallback((i, value) => {
        let temp = _.cloneDeep(quiz);
        temp = _.set(temp, ['questions', i], { type: value });
        setQuiz(temp);
    }, [quiz, setQuiz]);


    return <>
        <Text w="100%" className="block text-lg font-semibold mt-5 mb-2" style={{ color: `${G2_color_3}` }}>
            Quiz
        </Text>
        <Text w="100%" className="block text-sm font-semibold mt-2 mb-2" style={{ color: `${G2_color_3}` }}>
            A quiz allows students to demonstrate their understanding of this card's content. Once added, students must complete the quiz to progress to the next card.
        </Text>
        <VStack w="100%" alignItems="left" mb="15px">
            <Checkbox variant="white" isChecked={quiz?.forceQuestionRetry ?? false} onChange={(e) => update('forceQuestionRetry', !(quiz?.forceQuestionRetry ?? false))}>Force User to retry question when incorrectly answered.</Checkbox>
            <Checkbox variant="white" isChecked={quiz?.forceQuizRetry ?? false} onChange={(e) => update('forceQuizRetry', !(quiz?.forceQuizRetry ?? false))}>User allowed to retry Quiz if failed.</Checkbox>
            <HStack w="100%">
                <FormLabel pt="5px">Required Score Percentage</FormLabel>
                <Input variant="white" w="20%" type="number" value={quiz?.requiredScore ?? 100} onChange={(e) => update('requiredScore', _.clamp(e.target.value, 0, 100))} placeholder="Required Score" />
            </HStack>
        </VStack>
        {
            //JSON.stringify(quiz)
        }
        <G2Button21 widthVal="120px" heightVal="35px" text="Add Question" onClick={handleAddQuestion} />
        <Box w="100%" className="grid grid-cols-1 gap-x-5 sm:grid-cols-2">
            {(quiz?.questions ?? []).map((question, i) => {
                const QuestionComponent = getQuestionComponent(question.type);
                return <Box key={i} borderRadius="2.5px" bg={G2_color_2} className="shadow mt-5 px-[15px] pt-[10px] pb-[15px]">
                    <Box className="flex-box justify-between centering-h pb-[5px]">
                        <Box className="flex-box text-left"><Text className="block text-md font-semibold" style={{ color: `${G2_color_3}` }}>Question {i + 1}</Text></Box>
                        <Box className="flex-box text-right"><SmallCloseIcon className="normal-hover" style={{ color: `${G2_color_3}` }} onClick={() => questionActions.delete(["questions", i])} /></Box>
                    </Box>
                    <HStack w="100%">
                        <FormLabel pt="5px" fontWeight="bold">Type</FormLabel>
                        <Select placeholder='Select Type' value={question.type ?? defaultComponentType} onChange={(e) => handleTypeChange(i, e.target.value)}>
                            {Object.keys(questionTypes).map(r => <option key={r} value={r}>{questionTypes[r].name}</option>)}
                        </Select>
                    </HStack>

                    <QuestionComponent path={["questions", i]} quiz={quiz} setQuiz={setQuiz} questionActions={questionActions} />
                </Box>;
            })}
        </Box >
    </>
}

export default QuizCreationCard;