import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Card, CardBody, CardFooter, CardHeader, HStack, IconButton, useDisclosure } from "@chakra-ui/react";
import { GrHelp } from "react-icons/gr";

export const createStep = (selector, content, options) => {
	return {
		selector,
		content,
		options
	}
}

const PTourStep = ({ isOpen, step, prev, next, close }) => {
	const cardRef = useRef(null);

	const hasTitle = useMemo(() => {
		return step.title !== undefined && step.title !== "";
	}, [step]);

	useEffect(() => {
		document.querySelector('.tour-highlight')?.classList?.remove('tour-highlight');
		const component = document.querySelector(step.selector);
		if (component == undefined || !isOpen) {
			return
		}

		component.classList.add('tour-highlight');

		let card = cardRef.current;
		let rect = component.getBoundingClientRect();
		switch (step?.position ?? "bottomLeft") {
			case "topLeft":
				card.style.top = (rect.top + window.scrollY) + "px";
				card.style.bottom = "inherit";
				card.style.left = (rect.left + window.scrollX) + "px";
				card.style.right = "inherit";
				break;
			case "topRight":
				card.style.top = (rect.top + window.scrollY) + "px";
				card.style.bottom = "inherit";
				card.style.left = "inherit";
				card.style.right = (document.body.clientWidth - (rect.left + rect.width + window.scrollX)) + "px";
				break;
			case "bottomLeft":
				card.style.top = (rect.top + rect.height + window.scrollY) + "px";
				card.style.bottom = "inherit";
				card.style.left = (rect.left + window.scrollX) + "px";
				card.style.right = "inherit";
				break;
			case "bottomRight":
				card.style.top = (rect.top + rect.height + window.scrollY) + "px";
				card.style.bottom = "inherit";
				card.style.left = "inherit";
				card.style.right = (document.body.clientWidth - (rect.left + rect.width + window.scrollX)) + "px";
				break;
		}
	}, [cardRef, step, isOpen])

	if (!isOpen) {
		return <></>
	}

	return <Card ref={cardRef} position="absolute" zIndex="1000" width="250px">
		{hasTitle && <CardHeader>{step.title}</CardHeader>}
		<CardBody>
			{step.content}
		</CardBody>
		<CardFooter p="5px">
			<HStack justifyContent="space-between" w="100%">
				<HStack>
					{prev}
					{next}
				</HStack>
				<HStack>
					{close}
				</HStack>
			</HStack>
		</CardFooter>
	</Card>
}

function PTour({ steps }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [stepIndex, setStepIndex] = useState(0);
	const step = useMemo(() => {
		return steps[stepIndex];
	}, [steps, stepIndex]);

	const closeButton = <Button size="sm" colorScheme='blue' onClick={() => { setStepIndex(0); onClose(); }}>Close</Button>;
	const nextButton = <Button visibility={stepIndex < steps.length - 1 ? "visible" : "hidden"} size="sm" colorScheme='blue' onClick={() => setStepIndex(stepIndex + 1)}>Next ({stepIndex + 1}/{steps.length})</Button>
	const prevButton = <Button visibility={stepIndex > 0 ? "visible" : "hidden"} size="sm" colorScheme='blue' onClick={() => setStepIndex(stepIndex - 1)}>Prev</Button>

	return <>
		<PTourStep isOpen={isOpen} step={step} next={nextButton} prev={prevButton} close={closeButton} />
		<Box position="fixed" bottom="2vh" left="2vw" zIndex={1000}>
			<Box className="flex-box text-left centering-h">
				<IconButton className="mr-5" isRound={!isOpen} variant="outline" bg="#032c47" color="grey" fontSize="20px" icon={<GrHelp />} onClick={() => onOpen()} title="Help" />
			</Box>
		</Box>
	</>
};

export default PTour;