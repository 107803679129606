import React from "react";

import { useStateContext } from "../../ContextProvider/Context";

const G2Button25 = ({ text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <div className="mt-[10px] w-[220px] h-[40px] p-[3px] bg-[#032c47] rounded-full centering">
      <button
        onClick={onClick}
        className="w-[214px] h-[34px] bg-[var(--orange)] text-white font-semibold rounded-full hover:bg-[#2a956e]"
      >
        {text}
      </button>
    </div>
  );
};

export default G2Button25;
