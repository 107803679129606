import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Text, Image, Card, CardBody, Input, Textarea, IconButton, AspectRatio, Select, InputGroup, InputRightAddon, useToast, Spinner, Code, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, HStack, } from "@chakra-ui/react";
import { CloseIcon, DeleteIcon, SmallCloseIcon } from "@chakra-ui/icons";
import "../../App.css";
import { useStateContext } from "../../ContextProvider/Context";
import { addContent, getCategory, makePreview, updateContent } from "../../Store/Actions/lifeAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";
import G2Button12 from "../Common/G2Button12";
import G2Button13 from "../Common/G2Button13";
import G2Button20 from "../Common/G2Button20";
import upload1 from "../../assets/marketplace/upload1.png";
import upload2 from "../../assets/marketplace/upload2.png";
import upload3 from "../../assets/marketplace/upload3.png";
import ViewForm from "./ViewForm";
import { industryData } from "../../constant";
import DynamicFileViewer from "../DynamicFileViewer";
import { isFile, removeAt } from "../../helpers/general";
import _ from "lodash";

const areas = [
	"Mental health support",
	"Physical health services",
	"Community health programs",
	"Substance abuse and addiction services",
	"Disability support",
	"Nutrition & food assistance",
	"Education & awareness",
	"Domestic violence support",
	"Crisis intervention services",
	"Finance support",
	"Law services",
];

const skills = [
	"Business consulting",
	"Tardesmen",
	"OH & S",
	"Estimators",
	"Project Management",
	"Supervisors",
	"Sales & Marketing",
	"Administration",
	"Inventory Management",
	"Sales Rep",
	"Content Creators",
];

const UploadArea = ({ model, path, set, icon, title, className, accept = ".*", alt = "upload" }) => {
	const fileInputRef = useRef(null);

	const value = useMemo(() => {
		return _.get(model, path) ?? null;
	}, [model, path]);

	if (value == null) {
		return <Box className="flex-box">
			<Box w="100%" className="centering-w">
				<Box className={className}>
					<Image boxSize="100%" src={icon} onClick={() => fileInputRef.current.click()} alt={alt} />
					<Input type="file" ref={fileInputRef} accept={accept} onChange={(e) => set(path, e.target.files[0])} style={{ display: "none" }} />
				</Box>
			</Box>
			<Box w="100%" className="centering-w">
				<Text fontSize="14px">{title}</Text>
			</Box>
		</Box>
	}

	return <Box w="100%" h="100%" className="relative">
		<AspectRatio w="100%" h="100%" className="centering" ratio={16 / 9} maxW="400px">
			<DynamicFileViewer file={value} />
		</AspectRatio >
		<IconButton position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" variant="outline" isRound={true} color="white" fontSize="15px" icon={<DeleteIcon />} onClick={() => set(model, path, null)} />
	</Box >
}


const RegisterForm = ({ content = null }) => {
	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();
	const category = useSelector((state) => state.Life.category);
	const [wait, setWait] = useState(false);
	const [serviceItem, setServiceItem] = useState("");
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [data, setData] = useState({
		name: "",
		bioDescription: "",
		isProfit: false,
		industry: industryData[0],
		businessName: "",
		website: "",
		support: areas[0],
		skill: skills[0],
		subDescription: "",
		videoDescription: "",
		businessCost: "",
		memberCost: "",
		categoryTitle: "",
		nodeTitle: "",
		itemId: "",
		nodeId: "",
		cover: null,
		logo: null,
		video: null,
		serviceList: [],
		posted: false,

	});

	/*
			name: "a",
		bioDescription: "b",
		isProfit: false,
		industry: industryData[0],
		businessName: "c",
		website: "d",
		support: areas[0],
		skill: skills[0],
		subDescription: "e",
		videoDescription: "f",
		businessCost: "13",
		memberCost: "12",
		categoryTitle: "",
		nodeTitle: "",
		itemId: "",
		nodeId: "",
		cover: null,
		logo: null,
		video: null,
		serviceList: [],
		posted: false,

	*/

	useEffect(() => {
		if (content != null) {
			setData(content);
		}
		dispatch(getCategory(toast, true));
	}, []);

	const update = useCallback((path, value) => {
		let temp = _.cloneDeep(data);
		temp = _.set(temp, path, value);
		setData(temp);
	}, [data, setData]);

	useEffect(() => {
		if (content == null && data.categoryTitle == "" && category.length > 0) {
			let c = category.filter(r => r.userSelectable == 1)[0];
			let n = c.nodes.filter(r => r.userSelectable == 1)[0];

			let temp = _.cloneDeep(data);
			temp.categoryTitle = c.item;
			temp.nodeTitle = n.text;
			temp.itemId = c._id;
			temp.nodeId = n.id;
			setData(temp);
		}
	}, [data, content, category]);

	const categoryChange = useCallback((value) => {
		const targetCategory = category.find(r => r._id + "" == value + "");
		const targetNode = targetCategory?.nodes[0];
		let temp = _.cloneDeep(data);
		temp.categoryTitle = targetCategory.item;
		temp.nodeTitle = targetNode.text;
		temp.itemId = targetCategory._id;
		temp.nodeId = targetNode.id;
		setData(temp);
	}, [data, category]);

	const nodeChange = useCallback((value) => {
		const targetCategory = category.find(r => r._id + "" == data.itemId + "");
		const targetNode = targetCategory.nodes.find(r => r.id + "" == value + "");
		let temp = _.cloneDeep(data);
		temp.nodeTitle = targetNode.text;
		temp.nodeId = targetNode.id;
		setData(temp);
	}, [data, category]);

	const handleSaveContent = useCallback((posted = false) => {
		let temp = _.cloneDeep(data);
		temp.posted = posted;

		if (isEmptyObject(temp)) {
			g2Toast({ toast, status: "error", title: "All input fields must be filled out!", });
			return;
		}


		if (
			parseFloat(temp.memberCost) + "" != temp.memberCost ||
			parseFloat(temp.businessCost) + "" != temp.businessCost) {
			g2Toast({ toast, status: "error", title: "Costs must be numbers", });
			return;
		}

		if (temp.memberCost < 0 || temp.businessCost < 0) {
			g2Toast({ toast, status: "error", title: "Costs must be greater than or equal to Zero", });
			return;
		}

		const formData = new FormData();

		[
			'cover',
			'logo',
			'video'
		].forEach((field, i) => {
			if (isFile(temp[field])) {
				formData.append(field, temp[field]);
				_.unset(temp, field);
			}
		});

		console.log(temp);
		formData.append("data", JSON.stringify(temp));

		setWait(true);
		if (content == null) {
			dispatch(addContent(toast, navigate, formData, setWait));
		} else {
			dispatch(updateContent(toast, navigate, content._id, formData, setWait));
		}
	}, [data, content]);

	const handleServiceItemAdd = useCallback(() => {
		update('serviceList', [...data.serviceList, serviceItem]);
		setServiceItem("");
	}, [data, serviceItem, setServiceItem]);

	const handleServiceItemDelete = (index) => {
		let temp = removeAt(data, ['serviceList', index]);
		setData(temp);
	};

	if (wait) {
		return <Box w="100%" className="centering-w mt-[200px]">
			<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
		</Box>
	}

	return (
		<Box className="market-main-padding" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			<Box>
				<Box>
					<Text className="font-semibold ml-2.5 mb-2.5" fontSize="20px">
						{content == null ? "Create" : "Edit"} Content
					</Text>
				</Box >
				<Box w="100%" className="flex-box">
					{
						//JSON.stringify(data)
					}
					<Box className="market-reg-box1">
						<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
							<CardBody>
								<Box w="100%" h="100%" className="flex-box">
									<Box className="market-reg-box3">
										<Box className="flex-box market-reg-upload1-box centering">
											<UploadArea icon={upload1} model={data} path="cover" set={update} title="Upload Cover" className="market-reg-upload1 aspect-w-4" accept=".png, .jpg, .jpeg" alt="cover" />
										</Box>
									</Box>
									<Box className="market-reg-box4 flex-box">
										<Box w="100%" className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
											<Input placeholder="Enter your name" size="sm" value={data?.name} onChange={(e) => update('name', e.target.value)} />
											<Input placeholder="Enter your business name" size="sm" className="mt-2.5 xl:mt-0" value={data?.businessName} onChange={(e) => update("businessName", e.target.value)} />
											<Textarea className="mt-2.5" placeholder="Enter bio description" size="sm" rows={3} value={data?.bioDescription} onChange={(e) => update("bioDescription", e.target.value)} />
											<Input placeholder="Website" size="sm" className="mt-2.5" value={data?.website} onChange={(e) => update("website", e.target.value)} />
											<Box className="mt-2.5">
												<Text fontSize="14px" className="mb-1">
													Are you a non-for-profit?
												</Text>
												<Select size="sm" height="30px" className="centering-h" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, borderRadius: "0px", }} value={data?.isProfit} onChange={(e) => update('isProfit', e.target.value)}>
													<option value={true} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
														Yes
													</option>
													<option value={false} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
														No
													</option>
												</Select>
											</Box>
											<Box className="mt-2.5">
												<Text fontSize="14px" className="mb-1">
													What area of support do you provide?
												</Text>
												<Select size="sm" height="30px" className="centering-h" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, borderRadius: "0px", }} value={data?.support} onChange={(e) => update("support", e.target.value)}>
													{areas.map((val, i) => (
														<option key={i} value={val} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
															{val}
														</option>
													))}
												</Select>
											</Box>
											<Box className="mt-2.5">
												<Text fontSize="14px" className="mb-1">
													Choose industry
												</Text>
												<Select size="sm" height="30px" className="centering-h" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, borderRadius: "0px", }} value={data?.industry} onChange={(e) => update("industry", e.target.value)}>
													{industryData.map((val, i) => (
														<option key={i} value={val} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
															{val}
														</option>
													))}
												</Select>
											</Box>
											<Box className="mt-2.5">
												<Text fontSize="14px" className="mb-1">
													Choose skills category
												</Text>
												<Select size="sm" height="30px" className="centering-h" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, borderRadius: "0px", }} value={data?.skill} onChange={(e) => update("skill", e.target.value)}>
													{skills.map((val, i) => (
														<option key={i} value={val} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
															{val}
														</option>
													))}
												</Select>
											</Box>
										</Box>
									</Box>
								</Box>
							</CardBody>
						</Card>
					</Box>
					<Box className="market-reg-box2">
						<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
							<CardBody>
								<Box w="100%" h="100%" className="text-center">
									<Box w="100%" className="centering-w">
										<Box className="market-reg-upload2-box centering">
											<UploadArea icon={upload2} model={data} path="logo" set={update} title="Upload Logo" className="market-reg-upload2" accept=".png, .jpg, .jpeg" alt="upload" />
										</Box>
									</Box>
									<Text fontSize="14px" className="mt-2.5">
										Aristron Tech
									</Text>
								</Box>
							</CardBody>
						</Card>
					</Box>
				</Box>
				<Box w="100%" className="flex-box p-[10px]">
					<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
						<CardBody>
							<Box w="100%" h="100%" className="flex-box">
								<Box className="market-reg-box5">
									<Box className="market-reg-upload3-box centering">
										<UploadArea icon={upload3} model={data} path="video" set={update} title="Upload Video" className="market-reg-upload3" accept=".mp4, .mpg, .avi, .3gp" alt="upload" />
									</Box>
								</Box>
								<Box className="market-reg-box6 flex-box">
									<Box w="100%">
										<Text fontSize="14px" className="mb-1">
											Subscription Description
										</Text>
										<Textarea w="100%" size="sm" rows={5} value={data?.subDescription} onChange={(e) => update("subDescription", e.target.value)} />
									</Box>
									<Box w="100%" className="mt-2.5">
										<Text fontSize="14px" className="mb-1">
											Video Description
										</Text>
										<Textarea w="100%" size="sm" rows={3} value={data?.videoDescription} onChange={(e) => update("videoDescription", e.target.value)} />
									</Box>
								</Box>
							</Box>
						</CardBody>
					</Card>
				</Box>
				<Box w="100%" className="flex-box">
					<Box className="market-reg-box7">
						<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
							<CardBody>
								<Box w="100%" className="flex-box">
									<Box className="market-reg-box13">
										<Text fontSize="14px" className="mb-1">
											Category
										</Text>
									</Box>
									{
										//JSON.stringify(category)
									}
									<Box className="market-reg-box14">
										<Select width="100%" height="35px" className="text-sm" value={data.itemId} onChange={(e) => categoryChange(e.target.value)} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
											{category?.filter(r => r.userSelectable == 1)?.map((val, i) => (
												<option key={i} value={val._id} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
													{val.item}
												</option>
											))}
										</Select>
									</Box>
								</Box>
								<Box w="100%" className="flex-box mt-2.5">
									<Box className="market-reg-box13">
										<Text fontSize="14px" className="mb-1">
											Sub-category
										</Text>
									</Box>
									<Box className="market-reg-box14">
										<Select width="100%" height="35px" className="text-sm" value={data.nodeId} onChange={(e) => nodeChange(e.target.value)} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
											{category?.find((r) => r._id == data.itemId)?.nodes?.filter(r => r.userSelectable == 1)?.map((val, i) => (
												<option key={i} value={val.id} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
													{val.text}
												</option>
											))}
										</Select>
									</Box>
								</Box>
							</CardBody>
						</Card>
					</Box>
					<Box className="market-reg-box8">
						<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
							<CardBody>
								<Box w="100%" className="flex-box">
									<Box className="life-reg-box11">
										<Text fontSize="14px">Business price per user</Text>
									</Box>
									<Box className="life-reg-box12">
										<InputGroup size="sm">
											<Input w="100%" size="sm" value={data?.businessCost} onChange={(e) => update('businessCost', e.target.value)} />
											<InputRightAddon color="black">/ Week</InputRightAddon>
										</InputGroup>
									</Box>
								</Box>
								<Box w="100%" className="flex-box mt-2.5">
									<Box className="life-reg-box11">
										<Text fontSize="14px">Member price</Text>
									</Box>
									<Box className="life-reg-box12">
										<InputGroup size="sm">
											<Input w="100%" size="sm" value={data?.memberCost} onChange={(e) => update('memberCost', e.target.value)} />
											<InputRightAddon color="black">/ Week</InputRightAddon>
										</InputGroup>
									</Box>
								</Box>
							</CardBody>
						</Card>
					</Box>
				</Box>
				<Box>
					<Box w="100%" className="flex-box">
						<Box className="market-reg-box7">
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardBody>
									<Box w="100%" className="flex-box">
										{data?.serviceList?.length > 0 && <Box w="100%" className="flex-box mb-[10px]">
											{data?.serviceList?.map((val, i) => <Code key={i} colorScheme="green" className="centering-h mr-[10px] mb-[10px] text-[14px]">
												{val}
												<SmallCloseIcon className="ml-[20px] normal-hover" onClick={() => handleServiceItemDelete(i)} />
											</Code>)}
										</Box>}
										<Box className="market-reg-box9">
											<Text fontSize="14px" className="mb-1">
												Service item
											</Text>
										</Box>
										<Box className="market-reg-box10">
											<Input w="100%" size="sm" className="mb-[10px]" value={serviceItem} onChange={(e) => setServiceItem(e.target.value)} />
											<Box display="flex" justifyContent="flex-end">
												<G2Button20 widthVal="100px" heightVal="30px" text="Add" onClick={handleServiceItemAdd} />
											</Box>
										</Box>
									</Box>
								</CardBody>
							</Card>
						</Box>
					</Box>
				</Box>

				<Box w="100%" className="flex-box">
					<Box display="flex" justifyContent="flex-end" alignItems="end" w="100%">
						<Box className="text-left market-preview-btn mr-[20px]">
							<G2Button13 widthVal="150px" heightVal="40px" text="Preview" icon="preview" onClick={onOpen} />
						</Box>
						<Box className="text-right mr-[20px]">
							<G2Button13 widthVal="200px" heightVal="40px" text={content == null ? "Save to draft" : "Update"} icon="save" onClick={() => handleSaveContent()} />
						</Box>
						{content != null && <Box className="text-right">
							<G2Button13 widthVal="200px" heightVal="40px" text={"Add to Marketplace"} icon="save" onClick={() => handleSaveContent(true)} />
						</Box>}
					</Box>
				</Box>
			</Box >

			<Modal size="full" onClose={onClose} isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Preview</ModalHeader>
					<ModalCloseButton />
					<ModalBody p="0">
						<HStack w="100%" justifyContent="center">
							<Box w="1024px">
								<ViewForm content={data} isPreview={true} />
							</Box>
						</HStack>
					</ModalBody>
				</ModalContent>
			</Modal >
		</Box >
	);
};

export default RegisterForm;
