
import {
    Box,
    Text,
    Image,
    Divider,
    Card,
    CardBody,
    Select,
    Progress,
    Circle,
    useToast,
    InputGroup,
    Input,
    InputRightElement,
    IconButton,
    Checkbox,
    HStack
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useStateContext } from "../ContextProvider/Context";
import Navbar from "../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import plan from "../assets/create/plan.png";
import G2Button20 from "../Components/Common/G2Button20";
import { getAffiliateCodes, getAffiliateCodeUsers, createAffiliateCode, getUserCredits } from "../Store/Actions/otherAction";
import { useDispatch } from "react-redux";
import g2Toast from "../Custom/g2Toast";
import { useSelector } from "react-redux";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { formatCurrency } from "../helpers/formatters";
import InnerLeftSideBar from "../Components/InnerLeftSideBar";

function AffiliatePage() {
    const [affiliateCode, setAffiliateCode] = useState('');
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();


    const dispatch = useDispatch();
    const toast = useToast();

    const affiliateCodes = useSelector((state) => state.Other.affiliateCodes);
    const affiliateCodeUsers = useSelector((state) => state.Other.affiliateCodeUsers);
    const userCredits = useSelector((state) => state.Other.userCredits);

    useEffect(() => {
        dispatch(getAffiliateCodes(toast));
    }, []);

    useEffect(() => {
        dispatch(getAffiliateCodeUsers(toast));
    }, []);

    useEffect(() => {
        dispatch(getUserCredits(toast));
    }, []);

    const createAffiliateCodeCallback = useCallback(() => {
        if (!affiliateCode.match(/[A-Za-z][A-Za-z0-9]{4}/)) {
            g2Toast({ toast, status: "error", title: "Affiliate Code Must be 5 Letters or more and contain starting letter followed by letters and numbers" });
        }

        dispatch(createAffiliateCode(toast, affiliateCode));
        setAffiliateCode("");
    }, [affiliateCode]);

    const totalRebate = useMemo(() => {
        return affiliateCodeUsers.reduce((s, r) => {
            s += r.totalRebate;
            return s;
        }, 0);
    }, [affiliateCodeUsers]);

    const [isOpen, setIsOpen] = useState(true)
    const [innerLeftWidth, setInnerLeftWidth] = useState(250);
    useEffect(() => {
        if (isOpen) {
            setInnerLeftWidth(250);
        } else {
            setInnerLeftWidth(80);
        }
    }, [isOpen]);

    return <Box w="100%" minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
        <Box w="100%">
            <Navbar title="Create" />
        </Box>
        <Box w="100%" className="flex-box">
            <Box className="universal-leftBar-w">
                <Box w="100%" h="100vh" className="sticky top-0">
                    <UniversalLeftSideBar />
                </Box>
            </Box>
            <Box className="universal-body-w flex">
                <HStack height="100%" w="100%">
                    <Box h="100%" w={innerLeftWidth + "px"}>
                        <InnerLeftSideBar isOpen={isOpen} setIsOpen={setIsOpen}>
                            <Text fontSize="16px" className="font-semibold mx-[10px]">Affiliate Payment Summary</Text>
                            <Box w="100%" className="flex">
                                <Text fontSize="16px" className="font-semibold mx-[10px]">Earned</Text>
                                <Text fontSize="16px" className="font-semibold mx-[10px]">{formatCurrency(totalRebate)}</Text>
                            </Box>
                            <Box w="100%" className="flex">
                                <Text fontSize="16px" className="font-semibold mx-[10px]">Received</Text>
                                <Text fontSize="16px" className="font-semibold mx-[10px]">{formatCurrency(userCredits.totalcredits)}</Text>
                            </Box>
                            <Box w="100%" className="flex">
                                <Text fontSize="16px" className="font-semibold mx-[10px]">Total</Text>
                                <Text fontSize="16px" className="font-semibold mx-[10px]">{formatCurrency(totalRebate - userCredits.totalcredits)}</Text>
                            </Box>
                            <br />
                            <Text fontSize="16px" className="font-semibold mx-[10px]">Affiliate Codes</Text>
                            {affiliateCodes.map(r => <Text key={r} fontSize="16px" className="font-semibold mx-[10px]">{r.affiliateCode}</Text>)}
                        </InnerLeftSideBar>
                    </Box>
                    <Box h="100%" w={`calc(100% - ${innerLeftWidth}px)`}>
                        <Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h px-[30px] shadow">
                            <Box w="100%" className="flex-box justify-between">
                                <Box className="flex-box text-left centering-h">
                                    <Image boxSize="30px" src={plan} alt='icon' />
                                    <Text fontSize="16px" className="font-semibold mx-[10px]">Affiliate Code</Text>
                                </Box>
                                <Box className="flex-box text-right centering-h">
                                    <Box className="mr-5 normal-hover flex">
                                        <Input type="text" value={affiliateCode} onChange={(e) => setAffiliateCode(e.target.value)}></Input>
                                    </Box>
                                    <G2Button20 widthVal="150px" heightVal="35px" text="Create Code" onClick={createAffiliateCodeCallback} />
                                </Box>
                            </Box>
                        </Box>
                        <TableContainer>
                            <Table variant='striped'>
                                <Thead>
                                    <Tr>
                                        <Th>User</Th>
                                        <Th>Affiliate Code</Th>
                                        <Th>Paid Days</Th>
                                        <Th>Affiliate Payment</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {affiliateCodeUsers.map(r =>
                                        <Tr key={r.id}>
                                            <Td>{r.name}</Td>
                                            <Td>{r.affiliateCode}</Td>
                                            <Td>{r.totalDays}</Td>
                                            <Td>{formatCurrency(r.totalRebate)}</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </HStack>
            </Box >
        </Box >
    </Box >
}

export default AffiliatePage;