import React, { useState } from 'react';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { useStateContext } from '../../ContextProvider/Context';

const StreetAddressInput = ({ address, setAddress, label }) => {
  const apiKey = 'AIzaSyCAfEueuauGgt9cjF-E25QSNY8AL1SW8Tk';

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  // const [address, setAddress] = useState('');

  // const isStreet = (description) => {
  //   return description.toLowerCase().includes('street');
  // };

  const handleSelect = async (selected) => {
    try {
      const results = await geocodeByAddress(selected);
      setAddress(selected);
    } catch (error) {
      console.error('Error fetching location', error);
    }
  };

  return (
    <PlacesAutocomplete value={address} onChange={(value) => setAddress(value)} onSelect={handleSelect} googleCallbackName={`init${apiKey.replace(/-/g, '')}`}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="mt-3 relative" style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="first-name" style={{ color: `${G2_color_3}` }} className="block text-sm font-semibold leading-6">
            {label}
          </label>
          <div className="mt-1">
            <input {...getInputProps({ style: { background: `${G2_color_2}`, color: `${G2_color_3}` }, className: "block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6", })} />
          </div>
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {/* {suggestions.filter((suggestion) => isStreet(suggestion.description)) */}
            {suggestions.map((suggestion, i) => (
              <div key={i}
                {...getSuggestionItemProps(suggestion, {
                  className: suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item',
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default StreetAddressInput;