import React from "react";
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';

import { useStateContext } from '../../ContextProvider/Context';

const G2Button18 = ({ widthVal, heightVal, isFirst, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="centering pr-3 block rounded-full bg-[#334F72] px-1.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#233b59] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {text}
    </button>
  );
}

export default G2Button18;