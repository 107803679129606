import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
  CheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";

import { signUpUser } from "../../Store/Actions/authAction";
import g2Toast from "../../Custom/g2Toast";

const industryOptions = [
  "Construction",
  "Mechanical Trades",
  "Electrical Trades",
  "Horticulture and Landscaping",
  "Culinary Arts",
];

const learningGoalsOptions = [
  "Learn about different trades and career options.",
  "Find an apprenticeship opportunity.",
  "Gain basic skills to start a trade career.",
  "Connect with mentors and industry professionals.",
  "Get certifications or qualifications.",
];

const tradeOptions = {
  Construction: ["Carpenter", "Electrician", "Plumber"],
  Mechanical: ["Mechanic", "Boilermaker", "Fitter"],
  Culinary: ["Chef", "Baker"],
};

const LiteForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const toast = useToast();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    profile: "",
    address: "",
    phoneNumber: "",
    dateOfBirth: "",
    isEmployed: "",
    employerName: "",
    schoolStatus: "",
    trade: "",
    industry: "",
    learningGoals: [],
    specificTrade: "",
    connectMentor: "",
    exploreResources: "",
    connectOpportunities: "",
    notificationPreferences: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (value) => {
    setFormData((prev) => ({ ...prev, learningGoals: value }));
  };

  const validateStep = () => {
    if (step === 1) {
      if (
        !formData.fullName ||
        !formData.email ||
        !formData.password ||
        !formData.confirmPassword ||
        !formData.address ||
        !formData.phoneNumber
      ) {
        g2Toast({ toast, status: "error", title: "Missing required input!" });
        return false;
      }
      if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
        g2Toast({ toast, status: "error", title: "Invalid Email address!" });
        return false;
      }
      if (formData.isEmployed === "Yes" && !formData.employerName) {
        g2Toast({
          toast,
          status: "error",
          title: "Please provide your employer's name!",
        });
        return false;
      }
      if (formData.password !== formData.confirmPassword) {
        g2Toast({ toast, status: "error", title: "Passwords do not match!" });
        return;
      }
    } else if (step === 2) {
      if (!formData.schoolStatus) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select your status!",
        });
        return false;
      }
      if (formData.schoolStatus === "Apprentice" && !formData.trade) {
        g2Toast({ toast, status: "error", title: "Please select your trade!" });
        return false;
      }
      if (formData.schoolStatus === "School Student" && !formData.industry) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select your industry of interest!",
        });
        return false;
      }
    } else if (step === 3) {
      if (formData.learningGoals.length === 0) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one learning goal!",
        });
        return false;
      }
      if (!formData.specificTrade) {
        g2Toast({
          toast,
          status: "error",
          title: "Please specify if you know a specific trade!",
        });
        return false;
      }
      if (!formData.connectMentor) {
        g2Toast({
          toast,
          status: "error",
          title: "Please specify if you want to connect with a mentor!",
        });
        return false;
      }
    } else if (step === 4) {
      if (!formData.exploreResources) {
        g2Toast({
          toast,
          status: "error",
          title: "Please specify if you want to explore resources!",
        });
        return false;
      }
      if (!formData.connectOpportunities) {
        g2Toast({
          toast,
          status: "error",
          title:
            "Please specify if you want to connect with apprenticeship opportunities!",
        });
        return false;
      }
    } else if (step === 5) {
      if (!formData.notificationPreferences.length) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one notification preference!",
        });
        return false;
      }
    }
    return true;
  };

  const nextStep = () => {
    if (validateStep()) setStep((prev) => prev + 1);
  };

  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = () => {
    const data = { ...formData, type: "Lite", userTypeId: 1 };
    dispatch(signUpUser(toast, navigate, data));
  };

  return (
    <>
      {/* Step 1: Personal Information */}
      {step === 1 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 1: Personal Information
          </Text>
          <FormControl id="fullName" isRequired>
            <FormLabel>Full Name</FormLabel>
            <Input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="confirmPassword" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="profile" isRequired>
            <FormLabel>Select your profile</FormLabel>
            <Select
              name="profile"
              value={formData.profile}
              onChange={handleInputChange}
            >
              <option value="">Choose Profile</option>
              <option value="School Student">School Student</option>
              <option value="Apprentice">Apprentice</option>
              <option value="Job Seeker">Job Seeker</option>
              <option value="Parent">Parent</option>
            </Select>
          </FormControl>
          <FormControl id="address" isRequired>
            <FormLabel>Address</FormLabel>
            <Input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="phoneNumber" isRequired>
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="dateOfBirth">
            <FormLabel>Date of Birth</FormLabel>
            <Input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="isEmployed" isRequired>
            <FormLabel>Are You Employed?</FormLabel>
            <RadioGroup
              name="isEmployed"
              value={formData.isEmployed}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, isEmployed: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          {formData.isEmployed === "Yes" && (
            <FormControl id="employerName" isRequired>
              <FormLabel>Employer Name</FormLabel>
              <Input
                type="text"
                name="employerName"
                value={formData.employerName}
                onChange={handleInputChange}
              />
            </FormControl>
          )}
          <Box className="w-full flex items-end justify-end">
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 2: Select School Status or Apprentice Role */}
      {step === 2 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 2: Select School Status or Apprentice Role
          </Text>
          <FormControl id="schoolStatus" isRequired>
            <FormLabel>Are You a School Student or Apprentice?</FormLabel>
            <RadioGroup
              name="schoolStatus"
              value={formData.schoolStatus}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, schoolStatus: value }))
              }
            >
              <Stack direction="row">
                <Radio value="School Student">School Student</Radio>
                <Radio value="Apprentice">Apprentice</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          {formData.schoolStatus === "Apprentice" && (
            <FormControl id="trade" isRequired>
              <FormLabel>If Apprentice, Select Trade</FormLabel>
              <Select
                name="trade"
                value={formData.trade}
                onChange={handleInputChange}
              >
                <option value="">Choose Trade</option>
                {Object.keys(tradeOptions).map((category) => (
                  <optgroup label={category} key={category}>
                    {tradeOptions[category].map((trade) => (
                      <option key={trade} value={trade}>
                        {trade}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </FormControl>
          )}
          {formData.schoolStatus === "School Student" && (
            <FormControl id="industry" isRequired>
              <FormLabel>
                If School Student, Select Industry of Interest
              </FormLabel>
              <Select
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
              >
                <option value="">Choose Industry</option>
                {industryOptions.map((industry) => (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 3: Learning Goals */}
      {step === 3 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Learning Goals
          </Text>
          <FormControl id="learningGoals" isRequired>
            <FormLabel>What Are Your Learning Goals on UTRADIE?</FormLabel>
            <CheckboxGroup onChange={handleCheckboxChange}>
              <Stack spacing={4}>
                {learningGoalsOptions.map((goal) => (
                  <Checkbox key={goal} value={goal}>
                    {goal}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <FormControl id="specificTrade" isRequired>
            <FormLabel>Do You Have a Specific Trade in Mind?</FormLabel>
            <RadioGroup
              name="specificTrade"
              value={formData.specificTrade}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, specificTrade: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl id="connectMentor" isRequired>
            <FormLabel>
              Are You Interested in Connecting with a Mentor?
            </FormLabel>
            <RadioGroup
              name="connectMentor"
              value={formData.connectMentor}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, connectMentor: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 4: Explore Learning Resources */}
      {step === 4 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 4: Explore Learning Resources
          </Text>
          <FormControl id="exploreResources" isRequired>
            <FormLabel>
              Would You Like to Start Exploring Learning Resources Now?
            </FormLabel>
            <RadioGroup
              name="exploreResources"
              value={formData.exploreResources}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, exploreResources: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl id="connectOpportunities" isRequired>
            <FormLabel>
              Do You Want to Connect with Apprenticeship Opportunities?
            </FormLabel>
            <RadioGroup
              name="connectOpportunities"
              value={formData.connectOpportunities}
              onChange={(value) =>
                setFormData((prev) => ({
                  ...prev,
                  connectOpportunities: value,
                }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 5: Confirmation and Customization */}
      {step === 5 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 5: Confirmation and Customization
          </Text>
          <Box borderWidth={1} borderRadius="lg" p={4}>
            <Text fontWeight="bold">Review Your Details:</Text>
            <Text>Full Name: {formData.fullName}</Text>
            <Text>Email: {formData.email}</Text>
            <Text>Phone Number: {formData.phoneNumber}</Text>
            <Text>Status: {formData.schoolStatus}</Text>
            <Text>
              Trade/Industry:{" "}
              {formData.schoolStatus === "Apprentice"
                ? formData.trade
                : formData.industry}
            </Text>
          </Box>
          <FormControl id="notificationPreferences" isRequired>
            <FormLabel>How would you like to receive updates?</FormLabel>
            <CheckboxGroup
              onChange={(value) =>
                setFormData((prev) => ({
                  ...prev,
                  notificationPreferences: value,
                }))
              }
            >
              <Stack spacing={4}>
                <Checkbox value="Email">Email</Checkbox>
                <Checkbox value="SMS">SMS</Checkbox>
                <Checkbox value="Push Notifications">
                  Push Notifications
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button
              colorScheme="orange"
              onClick={() => {
                if (validateStep()) {
                  handleSubmit();
                }
              }}
            >
              Sign up
            </Button>
          </Box>
        </VStack>
      )}
    </>
  );
};

export default LiteForm;
