import React from "react";
import { DateTime } from "luxon";
import reactStringReplace from "react-string-replace";
import UTradieText from "../Components/Common/UTradieText";
import _ from "lodash";

const formatterAUD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
});

export function formatCurrency(value) {
    return formatterAUD.format(value);
}

export function formatDateTime(value) {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
}

export function formatDate(value) {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
}

export function formatRelativeTime(value) {
    if (value == null || value == undefined) {
        return value;
    }

    return DateTime.fromISO(value).toRelative();
}

export function formatContentText(text) {
    text = reactStringReplace(text, /(utradie)/ig, (match, i) => {
        return <UTradieText key={match + i} />
    });

    text = reactStringReplace(text, /(https?:\/\/\S+)/g, (match, i) => (
        <a key={match + i} href={match}>{match}</a>
    ));

    text = reactStringReplace(text, /(.+?\:)/g, (match, i) => {
        return <div key={match + i} className="font-bold inline">{match}</div>
    });

    text = reactStringReplace(text, /(~~)/g, (match, i) => {
        return <div key={match + i} className="mb-[10px]">&nbsp;</div>
    });

    return text;
};

export function formatPercentage(value, percision = 2) {
    return _.round(value * 100.0, percision) + '%';
}