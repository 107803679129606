import React from "react";

import { useStateContext } from "../../ContextProvider/Context";

const G2Button10 = ({ widthVal, heightVal, disabled, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="centering mr-2 pr-3 block rounded-md bg-[var(--orange)] px-1.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#2a956e] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {text}
    </button>
  );
};

export default G2Button10;
