import React from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useStateContext } from "../../ContextProvider/Context";
import Navbar from "../../Components/Navbar/Navbar";
import SignUpForm from "../../Components/SignUp/SignUpForm";

const SignUpPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();
  const location = useLocation();
  const { tierType } = location.state || {}; // Get the tier type from state

  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Navbar title="Register" />
      <SignUpForm tierType={tierType} /> {/* Pass tierType to SignUpForm */}
    </Box>
  );
};

export default SignUpPage;
