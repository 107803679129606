import React from "react";

import { useStateContext } from "../../ContextProvider/Context";

const G2Button24 = ({ text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <div className="mt-[10px] w-[220px] h-[40px] p-[3px] bg-[var(--orange)] rounded-full centering">
      <button
        onClick={onClick}
        className="w-[214px] h-[34px] bg-[#032c47] text-white font-semibold rounded-full hover:bg-[#49688F]"
      >
        {text}
      </button>
    </div>
  );
};

export default G2Button24;
