import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const getGoalList = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/goal")
      .then((res) => {
        console.log("API Response for Goal List:", res);
        dispatch({ type: "GOAL_LIST", payload: res.data.goalList });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering goal data:", error);
      });
  };
};

const addGoal = (toast, startDate, endDate, title) => {
  return (dispatch) => {
    g2AxiosT
      .post("/goal/add", { startDate, endDate, title })
      .then((res) => {
        dispatch({ type: "GOAL_LIST", payload: res.data.goalList });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering goal data:", error);
      });
  };
};

const updateGoal = (toast, id, percent, comment) => {
  return (dispatch) => {
    g2AxiosT
      .put("/goal/update/" + id, { percent, comment })
      .then((res) => {
        dispatch({ type: "GOAL_LIST", payload: res.data.goalList });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating goal data:", error);
      });
  };
};

const deleteGoal = (toast, ids) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/goal/delete", { data: { ids } })
      .then((res) => {
        dispatch({ type: "GOAL_LIST", payload: res.data.goalList });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating goal data:", error);
      });
  };
};

export { getGoalList, addGoal, updateGoal, deleteGoal };
