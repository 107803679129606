import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Button,
  Text,
  Input,
  Divider,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import { SmallCloseIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { addMember } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button20 from "../Common/G2Button20";

const DefaultGroupAddModal = (props) => {
  const { isDGAddOpen, closeDGAddModal } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toast = useToast();

  const groupList = useSelector((state) => state.Create.groupList);
  const member = useSelector((state) => state.Create.member);

  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleGroupSelect = (id) => {
    if (selectedGroups.includes(id)) {
      setSelectedGroups(selectedGroups.filter((val) => val != id));
    } else {
      setSelectedGroups([...selectedGroups, id]);
    }
  };

  const handleMemeberAdd = () => {
    if (!isEmptyValue(selectedGroups[0])) {
      dispatch(addMember(toast, member._id, selectedGroups, member.memberId._id));
    } else {
      g2Toast({ toast, status: "error", title: "Select group!" });
    }

    closeDGAddModal();
  };

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  const userGroups = useMemo(() => {
    const assignedGroupIds = (member?.assignedGroupIds ?? []).map(r => r.groupId);
    return groupList?.filter((element) => !assignedGroupIds?.includes(element._id))
  }, [groupList, member.assignedGroupIds]);

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isDGAddOpen}>
        <ModalOverlay />
        <ModalContent style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box w="100%" className="flex-box justify-between centering-h mb-[10px]">
                <Box className="flex-box text-left">
                  <Text className="block text-lg font-semibold" style={{ color: `${G2_color_3}` }}>
                    Add to group
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon className="exit-icon" style={{ color: `${G2_color_3}` }} onClick={closeDGAddModal} />
                </Box>
              </Box>
              <Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" className="mb-[10px]" />
              {groupList ? (
                <Box w="100%" className="mt-[10px]">
                  {
                    //JSON.stringify(userGroups)
                  }
                  {userGroups?.map((val, i) => (
                    <Box key={i} w="100%">
                      <Checkbox size="md" className="mr-5" borderColor="blue.500" colorScheme="green" isChecked={selectedGroups.includes(val._id)} onChange={() => handleGroupSelect(val._id)}>
                        {val.title}
                      </Checkbox>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box w="100%">
                  <Text w="100%" className="text-sm">
                    You have no any other group.
                  </Text>
                </Box>
              )}
              <Box w="100%" className="mt-5">
                <G2Button20 widthVal="100%" heightVal="40px" text="Add" onClick={handleMemeberAdd} />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DefaultGroupAddModal;
