import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";

import Navbar from "../../Components/Navbar/Navbar";
import PreviewForm from "../../Components/Life/PreviewForm";

import UniversalLeftSideBar from "../../Components/Common/UniversalLeftSideBar";

const PreviewPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);
  const today = useSelector((state) => state.Auth.today);

  const token = getToken();

  useEffect(() => {
    if (!verifyToken()) {
      navigate("/login");
    }

    const startDate = new Date(today);
    const endDate = new Date(user.expire);

    if (startDate > endDate) {
      navigate("/billing");
    }
  }, []);

  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%">
        <Navbar title="Connections" />
      </Box>
      <Box w="100%" className="flex-box">
        <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>
        <Box className="universal-body-w">
          <PreviewForm />
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewPage;
