import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Text, Link, Image, Card, CardBody } from "@chakra-ui/react";
import { CheckCircleIcon, LinkIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";

import G2Button1 from "../Common/G2Button1";
import G2Button2 from "../Common/G2Button2";

import twitter from "../../assets/social_icon3/twitter.png";
import facebook from "../../assets/social_icon3/facebook.png";
import instagram from "../../assets/social_icon3/instagram.png";
import tiktok from "../../assets/social_icon3/tiktok.png";
import spotify from "../../assets/social_icon3/spotify.png";
import youtube from "../../assets/social_icon3/youtube.png";

const socialData = [
  {
    img: twitter,
    title: "Twitter",
  },
  {
    img: facebook,
    title: "Facebook",
  },
  {
    img: instagram,
    title: "Instagram",
  },
  {
    img: tiktok,
    title: "Tiktok",
  },
  {
    img: spotify,
    title: "Spotify",
  },
  {
    img: youtube,
    title: "Youtube",
  },
];

const SocialShareForm = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const [selectElement, setSelectElement] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const onclickCheck = (index) => {
    setSelectElement((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const onClickBack = () => {
    navigate("/life");
  };

  return (
    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box className="title-text" style={{ marginTop: "50px" }}>
        Share on Social Media
      </Box>
      <Text className="text-center mt-10">
        Tell your friends, family members and neighbors
      </Text>
      <Text className="text-center">Share it with your community.</Text>

      <Box w="100%" className="market-social-box-group flex-box">
        {socialData.map((element, index) => (
          <Box key={index} className="market-social-box">
            <Card
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
              onClick={() => onclickCheck(index)}
            >
              <CardBody w="100%" className="centering">
                <Image
                  src={element.img}
                  w="50px"
                  h="50px"
                  className="responsive-img"
                />
              </CardBody>
            </Card>
            <Box w="100%" className="text-center mt-5">
              <CheckCircleIcon
                w="30px"
                h="30px"
                color={`${selectElement[index] ? "green.500" : "gray.300"}`}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box w="100%" className="centering-w pb-10">
        <Box className="flex flex-wrap centering-w">
          <G2Button1
            widthVal="200px"
            text="Back to Connections Page"
            onClick={onClickBack}
          />
          <G2Button2 widthVal="200px" text="Share Now" />
        </Box>
      </Box>
    </Box>
  );
};

export default SocialShareForm;
