// SocialConnectForm.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Link,
} from "@chakra-ui/react";
import _ from "lodash";
import { useStateContext } from "../../ContextProvider/Context";
import { socialMediaTypes } from "../../helpers/general"; 
import {EditIcon } from '@chakra-ui/icons' // Ensure this path is correct

const SocialConnectForm = ({ userData, setUserData }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const [socialMedia, setSocialMedia] = useState(socialMediaTypes);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSocial, setSelectedSocial] = useState(null); // Track selected social media
  const [inputValue, setInputValue] = useState("");

  // Initialize social media selection based on userData
  useEffect(() => {
    if (!userData?.socialMedia) {
      return;
    }

    let temp = _.cloneDeep(socialMedia);
    for (const name in userData.socialMedia) {
      if (temp[name]) {
        temp[name].isSelected = true;
        temp[name].url = userData.socialMedia[name];
      }
    }
    setSocialMedia(temp);
  }, [userData]);

  // Handle opening the modal and setting the selected social media
  const handleIconClick = (socialKey) => {
    setSelectedSocial(socialKey);
    setInputValue(socialMedia[socialKey].url || socialMedia[socialKey].default);
    onOpen();
  };

  // Handle updating selection and URL
  const handleSave = () => {
    // Update social media URL and selection without validation
    let updatedSocialMedia = _.cloneDeep(socialMedia);
    updatedSocialMedia[selectedSocial].url = inputValue;
    updatedSocialMedia[selectedSocial].isSelected = inputValue !== "";

    setSocialMedia(updatedSocialMedia);

    // Update userData accordingly
    if (updatedSocialMedia[selectedSocial].isSelected) {
      updateUserData(["socialMedia", selectedSocial], inputValue);
    } else {
      updateUserData(["socialMedia", selectedSocial], undefined);
    }

    onClose();
  };

  // Update userData with new URL
  const updateUserData = (path, value) => {
    let temp = _.cloneDeep(userData);
    temp = _.set(temp, path, value);
    setUserData(temp);
  };

  // Handle URL input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  return (
    <>
      <Box width="100%">
        {/* First Row - Display the first 3 social media icons */}
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={4} width="100%" mb={4}>
          {Object.keys(socialMedia).slice(0, 3).map((key) => {
            const social = socialMedia[key];
            return (
              <Box
              key={key}
              position="relative" // Ensure relative positioning for the card
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              flexBasis={{ base: "100%", md: "30%" }}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              p={4}
              >
                <IconButton
                  icon={<EditIcon />}
                  size="sm"
                  position="absolute"
                  top="2" // Adjust position as needed
                  right="2"
                  aria-label={`Edit ${social.name}`}
                  // onClick={() => handleEditClick(key)}
                  variant="ghost"
                />
                <Image
                  src={social.img}
                  borderRadius="full"
                  boxSize="40px"
                  cursor="pointer"
                  onClick={() => handleIconClick(key)}
                  border={social.isSelected ? "2px solid green" : "2px solid transparent"}
                  _hover={{ border: "2px solid blue" }}
                  alt={social.name}
                />
                <Text fontWeight="bold" mt={2}>{social.name}</Text>
                <Link href={social.default} color="blue.500" isExternal>
                  {social.default}
                </Link>
              </Box>
            );
          })}
        </Box>

        {/* Second Row - Display the remaining 3 social media icons */}
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={4} width="100%">
          {Object.keys(socialMedia).slice(3, 6).map((key) => {
            const social = socialMedia[key];
            return (
              <Box
              key={key}
              position="relative" // Ensure relative positioning for the card
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              flexBasis={{ base: "100%", md: "30%" }}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              p={4}
              >

                <IconButton
                  icon={<EditIcon />}
                  size="sm"
                  position="absolute"
                  top="2" // Adjust position as needed
                  right="2"
                  aria-label={`Edit ${social.name}`}
                  // onClick={() => handleEditClick(key)}
                  variant="ghost"
                />
                <Image
                  src={social.img}
                  borderRadius="full"
                  boxSize="40px"
                  cursor="pointer"
                  onClick={() => handleIconClick(key)}
                  border={social.isSelected ? "2px solid green" : "2px solid transparent"}
                  _hover={{ border: "2px solid blue" }}
                  alt={social.name}
                />
                <Text fontWeight="bold" mt={2}>{social.name}</Text>
                <Link href={social.default} color="blue.500" isExternal>
                  {social.default}
                </Link>
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* Modal for displaying and editing social media details */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedSocial && socialMedia[selectedSocial].name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSocial && (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={4}
                >
                  {/* Redirect on Icon Click using standard <a> tag */}
                  <a
                    href={socialMedia[selectedSocial].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => alert(socialMedia[selectedSocial].url)} // Logging the URL to the console
                  >
                    <Image
                      src={socialMedia[selectedSocial].img}
                      boxSize="100px"
                      borderRadius="full"
                      alt={socialMedia[selectedSocial].name}
                      cursor="pointer"
                      _hover={{ transform: "scale(1.05)" }}
                      transition="transform 0.2s"
                    />
                  </a>
                </Box>
                <Text mb={4}>{socialMedia[selectedSocial].description}</Text>
                <FormControl>
                  <FormLabel>URL</FormLabel>
                  <Input
                    type="url"
                    placeholder={socialMedia[selectedSocial].default}
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SocialConnectForm;
