import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  IconButton,
  AspectRatio,
  Select,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  useToast,
} from "@chakra-ui/react";

import { StarIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { addToBusket } from "../../Store/Actions/lifeAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import ReviewModal from "./ReviewModal";

import G2Button1 from "../Common/G2Button1";
import G2Button2 from "../Common/G2Button2";

import cover from "../../assets/life/cover.png";
import logo from "../../assets/life/logo.png";

import yesImg from "../../assets/marketplace/yesImg.png";
import noImg from "../../assets/marketplace/noImg.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";
import DynamicFileViewer from "../DynamicFileViewer";
import { formatCurrency } from "../../helpers/formatters";

const ViewForm = ({ content }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();
  const [buyingMode, setBuyingMode] = useState('memberCost');
  const [isOpen, setIsOpen] = useState(false);
  const [mark, setMark] = useState(0);

  useEffect(() => {
    if ((content?.review?.length ?? 0) == 0) {
      setMark(0);
      return;
    }

    let rating = content.review?.reduce((s, r) => s + r.mark, 0);
    setMark((rating / content.review.length).toFixed(1));
  }, [content]);

  const video = "video.mp4";

  const openReviewModal = () => {
    setIsOpen(true);
  };

  const onClickBack = () => {
    navigate("/life");
  };

  const onClickBucket = () => {
    dispatch(addToBusket(toast, content._id));

    navigate("/life/bucket");
  };

  return (
    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box className="life-preview-main flex-box centering-h">
        <Box className="market-preview-cover px-5 centering">
          <DynamicFileViewer width="100%" height="auto" file={content.cover} alt="uploadedImage" />
        </Box>
        <Box className="market-preview-descripton flex-box centering-h">
          <Box w="100%">
            <Text className="font-semibold text-white" fontSize="26px">
              {content.name}
            </Text>
          </Box>
          <Box w="100%" className="mt-5">
            <Text className="text-white" fontSize="14px">
              Works for
            </Text>
            <Text className="font-semibold text-[#FFC529]" fontSize="16px">
              {content.businessName}
            </Text>
          </Box>
          <Box w="100%" className=" mt-5">
            <Text className="text-white" fontSize="16px">
              {content.bioDescription}
            </Text>
          </Box>
          <Box w="100%" className="flex-box mt-5 centering-h">
            <StarIcon color="yellow.300" className="mr-2" />
            <Text className="text-white mr-5" fontSize="16px">
              {mark}/5
            </Text>
            <G2Button2 widthVal="120px" heightVal="35px" text="Add a review" onClick={openReviewModal} />
          </Box>
        </Box>
        <Box className="market-preview-logo flex-box centering">
          <Box w="100%" className="centering-w">
            <DynamicFileViewer borderRadius="full" boxSize="150px" file={content.logo} alt="uploadedImage" />
          </Box>
          <Box w="100%" className="flex-box centering-w mt-5">
            <Text w="100%" className="text-center font-semibold text-[#FFC529]" fontSize="16px">
              {content.businessName}
            </Text>
            <Text w="100%" className="text-center font-semibold text-[#35D8A7]" fontSize="16px">
              {formatCurrency(content[buyingMode])}/Week
            </Text>
          </Box>
          <Box w="100%" className="centering-w mt-5">
            <Select size="sm" w="210px" height="35px" bg="white" color="black" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, borderRadius: "2.5px", }} value={buyingMode} onChange={(e) => setBuyingMode(e.target.value)}>
              <option value="memberCost" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
                Individual Member
              </option>
              <option value="businessCost" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
                Business
              </option>
            </Select>
          </Box>
        </Box>
      </Box>
      <Box className="flex-box" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
        <Box className="market-preview-video">
          <AspectRatio w="100%" ratio={16 / 9}>
            <Box>
              <DynamicFileViewer file={content.video} alt="video" />
            </Box>
          </AspectRatio>
        </Box>
        <Box className="market-preview-video-des flex-box centering">
          <Text w="100%" fontSize="16px">
            {content.subDescription}
          </Text>
          <Text w="100%" fontSize="16px">
            {content.videoDescription}
          </Text>
          <Text w="100%" fontSize="16px">
            Click on the video to view a preview
          </Text>
        </Box>
        <Box className="market-preview-table">
          <Box>
            <Text fontSize="20px" className="font-semibold mb-5">
              Services
            </Text>
            <TableContainer css={{ background: `${G2_color_1}`, color: `${G2_color_3}`, borderRadius: "10px", }}>
              <Table>
                <Thead>
                  <Tr>
                    <Th fontSize="16px" p="5" css={{ background: `${G2_color_1}`, color: `${G2_color_3}`, }}>                      Description
                    </Th>
                    <Th fontSize="16px" css={{ background: `${G2_color_1}`, color: `${G2_color_3}`, }}>

                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {content.serviceList?.map((val, i) => (
                    <Tr key={i}>
                      <Td>{val}</Td>
                      <Td>
                        <Image boxSize="20px" src={yesImg} alt="video" />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box w="100%" className="centering-w mb-10">
          <Box className="flex flex-wrap centering-w">
            <G2Button1 widthVal="200px" text="Back to Connections Page" onClick={onClickBack} />
            <G2Button2 widthVal="200px" text="Purchase Content" onClick={onClickBucket} />
          </Box>
        </Box>
      </Box>
      <ReviewModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
};

export default ViewForm;
