function isEmptyObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
                console.log(key)
                return true; // Empty value found
            }
        }
    }
    return false; // No empty values found
}

export default isEmptyObject;