import React from "react";
import CommonExternalPage from "./CommonExternalPage";

const content = [
  {
    title: "About Us",
    body: "Welcome to uTRADIE, a ecosystem born from the vision of a tradie determined to empower every individual within their trade industry. Our journey began with a simple, yet profound belief: the path to success is paved by sharing knowledge, showcasing skills, and supporting one another in a community where everyone has the opportunity to grow—from apprentice to industry influencer."
  },
  {
    title: "Our Foundation",
    body: "uTRADIE was conceived by a tradesperson who saw the transformative potential of technology in bridging the gap between ambition and achievement. In an age where digital resources are at everyone's fingertips, we recognised the need for a platform that not only serves as a showcase for talent and hard work but also as a catalyst for career progression within the trades. Our mission is to provide access to opportunities, ensuring that everyone, regardless of their starting point, has the chance to excel and become a leading voice in their field."
  },
  {
    title: "Our Ecosystem",
    body: "At the heart of uTRADIE lies an innovative platform designed to revolutionise the way tradespeople, business owners, apprentices, mentors, educators, and sponsors connect, collaborate, and thrive. We believe we offer solutions that cater to the diverse needs of our communities, fostering skill development, career advancement, and engagement.",
    points: [
      "For Tradespeople: A dynamic space to exhibit your expertise, access invaluable resources, and network with peers and mentors, empowering you to achieve your fullest potential.",
      "For Businesses, Mentors, and Sponsors: An impactful platform to nurture talent, inspire innovation, and drive growth, enabling you to contribute significantly to shaping the future of the trade industry.",
      "For Apprentices and Students: Your gateway to a world of opportunities, offering access to leading courses, hands-on training, and mentorship, kickstarting your career with confidence.",
      "For Educators and Creators: A venue to share your knowledge, inspire future generations, and build thriving communities, supported by tools and resources to amplify your impact.",
    ]
  },
  {
    title: "Our Features",
    body: "uTRADIE is more than a platform; it's a comprehensive ecosystem designed for growth and success. Our dashboards—My TRADIE Journey, Creator Insights, Mentor & Parent Hub, and Sponsor's Playground—provide personalised experiences for every user, celebrating achievements and facilitating meaningful connections."
  },
  {
    title: "Our Vision and Mission",
    body: "Inspired by the power of community and the promise of progress, uTRADIE's mission is to empower and connect individuals across the trade industries. We envision a world where every tradesperson has the tools and support to climb the ladder of success, transforming their careers and their industries."
  },
  {
    title: "Join Our Community",
    body: "uTRADIE invites you to be part of a transformative journey. Whether you're taking your first steps as an apprentice or seeking to influence the industry as a seasoned professional, our platform is your springboard to unparalleled opportunities and growth. Together, we can redefine what it means to be part of the trades, building a brighter, more connected future for everyone."
  },
  {
    title: "Welcome to uTRADIE, where your journey of growth and success begins.",
    body: ""
  }
];

const AboutUsPage = () => {
  return <CommonExternalPage title="About Us" content={content} />
};

export default AboutUsPage;
