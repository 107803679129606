const tradesData = {
  "Construction and Building Trades": [
    "Builder",
    "Carpenter",
    "Electrician",
    "Plumber",
    "Bricklayer",
    "Masonry",
    "Roofer",
    "Painter",
    "Drywall Installer",
    "Flooring Installer",
  ],
  "Mechanical Trades": [
    "Mechanic - Automotive",
    "Mechanic - Aircraft",
    "Mechanic - Heavy Machinery",
    "HVAC Technician",
    "Welder",
    "Boilermaker",
    "Pipefitter",
    "Millwright",
    "Elevator Mechanic",
  ],

  "Utility and Energy Trades": [
    "Linemen",
    "Power Plant Operators",
    "Wind Turbine Technicians",
    "Solar Panel Installers",
    "Electricians",
    "Gas Plant Operators",
    "Water Treatment Operators",
    "Pipeline Technicians",
    "Nuclear Reactor Operators",
    "HVAC Technicians",
    "Energy Auditors",
    "Oil and Gas Drillers",
    "Renewable Energy Engineers",
    "Electrical Power-Line Installers",
    "Substation Technicians",
    "Metre Readers",
    "Environmental Compliance Inspectors",
    "Hydroelectric Plant Technicians",
    "Geothermal Technicians",
    "Utility Arborists",
  ],

  "Landscaping and Horticulture Trades": [
    "Hardscape Landscapers",
    "Landscape Gardeners",
    "Horticulturists",
    "Arborists",
    "Landscape Designers",
    "Groundskeepers",
    "Turf Management Specialists",
    "Irrigation Technicians",
    "Greenhouse Operators",
    "Nursery Workers",
    "Floral Designers",
    "Pest Control Specialists",
    "Soil Scientists",
    "Botanists",
    "Landscape Architects",
    "Garden Center Managers",
  ],

  "Culinary Trades": [
    "Chefs",
    "Cooks",
    "Bakers",
    "Pastry Chefs",
    "Butchers",
    "Fishmongers",
    "Food Stylists",
    "Caterers",
    "Sommeliers",
    "Baristas",
    "Bartenders",
  ],

  "Personal and Beauty Trades": [
    "Hairdressers",
    "Barbers",
    "Nail Technicians",
    "Estheticians",
    "Makeup Artists",
    "Massage Therapists",
    "Tattoo Artists",
    "Personal Trainers",
  ],

  "Fabrication and Manufacturing Trades": [
    "Machinists",
    "Welders",
    "Fabricators",
    "Tool and Die Makers",
    "Sheet Metal Workers",
    "Assemblers",
    "Quality Control Inspectors",
    "Industrial Mechanics (Millwrights)",
    "CNC Operators",
    "Electrical Technicians",
    "Industrial Electricians",
    "Painters and Coaters",
    "Metalworkers",
    "Plastic Process Technicians",
    "Foundry Workers",
    "Patternmakers",
    "Injection Moulding Operators",
    "Solderers",
  ],

  "Technology and Communication Trades": [
    "Telecommunications Installer",
    "Computer Network Technician",
    "Audio and Video Technician",
  ],

  "Transportation and Logistics Trades": [
    "Truck Drivers",
    "Train Conductors",
    "Heavy Equipment Operators",
    "Freight Handlers",
    "Warehouse Workers",
    "Forklift Operators",
    "Delivery Drivers",
    "Aircraft Maintenance Technicians",
    "Ship Captains and Crew",
    "Bus Drivers",
    "Taxi and Ride-Hailing Drivers",
    "Dispatchers",
    "Cargo and Freight Agents",
    "Port Workers",
    "Marine Mechanics",
    "Aviation Electricians",
    "Rail Track Maintainers",
    "Traffic Technicians",
  ],

  "Maritime Trades": [
    "Shipbuilder",
    "Ship Captains",
    "Able Seamen",
    "Marine Electricians",
    "Shipfitters",
    "Boat Builders",
    "Marine Mechanics",
    "Cargo Handlers",
    "Deckhands",
    "Fisheries Workers",
    "Underwater Welders",
    "Marine Surveyors",
    "Port Workers",
    "Dredge Operators",
    "Commercial Divers",
  ],

  "Mining Trades": [
    "Diesel Mechanics",
    "Electricians",
    "Heavy Equipment Operators",
    "Boilermakers",
    "Drill Operators",
    "Welders",
    "Mine Safety Officers",
    "Pipefitters",
    "Millwrights",
    "Instrumentation Technicians",
    "Belt Splicers",
    "Underground Miners",
    "Mineral Processing Operators",
    "Surveyors",
    "Blasters",
  ],

  "Smash Repair Trades": [
    "Panel Beaters",
    "Spray Painters",
    "Auto Body Technicians",
    "Vehicle Refinishers",
    "Auto Electricians",
    "Windshield Repair Technicians",
    "Detailers",
    "Paintless Dent Repair Technicians",
    "Frame and Alignment Specialists",
    "Upholstery Repair Technicians",
    "Automotive Air Conditioning Technicians",
    "Estimators",
    "Parts Managers",
    "Body Shop Managers",
  ],

  "Fashion Trades": [
    "Fashion Designers",
    "Tailors and Dressmakers",
    "Patternmakers",
    "Textile Designers",
    "Fashion Illustrators",
    "Seamstresses",
    "Fashion Merchandisers",
    "Fabric Buyers",
    "Apparel Production Managers",
    "Fashion Stylists",
    "Costume Designers",
    "Milliners (Hat Makers)",
    "Footwear Designers",
    "Fashion Photographers",
    "Jewellery Designers",
    "Leather Workers",
    "Fashion Public Relations Specialists",
    "Fashion Show Coordinators",
    "Garment Technologists",
  ],
};

const options1 = [
  {
    id: 1,
    text: "I heard great things about the platform from colleagues in my industry.",
  },
  {
    id: 2,
    text: "I'm looking for new opportunities to grow and develop my skills.",
  },
  {
    id: 3,
    text: "I'm interested in connecting with other professionals in my field.",
  },
  {
    id: 4,
    text: "I want to learn more about the latest trends and developments in my industry.",
  },
  {
    id: 5,
    text: "I'm looking for a platform that can help me achieve my career goals.",
  },
];

const options2 = [
  {
    id: 6,
    text: "To improve my skills and knowledge in my industry.",
  },
  {
    id: 7,
    text: "To connect with other professionals in my field.",
  },
  {
    id: 8,
    text: "To find job opportunities or advance my career.",
  },
  {
    id: 9,
    text: "To gain a competitive edge in my industry.",
  },
];

const options3 = [
  {
    id: 10,
    text: "Yes, I'm interested in learning new skills or expanding my knowledge.",
  },
  {
    id: 11,
    text: "No, I'm already skilled in my profession and just looking for opportunities to network and connect with others.",
  },
];

const options4 = [
  {
    id: 12,
    text: "Yes, I have specific career goals that I'm working towards.",
  },
  {
    id: 13,
    text: "No, I'm still exploring my options and trying to figure out my career path.",
  },
];
const options5 = [
  {
    id: 14,
    text: "Yes, networking is important to me and I'm interested in connecting with others in my field.",
  },
  {
    id: 15,
    text: "No, networking is not a priority for me at this time.",
  },
];

const idLabelData = ["Apperentice", "School Student", "Labourer", "Tradesman", "Business Owner", "Industry Content Creator", "Non for Profit Agency", "Sponsor"];

const workStatusData = ["Full-Time", "Part-Time", "Casual", "Self-Employed", "Not Employed"];

const industryData = [
  "Agriculture", 
  "Air Conditioning & Refrigeration", 
  "Appliance Repair",
  "Automotive",
  "Aviation", 
  "Beauty & Cosmetology", 
  "Carpentry & Construction",
  "Cleaning & Janitorial", 
  "Communications & Networking", 
  "Culinary Arts", 
  "Dental & Medical", 
  "Electrical", 
  "Engineering",
  "Environmental Services",
  "Event Planning & Management",
  "Fashion & Apparel",
  "Finance & Accounting",
  "Fitness & Sports",
  "Graphic Design & Printing",
  "HVAC",
  "Interior Design",
  "Landscaping & Gardening",
  "Law Enforcement & Security",
  "Machining & Fabrication",
  "Manufacturing & Production",
  "Massage Therapy",
  "Mechanical",
  "Metalworking & Welding",
  "Mining & Extraction",
  "Music & Audio Production",
  "Painting & Decorating",
  "Pest Control",
  "Photography & Videography",
  "Plumbing",
  "Real estate & Property Management",
  "Renewable Energy",
  "Roofing & Waterproofing",
  "Sign Making",
  "Smash Repair",
  "Telecommunications",
  "Transportation & Logistics",
  "Upholstery and Sewing"
];

export { tradesData, options1, options2, options3, options4, options5, idLabelData, workStatusData, industryData };
