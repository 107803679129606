import React from "react";

import { useStateContext } from '../../ContextProvider/Context';

const G2Button1 = ({ widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="block m-2 rounded-sm bg-[#cfcfcf] rounded-[8px] px-3.5 py-2.5 text-center text-sm font-semibold text-[#000000] shadow-sm hover:bg-[#afafaf] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {text}
    </button>
  );
}

export default G2Button1;