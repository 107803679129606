import React from "react";

import { useStateContext } from '../../ContextProvider/Context';

const G2Button11 = ({ text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      className="bg-[#032c47] text-white hover:bg-[#0e3d5c] rounded-md px-3 py-2 text-center font-semibold"
    >
      {text}
    </button>
  );
}

export default G2Button11;

