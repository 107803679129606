import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Text, Image, Circle, useToast } from "@chakra-ui/react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, deleteToken, verifyToken } from "../../utils";
import { getSchedule } from "../../Store/Actions/dashboardAction";
import g2Toast from "../../Custom/g2Toast";

import avatar1 from "../../assets/dashboard/avatar1.png";
import avatar2 from "../../assets/dashboard/avatar2.png";
import avatar3 from "../../assets/dashboard/avatar3.png";
import avatar4 from "../../assets/dashboard/avatar4.png";
import avatar5 from "../../assets/dashboard/avatar5.png";

import spread1 from "../../assets/dashboard/spread1.png";
import spread2 from "../../assets/dashboard/spread2.png";

const users = [
  {
    img: avatar1,
    title: "Jon Arthur",
    subtitle: "12 min ago",
    action: "gray.300",
  },
  {
    img: avatar2,
    title: "Somi Thakar",
    subtitle: "1 hour ago",
    action: "green.300",
  },
  {
    img: avatar3,
    title: "Krishiv Mehta",
    subtitle: "17 min ago",
    action: "yellow.300",
  },
  {
    img: avatar4,
    title: "Ram Jani",
    subtitle: "31 min ago",
    action: "green.300",
  },
  {
    img: avatar5,
    title: "Priyanka Seth",
    subtitle: "2 days ago",
    action: "yellow.300",
  },
];

const customWeekdays = ["SU", "MO", "TU", "WE", "TH", "FR", "ST"];

const RightSideBar = () => {
  const { setMode, G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const scheduleData = useSelector((state) => state.Dashboard.scheduleData);

  const [messageShow, setMessageShow] = useState(false);

  const [date, setDate] = useState("");

  useEffect(() => {
    if (verifyToken()) {
      dispatch(getSchedule(toast));
    }

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setDate(formattedDate);
  }, []);

  const onClickMessageShow = () => {
    // setMessageShow(!messageShow);
  };

  const handleDateChange = (newDate) => {
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setDate(formattedDate);
  };

  const convertTime = (time) => {
    const hour = parseInt(time.split(":")[0], 10);
    const minute = parseInt(time.split(":")[1], 10);

    if (hour < 12) {
      if (hour == 0) {
        return hour + 12 + ":" + minute + " am";
      } else {
        return hour + ":" + minute + " am";
      }
    } else {
      if (hour == 12) {
        return hour + ":" + minute + " pm";
      } else {
        return hour - 12 + ":" + minute + " pm";
      }
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      className="pb-5"
      style={{ background: `${G2_color_1}` }}
    >
      <Box
        width="100%"
        h="0px"
        className="mb-[10px]"
        style={{ borderBottom: "1px solid lightgray" }}
      ></Box>
      <Box
        className="flex-box justify-between pr-1.5"
        style={{ color: `${G2_color_3}` }}
      >
        Select a date to view the event.
        {/* <Box className="text-left">
          <Text className="font-semibold" fontSize="16px">
            Recent Message
          </Text>
        </Box>
        <Box className="text-right">
          <Image
            className="spread"
            src={G2_theme_mode == "light" ? spread1 : spread2}
            alt="spread"
            onClick={onClickMessageShow}
          />
        </Box> */}
      </Box>
      {messageShow ? (
        <Box style={{ color: `${G2_color_3}` }} className="pr-2">
          <Box
            width="100%"
            h="0px"
            className="mt-[10px] mb-[10px]"
            style={{ borderBottom: "1px solid lightgray" }}
          ></Box>
          {users.map((element, index) => (
            <Box key={index}>
              <Box className="flex-box justify-between">
                <Box className="flex-box text-left">
                  <Box h="100%" className="centering-h">
                    <Image
                      className="avatar-border"
                      borderRadius="full"
                      boxSize="50px"
                      src={element.img}
                      alt="avatar"
                    />
                  </Box>
                  <Box h="100%" className="flex-box centering-h pl-5">
                    <Text w="100%" fontSize="14px">
                      {element.title}
                    </Text>
                    <Text w="100%" fontSize="12px">
                      {element.subtitle}
                    </Text>
                  </Box>
                </Box>
                <Box className="centering-h text-right">
                  <Circle boxSize={2.5} bg={element.action} />
                </Box>
              </Box>
              <Box w="100%" h="10px"></Box>
            </Box>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Box w="100%" h="10px"></Box>
      <Box
        width="100%"
        h="0px"
        className="mb-[20px]"
        style={{ borderBottom: "1px solid lightgray" }}
      ></Box>
      <Calendar
        onChange={handleDateChange}
        value={date}
        calendarType="gregory"
        formatShortWeekday={(locale, date) => customWeekdays[date.getDay()]}
      />
      {scheduleData
        ?.filter((item) => item.date == date)
        ?.map((val, i) => (
          <Box key={i} style={{ color: `${G2_color_3}` }}>
            <Box w="100%" h="10px"></Box>
            <Box className="flex-box centering-h">
              <Box w="30%">
                <Text fontSize="14px">{convertTime(val.time)}</Text>
              </Box>
              <Box w="10%">
                <Circle
                  boxSize={1.5}
                  bg={
                    i % 5 == 0
                      ? "pink.400"
                      : i % 5 == 1
                        ? "blue.400"
                        : i % 2 == 2
                          ? "yellow.400"
                          : i % 5 == 3
                            ? "red.400"
                            : "green.400"
                  }
                />
              </Box>
              <Box w="60%">
                <Text fontSize="14px">{val.name}</Text>
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default RightSideBar;
