import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Textarea,
  Button,
  IconButton,
  Divider,
  useToast,
} from "@chakra-ui/react";

import { StarIcon, SmallCloseIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";
import { addReview } from "../../Store/Actions/lifeAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button20 from "../Common/G2Button20";

const ReviewModal = (props) => {
  const { isOpen, setIsOpen } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toast = useToast();

  const content = useSelector((state) => state.Life.content);

  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleRatingClick = (mark) => {
    setRating(mark);
  };

  const handleSaveClick = () => {
    if (!isEmptyValue(reviewText)) {
      dispatch(addReview(toast, content._id, rating, reviewText));
      setRating(null);
      setReviewText("");
      handleCloseModal();
    } else {
      g2Toast({ toast, status: "error", title: "Input review text!" });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box
                w="100%"
                className="flex-box justify-between centering-h mb-[10px]"
              >
                <Box className="flex-box text-left">
                  <Text
                    className="block text-lg font-semibold"
                    style={{ color: `${G2_color_3}` }}
                  >
                    Review content
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon
                    className="exit-icon"
                    style={{ color: `${G2_color_3}` }}
                    onClick={handleCloseModal}
                  />
                </Box>
              </Box>
              <Divider
                borderColor={G2_theme_mode == "light" ? "black" : "white"}
                orientation="horizontal"
                className="mb-[10px]"
              />
              <Box w="100%">
                <Box className="mt-5">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <IconButton
                      key={star}
                      icon={
                        <StarIcon
                          color={star <= rating ? "yellow.300" : "gray.300"}
                        />
                      }
                      onClick={() => handleRatingClick(star)}
                      size="lg"
                      variant="unstyled"
                      isDisabled={!isOpen}
                    />
                  ))}
                </Box>
                <Textarea
                  w="100%"
                  className="mt-5"
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                  }}
                  placeholder="Write a review"
                  size="md"
                  rows={6}
                  value={reviewText}
                  onChange={(event) => setReviewText(event.target.value)}
                />
              </Box>
              <Box w="100%" className="mt-5">
                <G2Button20
                  widthVal="100%"
                  heightVal="40px"
                  text="Save"
                  onClick={handleSaveClick}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReviewModal;
