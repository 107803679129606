function g2Toast({toast, status, title }) {
    const showToast = () => {
        toast({
            title,
            status,
            position: "top-right",
            duration: 2000,
            isClosable: true,
        });
    };

    return showToast();
}

export default g2Toast;