import React from "react";

import { useStateContext } from "../../ContextProvider/Context";

const G2Button6 = ({ ref, widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <button
      ref={ref}
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="centering block p-2 rounded-sm bg-[var(--orange)] rounded-[8px] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#2a956e] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {text}
    </button>
  );
};

export default G2Button6;
