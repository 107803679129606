import { useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";

import g2Toast from "./Custom/g2Toast";

export const setToken = (userToken) => {
  // sessionStorage.setItem("token", JSON.stringify(userToken));
  localStorage.setItem("token", userToken);
};

export const getToken = () => {
  // const tokenString = sessionStorage.getItem("token");
  // const userToken = JSON.parse(tokenString);
  // return userToken;
  const token = localStorage.getItem("token");
  return token;
};

export const deleteToken = () => {
  // sessionStorage.removeItem("token");
  localStorage.removeItem("token");
};

export const verifyToken = () => {
  // const toast = useToast();

  const token = localStorage.getItem("token");

  if (token) {
    try {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp < Date.now() / 1000) {
        // g2Toast({ toast, status: "error", title: "Token has expired!" });
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  } else {
    // g2Toast({ toast, status: "error", title: "Token not found!" });
    return false;
  }
};
