import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
	Box,
	Text,
	Image,
	Divider,
	useToast,
	Input,
	Checkbox,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	HStack,
} from "@chakra-ui/react";

import { EditIcon, DeleteIcon, AddIcon, ChevronDownIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import {
	setSelectedUnit,
	setSelectedCard,
	deleteCard,
	updateUnitIdWithCardIndex,
	moveUnitToContent,
} from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import G2Button19 from "../Common/G2Button19";
import G2Button20 from "../Common/G2Button20";
import G2Button22 from "../Common/G2Button22";

import doc from "../../assets/create/doc.png";

const UnitComponent = ({ boxW2, element, selectedUnits, setSelectedUnits, handleUnitSelect, ...rest }) => {

	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const toast = useToast();

	const contentList = useSelector((state) => state.Create.contentList);
	const content = useSelector((state) => state.Create.content);
	const cardList = useSelector((state) => state.Create.cardList);
	const unitList = useSelector((state) => state.Create.unitList);

	const [isCardDopen, setIsCardDopen] = useState(false);

	const [selectedAll, setSelectedAll] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);

	const [currentCard, setCurrentCard] = useState({});

	const filteredContentList = useMemo(() => {
		return contentList?.filter(r => {
			return r._id != element.contentId
		});
	}, [contentList, element]);

	useEffect(() => {
		setSelectedCards([]);
	}, [element]);

	const handleSelectAll = (e) => {
		e.stopPropagation();

		setSelectedUnits([]);

		setSelectedAll(!selectedAll);

		if (selectedAll == false) {
			const temp = [];
			cardList
				?.filter((val) => val.unitId == element._id)
				?.map((val) => {
					temp.push(val._id);
				});
			setSelectedCards(temp);
		} else {
			setSelectedCards([]);
		}
	};

	const handleCardSelect = (val, id) => {
		setCurrentCard(val);

		setSelectedUnits([]);

		if (selectedCards.includes(id)) {
			setSelectedAll(false);

			setSelectedCards(selectedCards.filter((val) => val != id));
		} else {
			if (
				cardList?.filter((val) => val.unitId == element._id)?.length ==
				selectedCards?.length + 1
			) {
				setSelectedAll(true);
			}

			setSelectedCards([...selectedCards, id]);
		}
	};

	const openCardDModal = () => {
		setIsCardDopen(true);
	};

	const closeCardDModal = () => {
		setIsCardDopen(false);
	};

	const onClickAddCard = () => {
		dispatch(setSelectedUnit(toast, navigate, element));
	};

	const handleEditCard = () => {
		dispatch(setSelectedUnit(toast, navigate, element));
		dispatch(setSelectedCard(navigate, currentCard));
	};

	const handleDeleteCard = () => {
		if (!isEmptyValue(selectedCards[0])) {
			dispatch(deleteCard(toast, element._id, selectedCards));
			setSelectedCards([]);
			setIsCardDopen(false);
		} else {
			g2Toast({ toast, status: "error", title: "Select card!" });
		}
	};

	const moveCardToUnit = (cardId, unitId) => {
		setSelectedCards([]);
		dispatch(updateUnitIdWithCardIndex(toast, cardId, unitId, 0, content._id));
	}

	const moveUnitToContentCallback = (contentId, unitId) => {
		setSelectedCards([]);
		dispatch(moveUnitToContent(toast, contentId, unitId));
	}

	return (
		<Box className="relative px-[10px] mb-[20px]">
			<Box {...rest} w="100%" py="5px" bg={selectedUnits?.includes(element._id) ? G2_theme_mode == "light" ? "#f99d8f" : "#a15245" : G2_color_2} text={G2_color_3} className={`flex-box centering-h rounded-tl-[2.5px] rounded-tr-[2.5px] border ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"} border-1`} onClick={(e) => { e.preventDefault(); handleUnitSelect(element._id) }}>
				<HStack w="100%" justifyContent="space-between" p="10px">
					<HStack>
						<Image display="inline" boxSize="35px" src={doc} alt="avatar" />
						<Text fontSize="16px" className="font-semibold">
							{element.title}
						</Text>
					</HStack>
					<HStack>
						<Menu>
							<MenuButton size="xs" as={Button} rightIcon={<ChevronDownIcon />}>Move To</MenuButton>
							<MenuList>
								{filteredContentList?.map((r, i) => <MenuItem key={i} onClick={() => { moveUnitToContentCallback(r._id, element._id) }}>{r.title}</MenuItem>)}
							</MenuList>
							<Checkbox size="md" borderColor="blue.500" colorScheme="red" isChecked={selectedUnits?.includes(element._id)} />
						</Menu>
					</HStack>
				</HStack>



			</Box>

			<Box px="10px" pt="20px" bg={G2_color_1} className={`rounded-bl-[2.5px] rounded-br-[2.5px] border-b border-r border-l ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"} border-1`}>
				<Droppable droppableId={element._id + ""}>
					{(provided) => (
						<Box w="100%" ref={provided.innerRef} {...provided.droppableProps}>
							{element ? (
								<Box ref={provided.innerRef}>
									{cardList?.filter((val) => val.unitId == element._id)?.map((val, i) => (
										<Draggable key={val._id} draggableId={val._id + ""} index={i}>
											{(provided) => (
												<Box key={i} mx="10px" mb="20px" p="5px 10px" borderRadius="2.5px" bg={selectedCards?.includes(val._id) ? G2_theme_mode == "light" ? "#f99d8f" : "#a15245" : G2_color_2} className={`normal-hover rounded border ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"} border-1`} onClick={() => handleCardSelect(val, val._id)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													{val.cardName}
												</Box>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Box>
							) : (
								<></>
							)}
							{!isEmptyValue(selectedCards[0]) ? (
								<Box className="flex-box justify-between px-[10px] mb-5">
									<Box className="flex-box text-left">
										<Checkbox size="md" className="mr-5" borderColor="blue.500" colorScheme="green" isChecked={selectedAll} onChange={handleSelectAll}>
											Select All
										</Checkbox>
										{selectedCards.length == 1 && <Menu>
											<MenuButton size="xs" as={Button} rightIcon={<ChevronDownIcon />}>Move To</MenuButton>
											<MenuList>
												{unitList?.map((r, i) => <MenuItem key={i} onClick={() => { moveCardToUnit(selectedCards[0], r._id) }}>{r.title}</MenuItem>)}
											</MenuList>
										</Menu>}
									</Box>
									<Box className="flex-box text-right">
										{selectedCards.length == 1 && <G2Button22 widthVal="75px" heightVal="25px" text="Edit" onClick={handleEditCard} />}
										<G2Button19 widthVal="75px" heightVal="25px" text="Delete" onClick={openCardDModal} />
										<G2ConfirmAlert isOpen={isCardDopen} onClose={closeCardDModal} handleOkClick={handleDeleteCard} />
									</Box>
								</Box>
							) : (
								<></>
							)}
							<Box pb="20px" px="10px">
								<G2Button20
									widthVal="100%"
									heightVal="35px"
									text="Add a card"
									onClick={onClickAddCard}
								/>
							</Box>
						</Box>
					)}
				</Droppable>
			</Box>
		</Box >
	);
};

export default UnitComponent;
