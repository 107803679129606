import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const getPassport = (toast, userHash) => {
  return (dispatch) => {
    g2AxiosT
      .get("/academy/passport/" + userHash)
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_PASSPORT", payload: res.data.passport });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting passport data:", error);
      });
  };
};

const savePassport = (toast, formData) => {
  return (dispatch) => {
    g2AxiosT
      .put("/academy/passport", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_PASSPORT", payload: res.data.passport });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding portfolio data:", error);
      });
  };
};

const getJobList = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/academy/job")
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_JOBLIST", payload: res.data.jobList });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting job list data:", error);
      });
  };
};

const getJob = (navigate, cond, val) => {
  return (dispatch) => {
    dispatch({ type: "ACADEMY_GET_JOB", payload: val });
    dispatch({ type: "ACADEMY_GET_BACKPAGE", payload: cond });
    navigate("/academy/job/view");
  };
};

const makeJob = (navigate, val) => {
  return (dispatch) => {
    dispatch({ type: "ACADEMY_GET_JOB", payload: val });
    navigate("/academy/job/update");
  };
};

const createJob = (toast, navigate, formData) => {
  return (dispatch) => {
    g2AxiosT
      .post("/academy/job", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_JOBLIST", payload: res.data.jobList });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/academy/job/edit");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding job data:", error);
      });
  };
};

const updateJob1 = (toast, navigate, id, formData) => {
  return (dispatch) => {
    g2AxiosT
      .put("/academy/job/1/" + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_JOBLIST", payload: res.data.jobList });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/academy/job/edit");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating job data:", error);
      });
  };
};

const updateJob2 = (toast, navigate, id, data) => {
  return (dispatch) => {
    g2AxiosT
      .put("/academy/job/2/" + id, { data })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_JOBLIST", payload: res.data.jobList });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/academy/job/edit");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating job data:", error);
      });
  };
};

const deleteJob = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/academy/job/" + id)
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_JOBLIST", payload: res.data.jobList });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error deleting job data:", error);
      });
  };
};

const getEventList = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/academy/event")
      .then((res) => {
        dispatch({
          type: "ACADEMY_GET_EVENTLIST",
          payload: res.data.eventList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting event list data:", error);
      });
  };
};

const getEvent = (navigate, val) => {
  return (dispatch) => {
    dispatch({ type: "ACADEMY_GET_EVENT", payload: val });
    navigate("/academy/event/view");
  };
};

const makeEvent = (navigate, val) => {
  return (dispatch) => {
    dispatch({ type: "ACADEMY_GET_EVENT", payload: val });
    navigate("/academy/event/update");
  };
};

const createEvent = (toast, navigate, formData) => {
  return (dispatch) => {
    g2AxiosT
      .post("/academy/event", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: "ACADEMY_GET_EVENTLIST",
          payload: res.data.eventList,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/academy/event");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding event data:", error);
      });
  };
};

const updateEvent1 = (toast, navigate, id, formData) => {
  return (dispatch) => {
    g2AxiosT
      .put("/academy/event/1/" + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: "ACADEMY_GET_EVEMTLIST",
          payload: res.data.eventList,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/academy/event");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating event data:", error);
      });
  };
};

const updateEvent2 = (toast, navigate, id, data) => {
  return (dispatch) => {
    g2AxiosT
      .put("/academy/event/2/" + id, { data })
      .then((res) => {
        dispatch({
          type: "ACADEMY_GET_EVENTLIST",
          payload: res.data.eventList,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/academy/event");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating event data:", error);
      });
  };
};

const deleteEvent = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/academy/event/" + id)
      .then((res) => {
        dispatch({
          type: "ACADEMY_GET_EVENTLIST",
          payload: res.data.eventList,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error deleting event data:", error);
      });
  };
};

const getUserData = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/academy")
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_USERDATA", payload: res.data.userData });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting user data:", error);
      });
  };
};

const actionEvent = (toast, eventId, accept, reject) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAcademy&method=actionEvent", { eventId, accept, reject })
      .then((res) => {
        dispatch({
          type: "ACADEMY_GET_EVENTLIST",
          payload: res.data.eventList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error deleting event data:", error);
      });
  };
};

const followUser = (toast, userHash, follow, requestMore) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAcademy&method=toggleFollow", { userHash, follow, requestMore })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_PASSPORT", payload: res.data.passport, });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error deleting event data:", error);
      });
  };
};

const actionFollowRequest = (toast, userHash, accept) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAcademy&method=actionFollowRequest", { userHash, accept })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_PASSPORT", payload: res.data.passport });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error deleting event data:", error);
      });
  };
};

const uploadExteriorResults = (toast, results) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAcademy&method=uploadExteriorResults", { results })
      .then((res) => {
        dispatch({ type: "ACADEMY_GET_PASSPORT", payload: res.data.passport });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error deleting event data:", error);
      });
  };
};





export {
  getPassport,
  savePassport,
  getJobList,
  getJob,
  makeJob,
  createJob,
  updateJob1,
  updateJob2,
  deleteJob,
  getEventList,
  getEvent,
  makeEvent,
  createEvent,
  updateEvent1,
  updateEvent2,
  deleteEvent,
  getUserData,
  actionEvent,
  followUser,
  actionFollowRequest,
  uploadExteriorResults
};
