import React from "react";
import {
  Box,
  HStack,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";

import Navbar from "../../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../../Components/Common/UniversalLeftSideBar";
import { formatContentText } from "../../helpers/formatters";
import { NavLink } from "react-router-dom";
import twitter from "../../assets/social/twitter.png";
import facebook from "../../assets/social/facebook.png";
import instagram from "../../assets/social/instagram.png";
import tiktok from "../../assets/social/tiktok.png";
import spotify from "../../assets/social/spotify.png";
import youtube from "../../assets/social/youtube.png";
import UTradieText from "../../Components/Common/UTradieText";

function CommonExternalPage({ content, title }) {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();
  //   const social = [twitter, facebook, instagram, tiktok, spotify, youtube];
  const social = [
    { src: facebook, url: "https://www.facebook.com/join.UTRADIE/" },
    { src: instagram, url: "https://www.instagram.com/join.utradie/" },
    { src: twitter, url: "https://twitter.com/utradie" },
    // { src: LinkedIn, url: "https://www.linkedin.com/company/grow2company" },
    { src: youtube, url: "https://www.youtube.com/@utradie" },
    { src: spotify, url: "https://www.reddit.com/user/UTRADIE/" },
    { src: tiktok, url: "https://www.tiktok.com/@utradie" },
  ];
  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Navbar title={title} />
      <Box w="100%" className="flex-box">
        <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>
        <Box className="universal-body-w flex">
          <Box w="100%" className="setting-box mt-[70px] mb-[50px]">
            {content.map((r, i) => {
              const title = formatContentText(r.title);
              const body = formatContentText(r.body);

              return (
                <div key={i} className="mb-[50px]">
                  <Text
                    color="var(--orange)"
                    fontSize="20px"
                    className="font-semibold"
                  >
                    {title}
                  </Text>
                  <Text
                    color={G2_color_3}
                    fontSize="16px"
                    className="mt-[10px] text-justify"
                  >
                    {body}
                  </Text>
                  {r.points != undefined && (
                    <UnorderedList>
                      {r.points.map((p, i) => {
                        const pointText = formatContentText(p);
                        return (
                          <ListItem key={i} className="text-justify">
                            {pointText}
                          </ListItem>
                        );
                      })}
                    </UnorderedList>
                  )}
                </div>
              );
            })}
          </Box>
        </Box>
        <Box w="100%" className="p-5 text-center">
          <HStack justifyContent="center">
            <Box>
              <NavLink to="/about">About</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/overview">Overview</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/pricing">Pricing</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/contact">Customer Support</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/terms">Terms &amp; Conditions</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/privacy">Privacy Policy</NavLink>
            </Box>
          </HStack>
        </Box>

        <Box w="100%" className="p-5 text-center">
          <Box className="centering-w">
            <Box className="flex">
              {/* {social.map((val, i) => (
                <Image
                  key={i}
                  src={val}
                  width="25px"
                  height="25px"
                  className="mr-5 normal-hover"
                />
              ))} */}

              {social.map((item, i) => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                >
                  <Image
                    src={item.src}
                    width="25px"
                    height="25px"
                    className="mr-5 normal-hover"
                  />
                </a>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          padding="15px"
          marginTop="15px"
          w="100%"
          justifyContent="center"
          alignContent="center"
          marginX="auto"
          textAlign="center"
          backgroundColor="var(--blue)"
          color="white"
        >
          Copyright <UTradieText isHeading={true} /> PTY LTD
        </Box>
      </Box>
    </Box>
  );
}

export default CommonExternalPage;
