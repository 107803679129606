import React, { useState, useEffect } from "react";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Button,
  Text,
  Input,
  Divider,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";

import { SmallCloseIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";

import G2Button20 from "../Common/G2Button20";

const UpdateModal = (props) => {
  const {
    isUOpen,
    closeUpdateModal,
    percent,
    comment,
    setPercent,
    setComment,
    handleUpdateGoal,
  } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isUOpen}>
        <ModalOverlay />
        <ModalContent
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box
                w="100%"
                className="flex-box justify-between centering-h mb-[10px]"
              >
                <Box className="flex-box text-left">
                  <Text
                    className="block text-lg font-semibold"
                    style={{ color: `${G2_color_3}` }}
                  >
                    Update goal
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon
                    className="exit-icon"
                    style={{ color: `${G2_color_3}` }}
                    onClick={closeUpdateModal}
                  />
                </Box>
              </Box>
              <Divider
                borderColor={G2_theme_mode == "light" ? "black" : "white"}
                orientation="horizontal"
                className="mb-[10px]"
              />
              <Box w="100%" className="flex-box">
                <Box w="100%" className="mt-5">
                  <Text
                    className="block text-sm font-semibold leading-6"
                    style={{ color: `${G2_color_3}` }}
                  >
                    How many percentage of your goal completed?
                  </Text>
                  <Box className="mt-2">
                    <InputGroup size="sm">
                      <Input
                        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                        style={{
                          background: `${G2_color_2}`,
                          color: `${G2_color_3}`,
                        }}
                        value={percent}
                        onChange={(e) => setPercent(e.target.value)}
                      />
                      <InputRightAddon color="black">%</InputRightAddon>
                    </InputGroup>
                  </Box>
                </Box>
                <Box w="100%" className="mt-5">
                  <Textarea
                    w="100%"
                    className="mt-2"
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                    placeholder="Write a comment"
                    size="md"
                    rows={6}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Box>
                <Box w="100%" className="mt-5">
                  <G2Button20
                    widthVal="100%"
                    heightVal="40px"
                    text="Update"
                    onClick={handleUpdateGoal}
                  />
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateModal;
