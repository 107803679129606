import { TimeIcon } from "@chakra-ui/icons";
import { AspectRatio, Box, Button, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatRelativeTime } from "../helpers/formatters";
import { getServerUrl } from "../helpers/location";
import { useDispatch } from "react-redux";
import { actionQuizResponse, actionTaskResponse } from "../Store/Actions/learnAction";
import { FaCheckCircle, FaCross, FaTimesCircle } from "react-icons/fa";
import QuizReview from "./Quiz/QuizReview";
import { getFileUrl } from "../helpers/general";

export default function ActivityCard({ item, groupMemberId = null }) {


    const taskRequiresReview = useMemo(() => {
        return item.cardTask != "" && item.isReviewable;
    }, [item]);

    const quizRequiresReview = useMemo(() => {
        console.log(item.quizResults);
        return item?.quizResults?.filter(r => r.rightAnswer === undefined)?.length > 0 && item.isReviewable && item.outcome.accepted == null;
    }, [item])

    const isAccepted = useMemo(() => {
        return item.tasks.filter(r => r.outcome.accepted != null).length > 0
    }, [item]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    const toggleSelection = useCallback((item) => {
        if (!taskRequiresReview) {
            return;
        }

        if (selected.includes(item.id)) {
            setSelected(selected.filter(r => r != item.id));
        } else {
            setSelected([...selected, item.id])
        }
    }, [selected, setSelected]);

    const actionEvidence = useCallback(async (accept) => {
        dispatch(actionTaskResponse(toast, item.cardId, selected, accept, groupMemberId));
        onClose();
    }, [item, selected, setSelected, onClose]);

    const actionQuiz = useCallback((accept) => {
        dispatch(actionQuizResponse(toast, item.id, accept, groupMemberId));
        onClose();
    }, []);


    useEffect(() => {
        setSelected([]);

        if (item?.quiz?.questions?.length > 0) {
            setTabIndex(0);
        } else if (item.cardTask != "") {
            setTabIndex(1);
        }

    }, [item, isOpen]);

    const getColour = (r, justSelected = false) => {
        if (justSelected) {
            if (selected.includes(r.id)) {
                return "var(--orange)";
            } else {
                return "transparent";
            }
        }

        if (r.outcome.accepted != null) {
            return "var(--green)";
        } else {
            return "var(--red)";
        }

    }

    const canBeViewed = useMemo(() => {
        return item?.quiz?.questions?.length > 0 || item.cardTask != "";
    }, [item])

    return <>
        <HStack w="100%" p="5px" justifyContent="space-between" className="flex-box my-[5px] centering-h" backgroundColor={isAccepted ? "var(--green)" : "var(--orange)"} >
            <Box>
                {
                    //JSON.stringify(item)
                }
                <Box w="30px" h="100%" display="inline" pr="5px">
                    <TimeIcon />
                </Box>
                <Text display="inline" fontSize="16px">{item.name}</Text>
                <Text display="inline">{" "}completed{" "}</Text>
                <Text display="inline">{item.cardName}</Text>
                <Text fontSize="13px">{formatRelativeTime(item.lastActive)}</Text>
            </Box>

            {canBeViewed == true && <Box><Button className="activity-view-button" onClick={onOpen}>View</Button></Box>}
        </HStack>
        <Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Reviewing {item.cardName} by {item.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs index={tabIndex} onChange={setTabIndex}>
                        <TabList>
                            <Tab isDisabled={item.quiz?.questions?.length == 0}>Quiz</Tab>
                            <Tab isDisabled={item.cardTask == ''}>Task</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <QuizReview item={item} />
                            </TabPanel>
                            <TabPanel>
                                <Text>{item.cardTask}</Text>
                                {taskRequiresReview == true && <Text pt="15px">Please select evidence to accept:</Text>}
                                <HStack w="100%" wrap="wrap">
                                    {item.tasks.map(r => <Box key={r.id} w="30%" p="5px" onClick={() => toggleSelection(r)} border={"1px solid " + getColour(r, true)}>
                                        <AspectRatio w="100%" className="centering" ratio={4 / 3}>
                                            <Image src={getFileUrl(r.attachment)} />
                                        </AspectRatio>

                                        {r.outcome.name != null && <HStack w="100%" my="5px">
                                            {r.outcome.accepted != null && <FaCheckCircle display="inline" />}
                                            {r.outcome.rejected != null && <FaTimesCircle display="inline" />}
                                            <Text display="inline" fontSize={"11px"} color={getColour(r)}>{r.outcome.name}</Text>
                                        </HStack>
                                        }
                                    </Box>)}
                                </HStack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    {
                        //JSON.stringify(item)
                    }

                </ModalBody>
                <ModalFooter>
                    {
                        //JSON.stringify(quizRequiresReview)
                    }
                    {quizRequiresReview == 1 && tabIndex == 0 && <>
                        <Button mx="5px" onClick={() => actionQuiz(1)}>Accept</Button>
                        <Button mx="5px" onClick={() => actionQuiz(0)}>Reject</Button>
                    </>}
                    {selected.length > 0 && tabIndex == 1 && taskRequiresReview == 1 && <>
                        <Button mx="5px" onClick={() => actionEvidence(1)}>Accept</Button>
                        <Button mx="5px" onClick={() => actionEvidence(0)}>Reject</Button>
                    </>}
                    <Button mx="5px" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    </>
}