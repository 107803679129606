import React from "react";
import CommonExternalPage from "./CommonExternalPage";

const content = [
  {
    "title": "Overview",
    "body": <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http%3A%2F%2Fwww.utradie.com.au%2Fapi%2Foverview.pptx' width="1024" height="768" frameborder='0'></iframe>
  }
];

const OverviewPage = () => {
  return <CommonExternalPage title="Overview" content={content} />
};

export default OverviewPage;
