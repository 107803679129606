import React from "react";
import { EditIcon } from '@chakra-ui/icons';

import { useStateContext } from '../../ContextProvider/Context';

const G2Button3 = ({ widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="btn-small rounded-sm bg-[var(--orange)] lineHeightrounded-[8px] text-center text-sm font-semibold text-white shadow-sm hover:bg-[#2a956e] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      <EditIcon className="mr-1" />{text}
    </button>
  );
}

export default G2Button3;