import React from "react";
import { AddIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";

const G2Button23 = ({ widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="bg-[#334F72] mt-[10px] text-white text-sm mr-2 hover:bg-[#233b59] rounded-sm flex justify-center items-center text-center font-semibold"
    >
      <AddIcon className="mr-2" />
      {text}
    </button>
  );
};

export default G2Button23;
