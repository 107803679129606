import { Box, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { useStateContext } from "../ContextProvider/Context";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../Store/Actions/authAction";
import { useDispatch } from "react-redux";

function VerifyPage() {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const { token } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(verifyEmail(toast, navigate, token));
    }, []);

    return <Box
        minH="100vh"
        style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
        <Navbar title="Verify Email" />
        <Box w="100%" className="flex-box">
            <Box className="universal-body-w flex-box">
                <Box w="100%" className="setting-box mt-[70px] mb-[30px]">
                    Email Address Verified.
                </Box>
            </Box>
        </Box>
    </Box>

}

export default VerifyPage;