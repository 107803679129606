import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Image, Text, IconButton, MenuIcon, ListIcon } from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AttachmentIcon,
  CopyIcon,
} from "@chakra-ui/icons";

import "../App.css";

import { useStateContext } from "../ContextProvider/Context";
import { getToken, verifyToken } from "../utils";

import G2Button10 from "../Components/Common/G2Button10";

import avatar from "../assets/dashboard/avatar.png";
import menu1 from "../assets/dashboard/menu1.png";
import menu2 from "../assets/dashboard/menu2.png";
import notification1 from "../assets/dashboard/notification1.png";
import notification2 from "../assets/dashboard/notification2.png";

import Navbar from "../Components/Navbar/Navbar";
// import LeftSideBar from "../Components/Dashboard/LeftSideBar";
import MainDashboard1 from "../Components/Dashboard/MainDashboard1";
import MainDashboard2 from "../Components/Dashboard/MainDashboard2";
import MainDashboard3 from "../Components/Dashboard/MainDashboard3";
import MainDashboard4 from "../Components/Dashboard/MainDashboard4";
import RightSideBar from "../Components/Dashboard/RightSideBar";

import lock from "../assets/dashboard/lock.png";
import Chat from "../Components/Chat/Chat";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";

const DashboardPage = () => {
  const { setMode, G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);

  const today = useSelector((state) => state.Auth.today);
  const type = useSelector((state) => state.Auth.type);

  const [screenSize, setScreenSize] = useState(1366);
  const [leftBarShow, setLeftBarShow] = useState(true);
  const [menuShow, setMenuShow] = useState(true);
  const [menuHide, setMenuHide] = useState(false);
  const [mainHide, setMainHide] = useState(false);
  const [rightBarShow, setRightBarShow] = useState(true);
  const [boxW1, setBoxW1] = useState("50%");
  const [boxW2, setBoxW2] = useState("50%");
  const [boxW3, setBoxW3] = useState("50%");

  const [boxWA, setBoxWA] = useState("33.3%");
  const [boxWB, setBoxWB] = useState("50%");
  const [boxWC, setBoxWC] = useState("33.3%");

  const [currentPage, setCurrentPage] = useState(0);

  const handleResize = () => {
    setScreenSize(window.innerWidth);

    if (window.innerWidth >= 1366) {
      setMenuShow(true);
      setRightBarShow(true);

      setBoxW1("50%");
      setBoxW2("50%");
      setBoxW3("50%");

      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }
    if (window.innerWidth < 1366 && window.innerWidth >= 1024) {
      setMenuShow(true);
      setRightBarShow(true);

      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");

      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }
    if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      setMenuShow(true);
      setRightBarShow(false);

      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");

      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }
    if (window.innerWidth < 768) {
      setMenuShow(false);
      setRightBarShow(false);

      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("100%");

      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }

    setMenuHide(false);
    setMainHide(false);
  };

  const token = getToken();

  useEffect(() => {
    if (!verifyToken()) {
      navigate("/login");
    }

    const startDate = new Date(today);
    const endDate = new Date(user.expire);

    if (startDate > endDate) {
      navigate("/billing");
    }

    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const menuClick = () => {
    if (screenSize >= 500) {
      setMenuShow(!menuShow);
    } else {
      setMenuShow(false);
    }

    if (screenSize >= 1366) {
      setBoxW1("50%");
      setBoxW2("50%");
      setBoxW3("50%");
    }

    if (
      screenSize >= 1024 &&
      screenSize < 1366 &&
      menuShow == true &&
      rightBarShow == false
    ) {
      setBoxW1("50%");
      setBoxW2("50%");
      setBoxW3("50%");
    }
    if (screenSize >= 1024 && screenSize < 1366 && menuShow == false) {
      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");
    }

    if (screenSize >= 768 && screenSize < 1024 && rightBarShow == false) {
      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");
    }
    if (
      screenSize >= 768 &&
      screenSize < 1024 &&
      menuShow == false &&
      rightBarShow == true
    ) {
      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");
    }

    if (screenSize >= 1366) {
      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }

    if (
      (screenSize >= 1024 && screenSize < 1366 && rightBarShow == false) ||
      (screenSize >= 1024 && screenSize < 1366 && menuShow == true)
    ) {
      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }
    if (
      screenSize >= 1024 &&
      screenSize < 1366 &&
      rightBarShow == true &&
      menuShow == false
    ) {
      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }

    if (
      screenSize >= 768 &&
      screenSize < 1024 &&
      rightBarShow == false &&
      menuShow == true
    ) {
      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }
    if (screenSize >= 768 && screenSize < 1024 && menuShow == false) {
      setRightBarShow(false);

      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }
  };

  const rightBarClick = () => {
    setRightBarShow(!rightBarShow);

    if (screenSize >= 1366) {
      setBoxW1("50%");
      setBoxW2("50%");
      setBoxW3("50%");
    }

    if (
      screenSize >= 1024 &&
      screenSize < 1366 &&
      menuShow == false &&
      rightBarShow == true
    ) {
      setBoxW1("50%");
      setBoxW2("50%");
      setBoxW3("50%");
    }
    if (screenSize >= 1024 && screenSize < 1366 && rightBarShow == false) {
      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");
    }

    if (screenSize >= 768 && screenSize < 1024 && rightBarShow == true) {
      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("50%");
    }
    if (screenSize >= 768 && screenSize < 1024 && rightBarShow == false) {
      setBoxW1("100%");
      setBoxW2("100%");
      setBoxW3("100%");
    }

    if (screenSize >= 1366) {
      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }

    if (
      (screenSize >= 1024 && screenSize < 1366 && rightBarShow == true) ||
      (screenSize >= 1024 && screenSize < 1366 && menuShow == false)
    ) {
      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }
    if (
      screenSize >= 1024 &&
      screenSize < 1366 &&
      rightBarShow == false &&
      menuShow == true
    ) {
      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }

    if (
      screenSize >= 768 &&
      screenSize < 1024 &&
      rightBarShow == true &&
      menuShow == false
    ) {
      setBoxWA("33.3%");
      setBoxWB("50%");
      setBoxWC("33.3%");
    }
    if (screenSize >= 768 && screenSize < 1024 && rightBarShow == false) {
      setMenuShow(false);

      setBoxWA("100%");
      setBoxWB("100%");
      setBoxWC("100%");
    }

    if (screenSize < 768 && rightBarShow == false) {
      if (screenSize >= 768) {
        setMenuHide(false);
        setMainHide(false);
      } else {
        setMenuHide(true);
        setMainHide(true);
      }
    } else {
      setMenuHide(false);
      setMainHide(false);
    }
  };

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const pageTitles = {
    "My Dashboard": <MainDashboard1 boxW1={boxW1} boxW2={boxW2} boxW3={boxW3} />,
    //"Creator Dashboard": <MainDashboard2 boxWA={boxWA} boxWB={boxWB} boxWC={boxWC} />,
    //"Mentor Dashboard": <MainDashboard3 />,
    //"Sponsorship Dashboard": <MainDashboard4 boxWA={boxWA} boxWB={boxWB} boxWC={boxWC} />
  };


  return (
    <Box>
      <Navbar title="Dashboard" />
      <Box className="flex-box" style={{ background: `${G2_color_1}` }}>
        <Box className={`flex-box ${menuHide ? "dash-leftBar-w0" : leftBarShow ? menuShow ? "dash-leftBar-w1" : "dash-leftBar-w2" : "dash-leftBar-w0"}`} style={{ background: `${G2_color_1}` }}>
          <UniversalLeftSideBar showText={true} menuShow={menuShow} screenSize={screenSize} />
        </Box>
        <Box minH="100vh" className={`flex-box ${mainHide ? "dash-main-w0" : !menuShow && !rightBarShow ? "dash-main-w1" : menuShow && !rightBarShow ? "dash-main-w2" : !menuShow && rightBarShow ? "dash-main-w3" : "dash-main-w4"}`} style={{ background: `${G2_color_1}` }}>
          <Box className={`centering-h justify-between ${rightBarShow ? "dash-main-header1-new" : "dash-main-header2-new"}`}>
            <Box w="100%" h="100%" className="flex centering-h">
              {currentPage == 0 ? (
                <Box w="30px" h="30px" className="rounded-md bg-[var(--orange)] mr-[10px]" />
              ) : (
                <G2Button10 widthVal="30px" heightVal="30px" text={<ArrowLeftIcon className="pl-1" />} onClick={handlePreviousClick} />
              )}
              <Text fontSize="20px" className="font-semibold mr-[10px] text-[var(--orange)]">{Object.keys(pageTitles)[currentPage]}
              </Text>
              {currentPage == Object.keys(pageTitles).length - 1 ? (
                <Box w="30px" h="30px" className="rounded-md bg-[var(--orange)]" />
              ) : (
                <G2Button10 widthVal="30px" heightVal="30px" text={<ArrowRightIcon className="pl-1" />} onClick={handleNextClick} />
              )}
            </Box>
          </Box>
          <Box className="dash-main-body-new">
            {pageTitles[Object.keys(pageTitles)[currentPage]]}
          </Box>
        </Box>

        <Box
          className={`${screenSize < 768 && rightBarShow
            ? "dash-rightBar-w0"
            : rightBarShow
              ? "dash-rightBar-w1"
              : "dash-rightBar-w2"
            }`}
          style={{ background: `${G2_color_1}` }}
        >
          <Box className="dash-rightBar-header-new centering-h justify-between">
            <Box className="text-left font-semibold centering- h">
              <Text w="100%" fontSize="16px" style={{ color: `${G2_color_3}` }}>
                Hi, {user?.name}{" "}
                !
              </Text>
              {/* <Text w="100%" fontSize="12px" style={{ color: `${G2_color_3}` }}>
                Good morning
              </Text> */}
            </Box>
            <Box className="text-right">
              <Image
                className="notification-icon"
                src={G2_theme_mode == "light" ? notification1 : notification2}
                alt="notification"
              />
            </Box>
          </Box>
          <Box className="dash-rightBar-body-new">
            <RightSideBar />
          </Box>
        </Box>

        {/*
        <Box className="toogleBtn">
          <IconButton
            isRound={true}
            bg="var(--orange)"
            color="white"
            aria-label="Done"
            fontSize="20px"
            icon={rightBarShow ? <ArrowForwardIcon /> : <ArrowBackIcon />}
            onClick={rightBarClick}
          />
        </Box>
        */}
      </Box>
      {/* <Box
          w="100%"
          className="pt-[200px] px-[30px]"
          style={{
            background: `${G2_color_1}`,
            color: `${G2_color_3}`,
            height: "calc(100vh - 80px)",
          }}
        >
          <Box w="100%" className="centering-w">
            <Image w="80px" h="80px" src={lock} />
          </Box>
          <Text
            w="100%"
            fontSize="18px"
            className="text-[var(--orange)] font-semibold text-center mt-5"
          >
            The Dashboard page is not accessible from business accounts.
          </Text>
        </Box> */}
    </Box>
  );
};
export default DashboardPage;
