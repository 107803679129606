import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Stack,
  Checkbox,
  RadioGroup,
  Radio,
  FormLabel,
  Text,
  Card,
  CardBody,
} from "@chakra-ui/react";

import { WarningTwoIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button3 from "../Common/G2Button3";
import G2FormLabel from "../Common/G2FormLabel";
import G2Label1 from "../Common/G2Label1";
import G2Label2 from "../Common/G2Label2";
import G2Label3 from "../Common/G2Label3";
import G2Label5 from "../Common/G2Label5";

import {
  options1,
  options2,
  options3,
  options4,
  options5,
} from "../../constant";
import UTradieText from "../Common/UTradieText";

const ConfirmAndFinishForm = (props) => {
  const {
    user,
    data,
    selectedOptions1,
    selectedOptions2,
    selectedOptions3,
    selectedOptions4,
    selectedOptions5,
    setActiveStep,
  } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const avairableComE = localStorage.getItem("avairableComE");

  const userInfo = useSelector((state) => state.Auth.user);

  return (
    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box width="100%" h="50px"></Box>
      <Box className={`font-bold text-[18px] text-start`}>
        Confirm your details
      </Box>

      <Box width="100%" h="50px"></Box>
      <Box>
        <Box className="flex-box justify-between centering-h">
          <Box className="flex-box text-left">
            <FormLabel as="legend">
              {userInfo?.type == "person"
                ? "Personal & Workplace Details"
                : "Workplace Details"}
            </FormLabel>
          </Box>
          <Box className="centering-h text-right">
            <G2Button3 text="Edit" onClick={() => setActiveStep(1)} />
          </Box>
        </Box>
        <Card className="mt-5" style={{ background: `${G2_color_2}` }}>
          <CardBody>
            {userInfo?.type == "person" && (
              <Box className="grid grid-cols-1 gap-x-10 sm:grid-cols-2">
                <G2Label1 className="confirm-label" label="Name" />
                <G2Label2 className="confirm-text" label={user.name} />
                <G2Label1 className="confirm-label" label="Email" />
                <G2Label2 className="confirm-text" label={user.email} />
                <G2Label1 className="confirm-label" label="Gender" />
                <G2Label2 className="confirm-text" label={data.gender} />
                <G2Label1 className="confirm-label" label="Date of birth" />
                <G2Label2 className="confirm-text" label={data.birthday} />
                <G2Label1 className="confirm-label" label="Phone" />
                {!isEmptyValue(data.phone) ? (
                  <G2Label2 className="confirm-text" label={data.phone} />
                ) : (
                  <G2Label5 className="confirm-text" />
                )}
                <G2Label1 className="confirm-label" label="Address" />
                {!isEmptyValue(data.address) ? (
                  <G2Label2 className="confirm-text" label={data.address} />
                ) : (
                  <G2Label5 className="confirm-text" />
                )}
              </Box>
            )}
            <Box className="grid grid-cols-1 gap-x-10 sm:grid-cols-2">
              <G2Label1 className="confirm-label" label="Occupation" />
              <G2Label2 className="confirm-text" label={data.occupation} />
              <G2Label1 className="confirm-label" label="ID Label" />
              <G2Label2 className="confirm-text" label={data.idLabel} />
              <G2Label1
                className="confirm-label"
                label="Company/Business name"
              />
              <G2Label2 className="confirm-text" label={data.companyName} />
              <G2Label1 className="confirm-label" label="Website URL" />
              <G2Label2 className="confirm-text" label={data.siteURL} />
              <G2Label1
                className="confirm-label"
                label="Company/Business Email"
              />
              {avairableComE == "yes" ? (
                <G2Label2 className="confirm-text" label={data.companyEmail} />
              ) : (
                <G2Label3 className="confirm-text" label={data.companyEmail} />
              )}
              <G2Label1
                className="confirm-label"
                label="Company/Business Phone"
              />
              <G2Label2 className="confirm-text" label={data.companyPhone} />
              <G2Label1
                className="confirm-label"
                label="Company/Business Address"
              />
              <G2Label2 className="confirm-text" label={data.companyAddress} />
              <G2Label1
                className="confirm-label"
                label="Years of experience in the industry"
              />
              <G2Label2 className="confirm-text" label={data.experience} />
              <G2Label1 className="confirm-label" label="Work Status" />
              <G2Label2 className="confirm-text" label={data.workStatus} />
            </Box>
          </CardBody>
        </Card>
      </Box>

      <Box width="100%" h="50px"></Box>
      <Box>
        <Box className="flex-box justify-between centering-h">
          <Box className="flex-box text-left">
            <FormLabel as="legend">Industry Details</FormLabel>
          </Box>
          <Box className="centering-h text-right">
            <G2Button3 text="Edit" onClick={() => setActiveStep(2)} />
          </Box>
        </Box>
        <Card className="mt-5" style={{ background: `${G2_color_2}` }}>
          <CardBody>
            <Box className="grid grid-cols-1 gap-x-10 sm:grid-cols-2">
              <G2Label1 className="confirm-label" label="Industry" />
              {data.industry == "Others" &&
                isEmptyValue(data.customIndustry) ? (
                <G2Label5 className="confirm-text" />
              ) : (
                <>
                  <G2Label2
                    className="confirm-text"
                    label={
                      data.industry != "Others"
                        ? data.industry
                        : data.customIndustry
                    }
                  />
                </>
              )}
            </Box>
          </CardBody>
        </Card>
      </Box>

      <Box width="100%" h="50px"></Box>
      <Box>
        <Box className="flex-box justify-between centering-h">
          <Box className="flex-box text-left">
            <FormLabel as="legend">Objective Details</FormLabel>
          </Box>
          <Box className="centering-h text-right">
            <G2Button3 text="Edit" onClick={() => setActiveStep(3)} />
          </Box>
        </Box>
        <Card className="mt-5" style={{ background: `${G2_color_2}` }}>
          <CardBody>
            <Box>
              <Text fontSize="16px" color={G2_color_3} className="mb-[5px]">
                1. What inspired you to join <UTradieText />?
              </Text>
              <Stack spacing={2}>
                {options1
                  .filter((option) => selectedOptions1.includes(option.id))
                  .map((option) => (
                    <Checkbox
                      colorScheme="green"
                      style={{ color: "green" }}
                      key={option.id}
                      isChecked={true}
                    >
                      {option.text}
                    </Checkbox>
                  ))}
              </Stack>
            </Box>
            <Box width="100%" h="20px"></Box>
            <Box>
              <Text fontSize="16px" color={G2_color_3} className="mb-[5px]">
                2. What do you hope to achieve by using <UTradieText />?
              </Text>
              <Stack spacing={2}>
                {options2
                  .filter((option) => selectedOptions2.includes(option.id))
                  .map((option) => (
                    <Checkbox
                      colorScheme="green"
                      style={{ color: "green" }}
                      key={option.id}
                      isChecked={true}
                    >
                      {option.text}
                    </Checkbox>
                  ))}
              </Stack>
            </Box>
            <Box width="100%" h="20px"></Box>
            <Box>
              <Text fontSize="16px" color={G2_color_3} className="mb-[5px]">
                3. Are you looking to develop your skills or learn something
                new?
              </Text>
              <RadioGroup
                spacing={2}
                style={{ color: "green" }}
                value={selectedOptions3.toString()}
              >
                {options3
                  .filter((option) => selectedOptions3 == option.id)
                  .map((option, i) => (
                    <Radio
                      key={i}
                      colorScheme="green"
                      width={"100%"}
                      value={option.id.toString()}
                    >
                      {option.text}
                    </Radio>
                  ))}
              </RadioGroup>
            </Box>
            <Box width="100%" h="20px"></Box>
            <Box>
              <Text fontSize="16px" color={G2_color_3} className="mb-[5px]">
                4. Do you have any specific career goals in mind?
              </Text>
              <RadioGroup
                spacing={2}
                style={{ color: "green" }}
                value={selectedOptions4.toString()}
              >
                {options4
                  .filter((option) => selectedOptions4 == option.id)
                  .map((option, i) => (
                    <Radio
                      key={i}
                      colorScheme="green"
                      color="green"
                      width={"100%"}
                      value={option.id.toString()}
                    >
                      {option.text}
                    </Radio>
                  ))}
              </RadioGroup>
            </Box>
            <Box width="100%" h="20px"></Box>
            <Box>
              <Text fontSize="16px" color={G2_color_3} className="mb-[5px]">
                5. Are you interested in networking with other professionals in
                your industry?
              </Text>
              <RadioGroup
                spacing={2}
                style={{ color: "green" }}
                value={selectedOptions5.toString()}
              >
                {options5
                  .filter((option) => selectedOptions5 == option.id)
                  .map((option, i) => (
                    <Radio
                      key={i}
                      colorScheme="green"
                      color="green"
                      width={"100%"}
                      value={option.id.toString()}
                    >
                      {option.text}
                    </Radio>
                  ))}
              </RadioGroup>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export default ConfirmAndFinishForm;
