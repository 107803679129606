import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    useBreakpointValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button,
    Text,
    Divider,
    Input,
    useToast,
    Image,
    VStack,
    HStack,
} from "@chakra-ui/react";
import { useStateContext } from '../../ContextProvider/Context';
import success from "../../assets/learn/success.png";
import fail from "../../assets/learn/fail.png";
import _ from 'lodash';
import { SmallCloseIcon } from '@chakra-ui/icons';
import G2Button20 from '../Common/G2Button20';
import WYSIWYGEditor from '../Common/WYSIWYGEditor';
import { shapes } from './data';
import { formatPercentage } from '../../helpers/formatters';

const QuizQuestionMultipleChoice = ({ question, handleNextClick, hasErrored }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    return <Box w="100%">
        <Text w="100%" fontSize="16px" className="font-semibold mt-[20px]">
            {question.question}
        </Text>
        {hasErrored && <Text color="red">Wrong, Please try again</Text>}
        {question.answers?.map((val, i) => <Box key={i} w="100%" bg={G2_color_2}
            className="flex-box normal-hover centering-h px-[20px] py-[10px] mt-[20px] rounded-[2.5px] shadow"
            onClick={() => handleNextClick({ answer: val.text, rightAnswer: question.rightAnswer })}>
            {val.text}
        </Box>)}
    </Box>
};

const QuizQuestionLong = ({ question, handleNextClick, hasErrored, isLastQuestion }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const [answer, setAnswer] = useState("");

    useEffect(() => {
        setAnswer("");
    }, [question]);

    const nextText = hasErrored ? "Wrong, Please try again" : isLastQuestion ? "Done" : "Next";

    return <VStack w="100%" h="500px">
        <Text w="100%" fontSize="16px" className="font-semibold mt-[20px]" dangerouslySetInnerHTML={{ __html: question.question }}></Text>
        {hasErrored && <Text color="red">Wrong, Please try again</Text>}
        <Box py="15px" w="100%" h="100%">
            <WYSIWYGEditor value={answer} setValue={setAnswer} className="h-[500px]" />
        </Box>
        <G2Button20 widthVal="100%" heightVal="40px" text={nextText} onClick={() => handleNextClick({ answer })} />
    </VStack>
}

const QuizQuestionFormula = ({ question, handleNextClick, hasErrored, isLastQuestion }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const [answer, setAnswer] = useState("");
    const [params, setParams] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");

    useEffect(() => {
        setAnswer("");
        let params = {};
        for (let name of Object.keys(question.params)) {
            let gen = question.params[name];
            let min = _.toInteger(gen.min ?? 1);
            let max = _.toInteger(gen.max ?? 100);
            let value = _.round(_.random(min, max, !(gen.integer ?? true)), 2);
            params[name] = value;
        }

        let names = Object.keys(params).join(', ');
        let values = Object.values(params).join(', ');

        let functionString = `return Math.round(Number(function (${names}) {return (${question.formula});}(${values}))*100)/100;`;
        try {
            setRightAnswer(Function(functionString)());
        } catch (ex) {
            console.log(ex);
        }
        setParams(params);
    }, [question]);

    const nextText = hasErrored ? "Wrong, Please try again" : isLastQuestion ? "Done" : "Next";

    return <VStack w="100%" h="500px">
        <Text w="100%" fontSize="16px" className="font-semibold mt-[20px]" dangerouslySetInnerHTML={{ __html: question.question }}></Text>
        {hasErrored && <Text color="red">Wrong, Please try again</Text>}
        <VStack py="15px" w="100%" h="100%">
            {Object.keys(params).map(r => <HStack key={r} w="100%">
                <Text fontWeight="bold">{r}</Text>
                <Text className='answer-value'>{params[r]}</Text>
            </HStack>)}
        </VStack>
        <HStack w="100%">
            <Text fontWeight="bold">Answer</Text>
            <Input type="number" w="100%" className="mt-2" placeholder="Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} />
        </HStack>
        <G2Button20 widthVal="100%" heightVal="40px" text={nextText} onClick={() => handleNextClick({ params, answer, rightAnswer })} />
    </VStack>
}

const QuizQuestionGeometry = ({ question, handleNextClick, hasErrored, isLastQuestion }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const [answer, setAnswer] = useState("");
    const [params, setParams] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");
    const [svgImage, setSVGImage] = useState("");

    useEffect(() => {
        setAnswer("");
        let params = {};
        for (let name of Object.keys(question.params)) {
            let gen = question.params[name];
            let min = _.toInteger(gen.min ?? 1);
            let max = _.toInteger(gen.max ?? 100);
            let value = _.round(_.random(min, max, !(gen.integer ?? true)), 2);
            params[name] = value;
        }

        let names = Object.keys(params).join(', ');
        let values = Object.values(params).join(', ');

        let functionString = `return Math.round(Number(function (${names}) {return (${question?.shape?.formula ?? 0});}(${values}))*100)/100;`;
        try {
            setRightAnswer(Function(functionString)() + "");
        } catch (ex) {
            console.log(ex);
        }
        setParams(params);

        const imageUrl = shapes.filter(r => r.name == question.shape.name)[0].image;
        fetch(imageUrl).then(async res => {
            let data = await res.text();
            for (let name in params) {
                let pattern = new RegExp('(?<=>)(\\s*' + name + '\\s*)(?=<\\/tspan)', 'ig');
                data = data.replace(pattern, params[name]);
            }
            setSVGImage(data);
        });

    }, [question]);

    /*
            <VStack py="15px" w="100%" h="100%">
            {Object.keys(params).map(r => <HStack key={r} w="100%">
                <Text fontWeight="bold">{r}</Text>
                <Text className='answer-value'>{params[r]}</Text>
            </HStack>)}
        </VStack>
    */

    const nextText = hasErrored ? "Wrong, Please try again" : isLastQuestion ? "Done" : "Next";

    return <VStack w="100%" h="500px">
        <Text w="100%" fontSize="16px" className="font-semibold mt-[20px]">{question?.shape?.name}</Text>
        {hasErrored && <Text color="red">Wrong, Please try again</Text>}
        {question.shape !== undefined && <HStack w="100%" p="5px" justifyContent="center">
            <Box w="100%" display="flex" justifyContent="center" dangerouslySetInnerHTML={{ __html: svgImage }} />
        </HStack>}
        <HStack w="100%">
            <Text fontWeight="bold">Answer</Text>
            <Input type="number" w="100%" className="mt-2" placeholder="Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} />
        </HStack>
        <G2Button20 widthVal="100%" heightVal="40px" text={nextText} onClick={() => handleNextClick({ params, answer, rightAnswer })} />
    </VStack>
}

const QuizCard = ({ card, isOpen, onClose, quiz, completedCallback }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const toast = useToast();
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });

    const [hasErrored, setHasErrored] = useState(false);
    const [hasFailed, setHasFailed] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setHasFailed(null);
        setCurrentIndex(0);
    }, [isOpen]);

    const currentQuestion = useMemo(() => {
        return quiz?.questions[currentIndex];
    }, [quiz, currentIndex]);

    const isLastQuestion = useMemo(() => {
        return currentIndex >= quiz?.questions?.length - 1
    }, [quiz, currentIndex]);

    const canRetryQuiz = useMemo(() => {
        return (quiz?.forceQuizRetry ?? false);
    }, [quiz]);

    const totalCount = useMemo(() => {
        return (answers ?? []).filter(r => r.rightAnswer != undefined).length;
    }, [quiz]);

    const rightCount = useMemo(() => {
        return (answers ?? []).filter((r, i) => {
            return r.rightAnswer != undefined && r.rightAnswer == r.answer;
        }).length;
    }, [quiz, answers]);

    const handleNextClick = useCallback((value) => {

        let temp = _.cloneDeep(answers);
        value.attempts = temp[currentIndex]?.attempts ?? 0;

        if ((quiz?.forceQuestionRetry ?? false) == true && value.rightAnswer != value.answer) {
            setHasErrored(true);
            value.attempts += 1;
            temp[currentIndex] = value;
            console.log(currentIndex, temp);
            setAnswers(temp);
            return;
        }

        setHasErrored(false);


        if (value != undefined) {
            temp[currentIndex] = value;
            setAnswers(temp);
        }

        if (currentIndex != quiz?.questions?.length - 1) {
            setCurrentIndex(currentIndex + 1);
            return;
        }

        let requiredPercentage = (quiz?.requiredScore / 100.0) ?? 1.0;

        let rightCount = (temp ?? []).filter((r, i) => {
            return r.rightAnswer != undefined && r.rightAnswer == r.answer;
        }).length;
        let totalCount = (temp ?? []).filter(r => r.rightAnswer != undefined).length;

        let currentPercentage = rightCount / totalCount;

        //console.log(rightCount, totalCount)


        if (currentPercentage < requiredPercentage) {
            setHasFailed(true);
        } else {
            setHasFailed(false);
        }

        completedCallback(temp, null);
    }, [answers, setAnswers, currentIndex, setCurrentIndex, hasFailed, setHasFailed]);

    const handleCompleteClick = useCallback(() => {
        completedCallback(answers, 1);
    }, [answers]);

    const handleTryAgainClick = useCallback(() => {
        setHasFailed(null);
        setCurrentIndex(0);
    }, [answers]);

    const defaultComponentType = "multipleChoice";

    const questionTypes = useMemo(() => {
        return {
            'multipleChoice': { name: "Multiple Choice", component: QuizQuestionMultipleChoice },
            'longForm': { name: "Long", component: QuizQuestionLong },
            'formula': { name: "Formula", component: QuizQuestionFormula },
            'geometry': { name: "Geometry", component: QuizQuestionGeometry },
        };
    }, []);

    const getQuestionComponent = useCallback((type) => {
        if (questionTypes[type] === undefined) {
            return questionTypes[defaultComponentType].component;
        }

        return questionTypes[type].component;
    }, [questionTypes, defaultComponentType]);

    const QuestionComponent = getQuestionComponent(currentQuestion?.type);

    if (!isOpen) {
        return <></>
    }

    return <Box w="100%">
        {hasFailed == null && <Box w="100%" className="flex-box py-5">
            <Box w="100%" className="flex-box justify-between centering-h mb-[10px]">
                <Box className="flex-box text-left">
                    <Text className="block text-lg font-semibold" style={{ color: `${G2_color_3}` }}>Question {currentIndex + 1} of {quiz?.questions?.length}</Text>
                </Box>
                <Box className="flex-box text-right">
                    <Text fontSize="16px" className="normal-hover text-[var(--orange)]" onClick={onClose}>Close</Text>
                </Box>
            </Box>
            <Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" className="mb-[10px]" />
            <QuestionComponent question={currentQuestion} hasErrored={hasErrored} isLastQuestion={isLastQuestion} handleNextClick={handleNextClick} />
        </Box>}

        {hasFailed != null && <Box w="100%" className="flex-box py-5">
            <Box w="100%" className="flex-box justify-between centering-h mb-[10px]">
                <Box className="flex-box text-left">
                </Box>
                <Box className="flex-box text-right">
                    <SmallCloseIcon className="exit-icon" style={{ color: `${G2_color_3}` }} onClick={onClose} />
                </Box>
            </Box>
            <HStack w="100%" pb="15px">
                <Box w="100%" className="centering-w">
                    <VStack>
                        <Box w="100px" h="100px" className="relative">
                            <Text w="100%" fontSize="35px" className="absolute z-10 top-[10px] text-white text-center font-semibold">{rightCount}</Text>
                            <Text w="100%" fontSize="35px" className="absolute z-10 top-[45px] text-white text-center font-semibold">{totalCount}</Text>
                            <Image className="absolute top-0 z-0" width="100%" src={!hasFailed ? success : fail} alt='outcome' />
                        </Box>
                        <Text mt="10px">{formatPercentage(rightCount / totalCount)} ({formatPercentage(quiz?.requiredScore / 100.0)} Required)</Text>
                    </VStack>
                </Box>
                {hasFailed === false && <Text w="100%" fontSize="16px" className="mt-[30px]">
                    You have completed the quiz for this card and can now move on to the next card.
                </Text>}
                {hasFailed === true && <Text w="100%" fontSize="16px" className="mt-[30px]">
                    Unlucky! You didn't pass this time round.
                    {canRetryQuiz ? "Try again so that you can continue to the next card." : ""}
                </Text>}
            </HStack>
            {(hasFailed === false || !canRetryQuiz) && <G2Button20 widthVal="100%" heightVal="40px" text="Done" onClick={handleCompleteClick} />}
            {hasFailed === true && canRetryQuiz && <G2Button20 widthVal="100%" heightVal="40px" text="Try Again" onClick={handleTryAgainClick} />}
        </Box>}
    </Box>;

};

export default QuizCard;