import React from "react";
import { useStateContext } from "../../ContextProvider/Context";

const G2Button21 = ({ widthVal, heightVal, text, onClick, className }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className={`bg-[#334F72] text-white text-sm hover:bg-[#233b59]  flex justify-center items-center text-center font-semibold ${className}`}
    >
      {text}
    </button>
  );
};

export default G2Button21;
