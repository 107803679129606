
const initialState = {
    channels: [],
    chatLogs: {},
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CHATLOGS":
            return {
                ...state,
                chatLogs: action.payload
            };
        case "GET_CHATCHANNELS":
            return {
                ...state,
                channels: action.payload
            };
        default:
            return state;
    }
}

export default chatReducer;
