import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Box,
  Text,
  Image,
  Button,
  Card,
  CardBody,
  Badge,
  Checkbox,
  Divider,
  Input,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { removeToBusket } from "../../Store/Actions/lifeAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import PaymentForm2 from "../Common/PaymentForm2";

import G2Button14 from "../Common/G2Button14";
import G2Button15 from "../Common/G2Button15";
import G2Button16 from "../Common/G2Button16";

import content3 from "../../assets/marketplace/content3.png";
import content1 from "../../assets/marketplace/content1.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const BucketForm = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const contentList = useSelector((state) => state.Life.contentList);
  const busket = useSelector((state) => state.Life.busket);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const [screenSize, setScreenSize] = useState(1366);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener("resize", setScreenSize);
  }, [window.innerWidth]);

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);

    if (selectedAll == false) {
      const temp = [];
      contentList
        ?.filter((item) => busket?.includes(item._id))
        ?.map((val) => {
          temp.push(val._id);
        });
      setSelectedElements(temp);
    } else {
      setSelectedElements([]);
    }
  };

  const handleElementChange = (id) => {
    if (selectedElements.includes(id)) {
      setSelectedAll(false);

      setSelectedElements(selectedElements.filter((val) => val != id));
    } else {
      if (
        contentList?.filter((item) => busket?.includes(item._id))?.length ==
        selectedElements?.length + 1
      ) {
        setSelectedAll(true);
      }

      setSelectedElements([...selectedElements, id]);
    }
  };

  const onClickBack = () => {
    navigate("/life");
  };

  const handleClickBusket = (id) => {
    dispatch(removeToBusket(toast, id));
  };

  const calcAmount = () => {
    let amount = 0;

    contentList
      ?.filter((item) => busket?.includes(item._id))
      ?.filter((ele) => selectedElements?.includes(ele._id))
      ?.map((val) => {
        amount = amount + parseFloat(val.memberCost);
      });

    return amount;
  };

  return (
    <Box
      className="market-bucket-main flex-box"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%" className="ml-[-10px] pb-[30px]">
        <G2Button16
          widthVal="240px"
          heightVal="40px"
          text="Back to Connections Page"
          onClick={onClickBack}
        />
      </Box>
      <Box className="market-bucket-left">
        <Box w="100%" className="market-bucket-title">
          <Text fontSize="24px" className="font-semibold">
            Your cart
            <Badge colorScheme="blue" fontSize="16px" className="mb-0.5 ml-2">
              {
                contentList?.filter((item) => busket?.includes(item._id))
                  ?.length
              }
            </Badge>
          </Text>
          <Checkbox
            w="100%"
            className="mt-10"
            size="lg"
            borderColor="blue.500"
            colorScheme="green"
            isChecked={selectedAll}
            onChange={handleSelectAll}
          >
            Select All Course
          </Checkbox>
        </Box>
        {contentList
          ?.filter((item) => busket?.includes(item._id))
          ?.map((val, i) => (
            <Box key={i} w="100%" className="market-bucket-content">
              <Box
                w="100%"
                className="pt-5 flex-box justify-between centering-h"
              >
                <Box className="flex-box text-left">
                  <Checkbox
                    w="100%"
                    size="lg"
                    borderColor="blue.500"
                    colorScheme="green"
                    isChecked={selectedElements.includes(val._id)}
                    onChange={() => handleElementChange(val._id)}
                  >
                    {val.name}
                  </Checkbox>
                </Box>
                <Box className="flex-box text-right">
                  <DeleteIcon
                    className="market-bucket-del"
                    onClick={() => handleClickBusket(val._id)}
                  />
                </Box>
              </Box>
              <Box w="100%" className="pl-[30px] pb-5">
                {/* <Text fontSize="14px">{val.creatorDescription}</Text> */}
              </Box>
              <Box w="100%" className="flex-box">
                <Box className="flex-box market-bucket-left-sub1">
                  <Image
                    width="100%"
                    h="150px"
                    src={getFileUrl(val.cover)}
                  />
                </Box>
                <Box className="flex-box market-bucket-left-sub2 px-5">
                  <Text w="100%" fontSize="16px">
                    {val.bioDescription}
                  </Text>
                  <Text w="100%" fontSize="14px" className="mt-5">
                    Works for :
                  </Text>
                  <Text w="100%" fontSize="16px" className="">
                    {val.businessName}
                  </Text>
                  <Text w="100%" fontSize="18px" className="my-5 font-semibold">
                    $ {val.memberCost}
                  </Text>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Box className="market-bucket-right flex-box">
        <Box
          w="100%"
          className="market-bucket-pay"
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <Box w="100%">
            <Text fontSize="20px" className="font-semibold mb-[10px]">
              Summary
            </Text>
          </Box>
          <Box w="100%" className="market-bucket-pay-sub flex-box">
            {contentList
              ?.filter((item) => busket?.includes(item._id))
              ?.filter((ele) => selectedElements?.includes(ele._id))
              ?.map((val, i) => (
                <Box key={i} w="100%" className="flex-box justify-between">
                  <Box className="flex-box text-left flex-box">
                    <Text fontSize="18px" className="mr-[10px]">
                      X{i + 1}
                    </Text>
                    <Text fontSize="18px">{val.name}</Text>
                  </Box>
                  <Box className="flex-box text-right">
                    <Text fontSize="18px">$ {val.memberCost}</Text>
                  </Box>
                </Box>
              ))}
            {isEmptyValue(selectedElements[0]) && (
              <Alert
                status="info"
                variant="solid"
                height="40px"
                borderRadius="md"
                className="mt-[10px] text-white"
              >
                <AlertIcon />
                Selecte the content.
              </Alert>
            )}
          </Box>
          <Box w="100%" className="market-bucket-pay-sub flex-box">
            <Box w="100%" className="flex-box justify-between">
              <Box className="flex-box text-left flex-box">
                <Text fontSize="18px">Amount</Text>
              </Box>
              <Box className="flex-box text-right">
                <Text fontSize="18px">$ {calcAmount()}</Text>
              </Box>
            </Box>
            <Box w="100%" className="flex-box justify-between">
              <Box className="flex-box text-left flex-box">
                <Text fontSize="18px">Promocode discount</Text>
              </Box>
              <Box className="flex-box text-right">
                <Text fontSize="18px">$ 15</Text>
              </Box>
            </Box>
            <Box w="100%" className="flex-box justify-between">
              <Box className="flex-box text-left flex-box">
                <Text fontSize="18px">Tax</Text>
              </Box>
              <Box className="flex-box text-right">
                <Text fontSize="18px">$ 7.25</Text>
              </Box>
            </Box>
          </Box>
          <Box
            w="100%"
            className="flex-box justify-between market-bucket-pay-sub"
          >
            <Box className="flex-box text-left">
              <Text fontSize="18px" className="font-semibold">
                Total
              </Text>
            </Box>
            <Box className="flex-box text-right">
              <Text fontSize="18px" className="font-semibold">
                $ {calcAmount() + 22.25}
              </Text>
            </Box>
          </Box>
          <Box
            w="100%"
            className="flex-box mt-5 pb-5 mb-5 border-b border-black border-1"
          >
            <Box className="market-bucket-pay-left">
              <Input
                w="100%"
                placeholder="Apply promocode"
                bg={G2_color_1}
                color={G2_color_3}
                isDisabled
              />
            </Box>
            <Box className="market-bucket-pay-right">
              <G2Button15 widthVal="100%" heightVal="40px" text="Apply" />
            </Box>
          </Box>
          {/* <Box w="100%" className="mt-5">
                        <G2Button14 widthVal="100%" heightVal="40px" text="Proceed to pay" onClick={onClickPay} />
                    </Box> */}
          <Box w="100%">
            <Elements stripe={stripePromise} options={options}>
              <PaymentForm2
                price={calcAmount() + 22.25}
                selectedElements={selectedElements}
              />
            </Elements>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BucketForm;
