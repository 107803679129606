import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const setTheme = (mode) => {
  return (dispatch) => {
    if (mode == "light") {
      dispatch({
        type: "THEME_SET",
        payload: {
          mode: "light",
          color1: "#efefef",
          color2: "#ffffff",
          color3: "#000000",
        },
      });
    } else {
      dispatch({
        type: "THEME_SET",
        payload: {
          mode: "dark",
          color1: "#1e1e26",
          color2: "#272a3d",
          color3: "#efefef",
        },
      });
    }
  };
};

const makeContentData = (contentName, unitList) => {
  return (dispatch) => {
    dispatch({ type: "LOAD_CONTENT_DATA", payload: { contentName, unitList } });
  };
};

const saveUnitData = (content) => {
  return (dispatch) => {
    dispatch({ type: "SAVE_UNIT_DATA", payload: content });
  };
};

const getMentorList = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/billing/mentor")
      .then((res) => {
        dispatch({ type: "GET_MENTOR_LIST", payload: res.data.mentorList });
      })
      .catch((error) => {
        console.error("Error getting mentor list:", error);
      });
  };
};

const requestPayment = (toast, mentorId, type) => {
  return (dispatch) => {
    g2AxiosT
      .post("/billing/request", { mentorId, type })
      .then((res) => {
        g2Toast({ toast, status: "success", title: res.data });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error registering goal data:", error);
      });
  };
};

const getAffiliateCodes = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAccess&method=getAffiliateCodes", {})
      .then((res) => {
        dispatch({ type: "GET_AFFILIATE_CODES", payload: res.data });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error failed Getting Affiliate Codes", error);
      });
  };
};

const createAffiliateCode = (toast, code) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAccess&method=createAffiliateCode", { code })
      .then((res) => {
        g2Toast({ toast, status: "success", title: "Affiliate Code Created" });
        dispatch({ type: "GET_AFFILIATE_CODES", payload: res.data });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error failed creating Affiliate code", error);
      });
  };
};

const getAffiliateCodeUsers = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAccess&method=getAffiliateCodeUsers", {})
      .then((res) => {
        dispatch({ type: "GET_AFFILIATE_USERS", payload: res.data });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error failed Getting Affiliate Codes", error);
      });
  };
};

const getUserCredits = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .post("/?class=ControllerAccess&method=getUserCredits", {})
      .then((res) => {
        dispatch({ type: "GET_USER_CREDITS", payload: res.data });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error failed Getting User Credits", error);
      });
  };
};
const getSystemContent = () => {
  return (dispatch) => {
    g2Axios
      .post("/", { "class": "ControllerAccess", "method": "getSystemContent" })
      .then((res) => {
        dispatch({ type: "SYSTEM_GET_CONTENT", payload: res.data });
      })
      .catch((error) => {
        console.error("Error failed Getting System Content", error);
      });
  };
}

export {
  setTheme,
  makeContentData,
  saveUnitData,
  getMentorList,
  requestPayment,
  createAffiliateCode,
  getAffiliateCodes,
  getAffiliateCodeUsers,
  getUserCredits,
  getSystemContent
};
