import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { parseCSV, removeAt } from '../helpers/general';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    VStack,
    Text,
    Select,
    HStack,
    Checkbox,
    Button,
    Input,
} from '@chakra-ui/react'
import _ from 'lodash';
import { FaArrowLeft, FaArrowRight, FaEye, FaEyeSlash } from 'react-icons/fa';

const ExternalFileLoader = ({ file, setRecords }) => {

    const [requiredFields, setRequiredFields] = useState({
        "title": "",
        "date": "",
        "organisation": "",
        "certifier": ""
    });

    const [showHidden, setShowHidden] = useState(false);
    const [rowIndex, setRowIndex] = useState(0);
    const [emptyColumnIndexs, setEmptyColumnIndexs] = useState([]);
    const [content, setContent] = useState(null);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (file == undefined) {
            return;
        }
        console.log(file)

        const reader = new FileReader();
        reader.onload = (e) => {
            setContent(e.target.result);
        };
        reader.readAsText(file);
        setRecords([]);
    }, [file]);

    const isEmpty = useMemo(() => {
        if (content == null) {
            return true;
        }
        return content.length == 0 || content.trim() == "";
    }, [content]);

    const cells = useMemo(() => {
        if (content == null) {
            return [];
        }
        return parseCSV(content)
    }, [content]);

    useEffect(() => {
        let emptyCells = null;
        const rows = cells.slice(1);
        for (let i in rows) {
            let r = rows[i];
            if (emptyCells == null) {
                emptyCells = [];
                for (let j in r) {
                    let c = r[j];
                    if (c.trim() == "") {
                        emptyCells.push(j);
                    }
                }
                continue;
            }

            if (emptyCells.length == 0) {
                return emptyCells;
            }

            let newEmptyCells = [];
            for (let j of emptyCells) {
                let c = r[j];
                if (c.trim() == "") {
                    newEmptyCells.push(j);
                }
            }
            emptyCells = newEmptyCells;
        }

        setEmptyColumnIndexs(emptyCells);
    }, [cells]);

    const heading = useMemo(() => {
        if (isEmpty) {
            return [];
        }

        if (showHidden) {
            return cells[0];
        }

        return cells[0].filter((r, i) => (emptyColumnIndexs ?? []).indexOf(i + "") === -1);
    }, [cells, emptyColumnIndexs, showHidden]);

    const rows = useMemo(() => {
        if (isEmpty) {
            return [];
        }

        if (showHidden) {
            return cells.slice(1);
        }

        return cells.slice(1).map(r => r.filter((c, i) => (emptyColumnIndexs ?? []).indexOf(i + "") === -1));
    }, [cells, emptyColumnIndexs, showHidden]);


    const update = useCallback((path, value) => {
        let temp = _.cloneDeep(requiredFields);
        temp = _.set(temp, path, value);
        setRequiredFields(temp);
    }, [requiredFields, setRequiredFields]);

    const toggleVisibility = useCallback((index) => {
        let temp = _.cloneDeep(emptyColumnIndexs);
        console.log(index, temp);
        let position = emptyColumnIndexs.indexOf(index);
        if (position !== -1) {
            temp = removeAt(temp, position);
        } else {
            temp.push(index + "");
        }
        console.log(temp);
        setEmptyColumnIndexs(temp);
    }, [emptyColumnIndexs, setEmptyColumnIndexs]);

    useEffect(() => {
        let data = [];

        let fields = Object.keys(requiredFields).filter(r => requiredFields[r] != "");
        if (!fields.includes("title") && !fields.includes("date")) {
            return;
        }

        for (let r of cells.slice(1)) {
            let row = {};
            for (let field of Object.keys(requiredFields)) {
                if (requiredFields[field] == "") {
                    continue;
                }

                if (parseInt(requiredFields[field]) + "" != requiredFields[field] + "") {
                    row[field] = requiredFields[field];
                } else {
                    row[field] = r[parseInt(requiredFields[field])];
                }
            }
            data.push(row);
        }

        setResults(data);
        setRecords(data);
    }, [requiredFields, cells]);

    if (isEmpty) {
        return <Text color="var(--red)">File is Empty</Text>
    }

    return <Box w="100%" h="100%">
        {
            //JSON.stringify(heading)
        }
        <Tabs>
            <TabList>
                <Tab>File</Tab>
                <Tab>Mapping</Tab>
                <Tab>Records</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <TableContainer w="100%" h="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    {heading.map((c, i) => <Th key={i}>{c}</Th>)}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {rows.map((r, i) => <Tr key={i}>
                                    {r.map((c, i) => <Td key={i}>{c}</Td>)}
                                </Tr>)}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <TabPanel>
                    <VStack w="100%" alignContent="left">
                        <Checkbox isChecked={showHidden} onChange={() => { setShowHidden(!showHidden) }}>Show Hidden</Checkbox>
                        {Object.keys(requiredFields).map((r, i) => <HStack key={i} w="100%" justifyContent="left">
                            <Text w="200px" className='capitalize'>{r}</Text>
                            <Select value={requiredFields[r]} onChange={(e) => update(r, e.target.value)}>
                                <option value="">Select</option>
                                {cells[0].map((r, i) => {
                                    return { id: i, name: r }
                                }).filter(r => (emptyColumnIndexs ?? []).indexOf(r.id + "") === -1).map((r, i) => <option key={i} value={r.id}>{r.name}</option>)}
                            </Select>
                            {parseInt(requiredFields[r]) != requiredFields[r] + "" && <Input placeholder='Or enter a fixed value' type="text" value={requiredFields[r]} onChange={(e) => update(r, e.target.value)} />}
                        </HStack>)}
                        <Box>Record {rowIndex + 1}/{rows.length}</Box>
                        <HStack w="100%">
                            <Button visibility={(rowIndex <= 0) ? "hidden" : "visible"} size="sm" onClick={() => setRowIndex(rowIndex - 1)}><FaArrowLeft /></Button>
                            <TableContainer w="100%" h="100%">
                                <Table size='sm'>
                                    <Tbody>
                                        {heading.map((r, i) => <Tr key={i}>
                                            <Th width="200px">
                                                {showHidden && <Button size="sm" onClick={() => toggleVisibility(i)}>
                                                    {(emptyColumnIndexs ?? []).indexOf(i + "") === -1 ? <FaEye /> : <FaEyeSlash />}
                                                </Button>}
                                                {r}
                                            </Th>
                                            <Th width="auto">{rows[rowIndex][i]}</Th>
                                        </Tr>)}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Button visibility={rowIndex < rows.length ? "visible" : "hidden"} size="sm" onClick={() => setRowIndex(rowIndex + 1)}><FaArrowRight /></Button>
                        </HStack>
                    </VStack>
                </TabPanel>

                <TabPanel>
                    <TableContainer w="100%" h="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    {Object.keys(requiredFields).filter(r => requiredFields[r] != "").map((c, i) => <Th key={i} className='capitalize'>{c}</Th>)}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {results.map((r, i) => <Tr key={i}>
                                    {Object.keys(requiredFields).filter(f => requiredFields[f] != "").map(f => <Td key={f}>
                                        {r[f]}
                                    </Td>)}
                                </Tr>)}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </TabPanels>
        </Tabs>

    </Box >
}

export default ExternalFileLoader;