import React from "react";
import { Box, HStack, Radio, RadioGroup, Text, VStack } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { useStateContext } from "../../ContextProvider/Context";
import SubscriptionForm from "../../Components/Subscription/SubscriptionForm";
import G2Button21 from "../../Components/Common/G2Button21";
import { useNavigate } from "react-router-dom";

const SubscriptionPage = ({ }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const navigate = useNavigate();

    return <Box minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
        <Navbar title="Subscription" />
        <HStack w="100%" justifyContent="center" p="15px" mt="50px">
            <VStack width="400px">
                <SubscriptionForm />
                <G2Button21 widthVal="400px" heightVal="35px" text="Skip Now" onClick={() => navigate("/onboard")} />
            </VStack>
        </HStack>

    </Box>
};

export default SubscriptionPage;
