import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  useToast,
  Input,
  Checkbox,
  IconButton,
} from "@chakra-ui/react";

import { EditIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getMember } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import user from "../../assets/create/user.png";

const MemberComponent = (props) => {
  const { boxW2, member, selectedMembers, handleMemberSelect } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const contentList = useSelector((state) => state.Create.contentList);
  const unitList = useSelector((state) => state.Create.unitList);
  const cardList = useSelector((state) => state.Create.cardList);
  const activityList = useSelector((state) => state.Create.activityList);
  const group = useSelector((state) => state.Create.group);

  const handleMemberClick = (e, id) => {
    e.stopPropagation();
    dispatch(getMember(toast, navigate, id));
  };

  const calcPercent = (contentId) => {
    const units = unitList?.filter((val) => val.contentId == contentId);

    let assignedCardIds = [];
    units?.map((unit) => {
      const cards = cardList?.filter((card) => card.unitId == unit._id)?.map(r => r._id);
      assignedCardIds?.push(...cards);
    });

    const activity = activityList?.find((val) => val._id == member._id);
    const passedCardIds = assignedCardIds.filter((cardId) => activity?.passedCardIds?.includes(cardId));
    const percent = ((passedCardIds.length / assignedCardIds.length) * 100).toFixed(0).toString() + "%";
    return percent;
  };

  const calcCardCnt = (id) => {
    const units = unitList?.filter((val) => val.contentId == id);

    let assignedCards = [];
    units?.map((item) => {
      const cards = cardList?.filter((val) => val.unitId == item._id);
      assignedCards?.push(...cards);
    });
    return assignedCards.length;
  };

  return (
    <Box className="create-group-member-box p-[10px] relative">
      <Box w="100%" bg={G2_color_2} className={`py-[5px] normal-hover centering-h rounded-tl-[2.5px] rounded-tr-[2.5px] shadow border ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"} border-1`} onClick={(e) => handleMemberClick(e, member._id)}>
        <Box w="70px" className="pl-[20px] centering-h">
          <Image boxSize="35px" src={user} alt="avatar" />
        </Box>
        <Box style={{ width: "calc(100% - 126px)" }}>
          <Text fontSize="16px" className="font-semibold">
            {member.memberId.name}
          </Text>
        </Box>
        <Box w="56px" h="56px" className="pl-[20px] centering-h" onClick={(e) => handleMemberSelect(e, member._id)}>
          <Checkbox size="md" borderColor="blue.500" colorScheme="red" isChecked={selectedMembers?.includes(member._id)} />
        </Box>
      </Box>
      <Box w="100%" bg={G2_color_1} className={`px-[20px] pt-[10px] pb-[20px] rounded-bl-[2.5px] rounded-br-[2.5px] shadow border-l border-r border-b ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"} border-1`}>
        {!isEmptyValue(group?.defaultContentIds[0]) ? (
          <Box>
            {contentList?.filter((element) => group?.defaultContentIds?.includes(element._id))?.map((content, i) => (
              <Box key={i}>
                <Box className="mt-[5px]">
                  <Text fontSize="14px" className="font-semibold">
                    {content.title}
                  </Text>
                </Box>
                <Box w="100%" h="20px" bg="#032c47" className="flex-box mt-[3px] relative centering-h">
                  <Box w={calcPercent(content._id)} h="100%" bg="var(--orange)" color="white" fontSize="12px"></Box>
                  <Text fontSize="12px" className="absolute top-[0px] right-[10px] text-white">
                    {content.size} Units
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default MemberComponent;
