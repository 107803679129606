import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa';

const QuizReview = ({ item }) => {

    const getQuestionText = useCallback((question) => {
        switch (question.type) {
            case "geometry":
                return <Text display="inline" dangerouslySetInnerHTML={{ __html: question.shape.name }}></Text>;
            default:
                return <Text display="inline" dangerouslySetInnerHTML={{ __html: question.question }}></Text>;
        }
    }, []);

    const getAnswerText = useCallback((question, result) => {
        switch (question.type) {
            case "geometry":

                return <>
                    {Object.keys(result.params).map(r => <>
                        <Text pl="15px">{r}: {result.params[r]}</Text>
                    </>)}
                    <Text pl="15px">{result.answer}</Text>
                </>
            default:
                return <Text pl="15px" dangerouslySetInnerHTML={{ __html: result.answer }}></Text>;
        }
    }, []);

    return <VStack w="100%">
        {item.quiz.questions.map((question, i) => <HStack key={i} w="100%" justifyContent="left">
            {item.quizResults[i].rightAnswer != undefined && <>
                <Box display="inline">{item.quizResults[i].answer == item.quizResults[i].rightAnswer ? <FaCheckCircle color="var(--green)" /> : <FaTimesCircle color="var(--red)" />}</Box>
            </>}
            {item.quizResults[i].rightAnswer == undefined && <>
                <Box display="inline">
                    {item.outcome.accepted != null && <FaCheckCircle color="var(--green)" />}
                    {item.outcome.rejected != null && <FaTimesCircle color="var(--red)" />}
                    {item.outcome.accepted == null && item.outcome.rejected == null && <FaQuestionCircle color="var(--orange)" />}
                </Box>
            </>}
            <Text>{i + 1}. </Text>
            {getQuestionText(question)}
            {getAnswerText(question, item.quizResults[i])}
            {item.outcome.name != null && <Text>{item.outcome.name}</Text>}
        </HStack>)}
    </VStack>

}

export default QuizReview;