import React from "react";

import { useStateContext } from '../../ContextProvider/Context';

const G2Button12 = ({ widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="bg-[var(--orange)] text-white hover:bg-[#2a956e] rounded-sm flex justify-center items-center text-center font-semibold"
    >
      {text}
    </button>
  );
}

export default G2Button12;