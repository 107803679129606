import { Box, HStack, Image, Input, Text, useToast, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import g2AxiosT from '../../Custom/g2AxiosT';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { getFileUrl } from '../../helpers/general';
import g2Toast from "../../Custom/g2Toast";

const SearchBox = ({ }) => {
    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);
    const toast = useToast();

    const fetchData = useCallback(_.debounce((value) => {
        if (value.length <= 0) {
            return;
        }

        g2AxiosT
            .post("/?class=ControllerAcademy&method=search", { search: value })
            .then((res) => {
                setResults(res.data);
            })
            .catch((error) => {
                g2Toast({ toast, status: "error", title: "Error Searching" });
                console.error("Error Searching", error);
            });

    }, 500), [setResults]);

    useEffect(() => {
        fetchData(value);
    }, [value, setResults]);

    return <Box position="relative">
        <Input placeholder='Search...' type="text" value={value} onChange={(e) => setValue(e.target.value)} />
        {value.length > 0 && <Box w="100%" position="absolute" zIndex="1000" bg="white" border="1px solid var(--blue)">
            {results.length == 0 && <Text>No Results</Text>}
            {value.length > 0 && <VStack w="100%">
                {results.map(r => <Box key={r.hashId} w="100%" textAlign="left" p="5px" onClick={() => setValue("")}>
                    <NavLink to={`/academy/digital/` + r.hashId}>
                        <HStack w="100%">
                            <Image borderRadius="full" bg="white" boxSize="32px" src={getFileUrl(r?.avatar)} className="my-[5px]" alt="avatar" />
                            <Text>{r.name}</Text>
                        </HStack>
                    </NavLink>
                </Box>)}
            </VStack>}
        </Box>}
    </Box>
}

export default SearchBox;