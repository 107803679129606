import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";

import Navbar from "../../Components/Navbar/Navbar";
import EditForm from "../../Components/Marketplace/EditForm";

import UniversalLeftSideBar from "../../Components/Common/UniversalLeftSideBar";
import RegisterForm from "../../Components/Marketplace/RegisterForm";

const EditPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  const content = useSelector((state) => state.Marketplace.content);

  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%">
        <Navbar title="Marketplace" />
      </Box>
      <Box w="100%" className="flex-box">
        <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>
        <Box className="universal-body-w">
          <RegisterForm content={content} />
        </Box>
      </Box>
    </Box>
  );
};

export default EditPage;
