import React, { useEffect, useMemo, useState } from 'react';
import { formatCurrency } from '../../helpers/formatters';
import { Box, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Radio, RadioGroup, Text , Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,

} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm4 from '../Common/PaymentForm4';
import { useStateContext } from '../../ContextProvider/Context';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAvailableSubscriptions } from '../../Store/Actions/onboardAction';
import { loadStripe } from "@stripe/stripe-js";
import { BsInfoCircle } from "react-icons/bs";


const options = {
    mode: "payment",
    amount: 100,
    currency: "aud",
    appearance: {
        /*...*/
    },
};

const SubscriptionForm = ({ title = "Subscription" }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const availableSubscriptions = useSelector((state) => state.Onboard.availableSubscriptions);
    const [type, setType] = useState(null);
    const [users, setUsers] = useState(0);
    const{infoOpen , setInfoOpen} = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedInfo, setSelectedInfo] = useState(null);
    const dispatch = useDispatch();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    useEffect(() => {
        dispatch(getAvailableSubscriptions());
    }, []);

    const selectedSubscription = useMemo(() => {
        const s = availableSubscriptions.filter(r => r.id + "" == type);
        if (s.length == 1) {
            return s[0];
        }

        return null;
    }, [type]);

    const calcPrice = useMemo(() => {
        if (type == null) {
            return 0;
        }

        return selectedSubscription?.periodCost + (users * (selectedSubscription?.additionalUsers ?? 0));
    }, [type, users]);

    const handleInfoClick = (id) => {
        console.log(availableSubscriptions , "ASSSSS")
        const subscriptionInfo = availableSubscriptions.find(sub => sub.id === id); // Find the subscription by ID
        setSelectedInfo(subscriptionInfo);
        onOpen(true); // Open the modal
    };

    return <Box w="100%">
        <Box w="100%" className="market-bucket-pay" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
            <Box w="100%">
                <Text fontSize="20px" className="font-semibold mb-[10px]">{title}</Text>
            </Box>
            <Box w="100%" className="market-bucket-pay-sub flex-box">
                <RadioGroup className="wide-radio" w="100%" value={type} onChange={setType}>

                    {availableSubscriptions.map(r => 
                    <Radio key={r.id} borderColor="blue.500" colorScheme="green" w="100%" value={r.id + ""}>
                        <HStack w="100%" justifyContent="space-between">
                                <Box flex="1">
                                    <Text fontWeight="semibold">{r.name}</Text>
                                    <Text fontSize="sm" color="gray.600">{r.description}</Text>
                                </Box>
                                <Box fontWeight="bold" minWidth="70px" textAlign="right">
                                    {formatCurrency(r.periodCost)}
                                </Box>
                                <Box as={BsInfoCircle}
                                    onClick={() => handleInfoClick(r.id)}
                                    cursor="pointer"
                                    boxSize="20px"
                                    color="gray.500"
                                    _hover={{ color: "blue.500" }} />
                            </HStack>
                        </Radio>
                    )}
                </RadioGroup>
            </Box>
            {
                //JSON.stringify(selectedSubscription)
            }
            {selectedSubscription?.additionalUsers != null && <>
                <Box w="100%" className="flex-box justify-between market-bucket-pay-sub">
                    <Box className="flex-box text-left">
                        <Text fontSize="18px" className="font-semibold">Additional Users</Text>
                    </Box>
                    <Box className="flex-box text-right">
                        <NumberInput w="60px" value={users} onChange={(value) => setUsers(value)} min={0} max={100}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>
                </Box>
            </>
            }
            <Box w="100%" className="flex-box justify-between market-bucket-pay-sub">
                <Box className="flex-box text-left">
                    <Text fontSize="18px" className="font-semibold">Price</Text>
                </Box>
                <Box className="flex-box text-right">
                    <Text fontSize="18px" className="font-semibold">{formatCurrency(calcPrice)}</Text>
                </Box>
            </Box>
            {type != null && <Box w="100%" className="mt-[20px]">
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm4 subscriptionId={type} users={users} />
                </Elements>
            </Box>}
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader style={{textAlign:"center" , color:"orange"}}>{selectedInfo?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Text>
                        {selectedInfo?.name === "LITE" &&
                            "School Students, Apprentices, Job Seeker, Parents"}
                        {selectedInfo?.name === "USER" &&
                            "Trade Workers, Trade Managers, Business Owners, Support People"}
                        {selectedInfo?.name === "BUSSINESS" &&
                            "Trade Business, Content Creator, Paid Mentor Trade Business/Support"}
                    </Text>
                        {/* <Text fontWeight="bold" mt="4">Cost: {formatCurrency(selectedInfo?.periodCost)}</Text> */}
                    </ModalBody>
                </ModalContent>
            </Modal>
            
    </Box>

};

export default SubscriptionForm;