import React, { useState, useEffect, useCallback } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Resizer from "react-image-file-resizer";
import "./style.css";

import { useStateContext } from "../../ContextProvider/Context";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			1024,
			768,
			"JPEG",
			75,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});

const G2CKEditor = ({ value, setValue }) => {
	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

	const editorConfig = {
		mediaEmbed: {
			previewsInData: true
		},
		styles: [
			"style.css", // Path to your custom CSS file
		],
	};

	const handleEditorChange = useCallback((event, editor) => {
		const data = editor.getData();
		setValue(data);
	}, [value, setValue]);

	/* global ClassicEditor:readonly */

	const onReady = useCallback((editor) => {
		const fileRepositoryPlugin = editor.plugins.get("FileRepository");
		if (fileRepositoryPlugin) {
			fileRepositoryPlugin.createUploadAdapter = (loader) => {
				return {
					upload: async () => {
						try {
							const image = await loader.file;

							// Convert the image to Base64
							const reader = new FileReader();
							reader.readAsDataURL(image);

							reader.onload = async () => {
								let base64String = await resizeFile(image);

								// Insert the Base64 string into the CKEditor's data field
								const data = editor.getData();
								const newData = `${data}<img src="${base64String}" alt="base64image" />`;
								setValue(newData);
							};

							return 0;
						} catch (error) {
							console.error("Error uploading image: ", error);
						}
					},
				};
			};
		} else {
			console.error("FileRepository plugin not found");
		}
	}, [value, setValue]);

	return <CKEditor editor={ClassicEditor} config={editorConfig} on onReady={onReady} data={value} onChange={handleEditorChange} />
};

export default G2CKEditor;
