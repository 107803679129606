import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  useToast,
  Input,
  Checkbox,
  IconButton,
} from "@chakra-ui/react";

import { TimeIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";

import "../../App.css";

import UnitComponent from "./UnitComponent";

import { useStateContext } from "../../ContextProvider/Context";
import {
  getGroup,
  deleteMember,
  deleteMemberList,
} from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import InviteModal from "./InviteMentorModal";
import GroupEditModal from "./GroupEditModal";
import DefaultGroupAddModal from "./DefaultGroupAddModal";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import G2Button19 from "../Common/G2Button19";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";

import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import group1 from "../../assets/create/group1.png";
import group2 from "../../assets/create/group2.png";
import edit1 from "../../assets/create/edit1.png";
import edit2 from "../../assets/create/edit2.png";
import user from "../../assets/create/user.png";
import print1 from "../../assets/create/print1.png";
import print2 from "../../assets/create/print2.png";
import { formatRelativeTime } from "../../helpers/formatters";
import ActivityCard from "../ActivityCard";

const MemberEditForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const fileInputRef = useRef(null);

  const contentList = useSelector((state) => state.Create.contentList);
  const groupList = useSelector((state) => state.Create.groupList);
  const group = useSelector((state) => state.Create.group);
  const member = useSelector((state) => state.Create.member);
  const unitList = useSelector((state) => state.Create.unitList);
  const cardList = useSelector((state) => state.Create.cardList);
  const activityList = useSelector((state) => state.Create.activityList);
  const activity = useSelector((state) => state.Create.activity);

  const [isMemberDopen, setIsMemberDopen] = useState(false);

  const [isIAddopen, setIsIAddOpen] = useState(false);
  const [isGEditOpen, setIsGEditOpen] = useState(false);
  const [isDGAddOpen, setIsDGAddOpen] = useState(false);

  useEffect(() => {
    if (isEmptyValue(groupList[0]?._id) || isEmptyValue(group._id) || isEmptyValue(member._id)) {
      navigate("/create");
    }
  }, []);

  const openIAddModal = () => {
    setIsIAddOpen(true);
  };

  const closeIAddModal = () => {
    setIsIAddOpen(false);
  };

  const openGEditModal = () => {
    setIsGEditOpen(true);
  };

  const closeGEditModal = () => {
    setIsGEditOpen(false);
  };

  const openDGAddModal = () => {
    setIsDGAddOpen(true);
  };

  const closeDGAddModal = () => {
    setIsDGAddOpen(false);
  };

  const openMemberDModal = () => {
    setIsMemberDopen(true);
  };

  const closeMemberDModal = () => {
    setIsMemberDopen(false);
  };

  const handleDeleteMember = () => {
    dispatch(deleteMember(toast, navigate, member._id));

    setIsMemberDopen(false);
  };

  const calcPercent = (contentId) => {
    const units = unitList?.filter((val) => val.contentId == contentId);

    let assignedCardIds = [];
    units?.map((unit) => {
      const cards = cardList?.filter((card) => card.unitId == unit._id)?.map(r => r._id);
      assignedCardIds?.push(...cards);
    });

    const activity = activityList?.find((val) => val._id == member._id);
    const passedCardIds = assignedCardIds.filter((cardId) => activity?.passedCardIds?.includes(cardId));
    const percent = ((passedCardIds.length / assignedCardIds.length) * 100).toFixed(0).toString() + "%";
    return percent;
  };

  const handlePrint = () => {
    window.print();
  };

  const userGroups = useMemo(() => {
    let data = [];
    for (var assignedGroup of (member?.assignedGroupIds ?? [])) {
      const group = groupList?.filter(r => r._id == assignedGroup.groupId)[0];
      data.push({
        assignment: assignedGroup,
        group
      });
    }

    return data;
  }, [groupList, member]);

  return (
    <Box w="100%" className="flex-box" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box w="100%" bg={G2_color_2} className="create-group-main centering-h shadow">
        <Box w="100%" className="flex-box justify-between centering-h">
          <Box className="flex-box text-left centering-h">
            <Image boxSize="25px" src={G2_theme_mode == "light" ? group1 : group2} alt="icon" />
            <Box className="flex-box centering-h">
              {!isEmptyValue(member?.memberId?._id) && <Box>
                <Text fontSize="16px" className="font-semibold mx-[10px]">
                  {member.memberId.name}
                </Text>
                <Text
                  fontSize="14px"
                  className="mx-[10px] normal-hover"
                  onClick={() => navigate("/create/group")}
                >
                  {group.title}
                </Text>
              </Box>}
            </Box>
          </Box>
          <Box className="flex-box text-right centering-h">
            <Box className="mr-5 normal-hover flex" onClick={handlePrint}>
              <Image
                className="mt-[5px] mr-[5px]"
                boxSize="15px"
                src={G2_theme_mode == "light" ? print1 : print2}
                alt="icon"
              />
              <Text>Print report</Text>
            </Box>
            <Box className="mr-5 normal-hover flex" onClick={openMemberDModal}>
              <DeleteIcon boxSize="15px" className="mt-[5px] mr-[5px]" />
              <Text>Delete Member</Text>
            </Box>
            <G2ConfirmAlert isOpen={isMemberDopen} onClose={closeMemberDModal} handleOkClick={handleDeleteMember} />
            <G2Button20 widthVal="130px" heightVal="35px" text="Invite Mentor" onClick={openIAddModal} />
            <InviteModal isIAddopen={isIAddopen} closeIAddModal={closeIAddModal} />
          </Box>
        </Box>
        <GroupEditModal isGEditOpen={isGEditOpen} closeGEditModal={closeGEditModal} />
      </Box>
      <Box className="create-group-main-left py-[10px] print-area">
        <Box w="100%" className="mt-[20px] px-[10px]">
          <Text fontSize="20px" className="font-semibold">
            Activity
          </Text>
        </Box>
        {
          //JSON.stringify(activity)
        }
        {!isEmptyValue(activity?._id) ? (
          <Box className="px-[10px]">
            {Object.values(activity?.activeCards)?.map((r, i) => <ActivityCard key={i} item={r} groupMemberId={activity._id} />)}
          </Box>
        ) : (
          <Box w="100%" className="px-[10px] mt-[10px]">
            <Text w="100%" fontSize="16px">
              There is no recorded activity.
            </Text>
          </Box>
        )}
      </Box>
      <Box className="create-group-main-right" pt="20px" pb="30px">
        <Text fontSize="16px" my="15px" className="font-semibold">
          Assigned Content
        </Text>
        {contentList && <Box>
          {contentList?.filter((element) => group.defaultContentIds?.includes(element._id))?.map((val, i) => (
            <Box key={i} w="100%" bg={G2_color_2} className="px-[20px] py-[10px] mb-[10px] rounded-[2.5px] shadow">
              <Box w="100%" className="flex-box">
                <Box w="30px" className="centering-h">
                  <Image boxSize="20px" src={G2_theme_mode == "light" ? content1 : content2} alt="icon" />
                </Box>
                <Box style={{ width: "calc(100% - 30px)" }}>
                  <Text fontSize="14px" className="font-semibold">{val.title}</Text>
                </Box>
              </Box>
              <Box w="100%" h="20px" bg="#032c47" className="flex-box mt-[5px] relative">
                <Box w={calcPercent(val._id)} h="100%" bg="var(--orange)"></Box>
                <Text fontSize="12px" className="absolute top-[0px] right-[10px] text-white">
                  {val.size} units
                </Text>
              </Box>
            </Box>
          ))}
        </Box>}
        <Text fontSize="16px" className="font-semibold mt-[30px] mb-[15px]">
          {member?.memberId?.name} belongs to
        </Text>
        {member && <Box w="100%">
          {
            //JSON.stringify(userGroups)
          }
          {userGroups.map((r, i) => (
            <Box key={i} w="100%" bg={G2_color_2} className="flex centering-h px-[20px] py-[10px] mb-[10px] rounded-[2.5px] shadow">
              <Image boxSize="25px" src={G2_theme_mode == "light" ? group1 : group2} alt="icon" />
              <Box className="ml-[20px]">
                <Text fontSize="16px" className="font-semibold">
                  {r?.group?.title}
                </Text>
                <Text fontSize="13px">Subcribed {formatRelativeTime(r.assignment.created)}</Text>
              </Box>
            </Box>
          ))}
        </Box>}
        <G2Button23 widthVal="130px" heightVal="35px" text="Add to group" onClick={openDGAddModal} />
        <DefaultGroupAddModal isDGAddOpen={isDGAddOpen} closeDGAddModal={closeDGAddModal} />
      </Box>
    </Box >
  );
};

export default MemberEditForm;
