import React from "react";
import {
  Box,
  Image,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const BadgeComponent = ({ badge }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Badge that user clicks */}
      <Flex alignItems="center" onClick={onOpen} cursor="pointer">
        <Box position="relative" display="inline-block">
          <Image
            src={badge.icon}
            boxSize="40px"
            borderRadius="full"
            alt={badge.name}
          />
        </Box>
      </Flex>

      {/* Modal for showing badge details */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{badge.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Badge icon */}
            <Flex justifyContent="center" alignItems="center">
              <Image
                src={badge.icon}
                boxSize="100px"
                borderRadius="full"
                alt={badge.name}
                mb={4}
              />
            </Flex>
            {/* Badge description */}
            <Text>{badge.description}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BadgeComponent;
