import React from "react";
import { EditIcon } from '@chakra-ui/icons';

import { useStateContext } from '../../ContextProvider/Context';

const G2Button22 = ({ widthVal, heightVal, text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{ width: `${widthVal}`, height: `${heightVal}` }}
      className="bg-[#334F72] text-white text-sm mr-2 hover:bg-[#233b59] rounded-sm flex justify-center items-center text-center font-semibold"
    >
      <EditIcon className="mr-2" />{text}
    </button>
  );
}

export default G2Button22;