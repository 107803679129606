import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Image,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
  Tooltip,
  Card,
  CardBody,
  Icon,
} from "@chakra-ui/react";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getFilteredMenu } from "../../helpers/menu";
import { useSelector } from "react-redux";

const UniversalLeftSideBar = ({ menuShow, screenSize, showText = false }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);
  const type = useSelector((state) => state.Auth.type);

  useEffect(() => {
    if (user.forcePasswordReset) {
      let token = user.verificationToken;
      navigate("/forgotten/" + token);
    }
  }, [user]);

  const onClickSideMenu = (element) => {
    navigate(element.path);
  };

  const menuItems = useMemo(() => {
    return getFilteredMenu(user.accessRights);
  }, [user]);

  return (
    <Box
      w="100%"
      minHeight="100vh"
      // h="100%"
      className="bg-cover bg-no-repeat pt-[20px] px-[22px]"
      style={{ backgroundColor: "var(--orange)" }}
    >
      {menuItems.map((group, i) => (
        <Box key={i}>
          <List>
            {group.map((element, index) => (
              <ListItem
                key={index}
                className="list-item"
                onClick={() => onClickSideMenu(element)}
              >
                <Tooltip
                  label={element.text}
                  placement="right"
                  closeOnClick={true}
                  bg="gray"
                >
                  <>
                    {element.img != undefined && (
                      <Image
                        m="10px 0px 10px 0px"
                        boxSize="25px"
                        src={element.img}
                        alt="icon"
                      />
                    )}
                    {element.icon != undefined && (
                      <Icon
                        m="10px 0px 10px 0px"
                        boxSize="25px"
                        color="#4B4B4B"
                        as={element.icon}
                      />
                    )}
                  </>
                </Tooltip>
                {showText && screenSize > 768 && (
                  <Text
                    paddingLeft="10px"
                    paddingTop="10px"
                    fontSize="16px"
                    className="font-semibold text-gray"
                  >
                    {element.text === "Passport"
                      ? user?.type === "Business"
                        ? "Profile"
                        : "Passport"
                      : element.text}
                  </Text>
                )}
              </ListItem>
            ))}
          </List>
          {i != group.length - 1 && (
            <Box
              width="100%"
              h="0px"
              className="mt-[10px] my-[10px]"
              style={{ borderBottom: "1px solid lightgray" }}
            ></Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default UniversalLeftSideBar;
