import React from "react";
import { Box } from "@chakra-ui/react";

import { useStateContext } from '../ContextProvider/Context';

import Navbar from "../Components/Navbar/Navbar";
import SinginForm from "../Components/Login/Login";

const SignInPage = () => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    return (
        <Box minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
            <Navbar title="Login" />
            <SinginForm />
        </Box>
    );
};

export default SignInPage;