import circle from "../../assets/card-graphics/Circle.svg";
import cube from "../../assets/card-graphics/Cube.svg";
import cuboid from "../../assets/card-graphics/Cuboid.svg";
import cylinder from "../../assets/card-graphics/Cylinder.svg";
import equilateralTriangle from "../../assets/card-graphics/EquilateralTriangle.svg";
import pyramid from "../../assets/card-graphics/Pyramid.svg";
import rectangleHorizontal from "../../assets/card-graphics/RectangleHorizontal.svg";
import rectangleVertical from "../../assets/card-graphics/RectangleVertical.svg";
import rightAngleTriangle from "../../assets/card-graphics/RightAngleTriangle.svg";
import sphere from "../../assets/card-graphics/Sphere.svg";
import square from "../../assets/card-graphics/Square.svg";

export const shapes = [
    { name: "Area of a Circle", formula: "Math.PI * R * R", image: circle },
    { name: "Volume of a Cube", formula: "H * W * D", image: cube },
    { name: "Volume of a Cuboid", formula: "H * W * D", image: cuboid },
    { name: "Volume of a Cylinder", formula: "Math.PI * R * R * H", image: cylinder },
    { name: "Area of a Equilateral Triangle", formula: "(1/2) * B * H", image: equilateralTriangle },
    { name: "Volume of a Square Pyramid", formula: "(D * W * H) / 3", image: pyramid },
    { name: "Area of a Rectangle Horizontal", formula: "H * W", image: rectangleHorizontal },
    { name: "Area of a Rectangle Vertical", formula: "H * W", image: rectangleVertical },
    { name: "Area of a Right-angle Triangle", formula: "(B/2) * H", image: rightAngleTriangle },
    { name: "Volume of a Sphere", formula: "(4/3) * Math.PI * R * R * R", image: sphere },
    { name: "Area of a Square", formula: "L * L", image: square },
];