import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import { getUserData } from "../../Store/Actions/authAction";
import {
	registUserData,
	registUserDataWithImage,
} from "../../Store/Actions/onboardAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";
import isEmptyObject from "../../Custom/isEmptyObject";

import { idLabelData } from "../../constant";
import { workStatusData } from "../../constant";
import { industryData } from "../../constant";

import {
	options1,
	options2,
	options3,
	options4,
	options5,
} from "../../constant";

import PersonalDetailsForm from "./PersonalDetailsForm";
import IndustryDetailsForm from "./IndustryDetailsForm";
import GoalsAndObjectivesForm from "./GoalsAndObjectivesForm";
import ConfirmAndFinishForm from "./ConfirmAndFinishForm";
import SocialConnectForm from "./SocialConnectForm";
// import CongratulationForm from "./CongratulationForm";

import G2Button1 from "../Common/G2Button1";
import G2Button2 from "../Common/G2Button2";

import stepper1 from "../../assets/onboarding_page/stepper1.png";
import stepper2 from "../../assets/onboarding_page/stepper2.png";
import stepper3 from "../../assets/onboarding_page/stepper3.png";
import stepper4 from "../../assets/onboarding_page/stepper4.png";
import stepperA from "../../assets/onboarding_page/stepperA.png";
import stepperB from "../../assets/onboarding_page/stepperB.png";
import stepperC from "../../assets/onboarding_page/stepperC.png";
import stepperD from "../../assets/onboarding_page/stepperD.png";
import UTradieText from "../Common/UTradieText";
import { updateSetting2 } from "../../Store/Actions/settingAction";

const OnBoardingForm = () => {
	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();
	const user = useSelector((state) => state.Auth.user);
	const type = useSelector((state) => state.Auth.type);
	const [screenSize, setScreenSize] = useState(1366);
	const [activeStep, setActiveStep] = useState(0);
	const [avatarFile, setAvatarFile] = useState(null);
	const [avatarUrl, setAvatarUrl] = useState(null);

	const [state, setState] = useState({
		birthday: "",
		phone: "",
		gender: "Male",
		address: "",
		// street: "",
		// city: "",
		// province: "",
		// zipCode: "",
		// country: "",
		occupation: "",
		idLabel: idLabelData[0],
		companyName: "",
		siteURL: "",
		companyEmail: "",
		companyPhone: "",
		companyAddress: "",
		experience: 0,
		workStatus: workStatusData[0],

		industry: industryData[0],
		customIndustry: "",
		socialMedia: {}
	});

	const [selectedOptions1, setSelectedOptions1] = useState([]);
	const [selectedOptions2, setSelectedOptions2] = useState([]);
	const [selectedOptions3, setSelectedOptions3] = useState(0);
	const [selectedOptions4, setSelectedOptions4] = useState(0);
	const [selectedOptions5, setSelectedOptions5] = useState(0);

	const token = getToken();

	useEffect(() => {
		if (!isEmptyValue(type)) {
			localStorage.setItem("type", type);
		}

		const cond = localStorage.getItem("type");
		if (verifyToken()) {
			dispatch(getUserData(cond));
		}
	}, []);

	useEffect(() => {
		setScreenSize(window.innerWidth);
		window.addEventListener("resize", setScreenSize);

		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;

		setState((prevState) => ({
			...prevState,
			birthday: formattedDate,
		}));
	}, [window.innerWidth]);

	const setAddress = (value) => {
		setState((prevState) => ({
			...prevState,
			address: value,
		}));
	};

	const setComAddress = (value) => {
		setState((prevState) => ({
			...prevState,
			companyAddress: value,
		}));
	};

	const handleFieldChange1 = (e) => {
		setState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleFieldChange2 = (e) => {
		const value = e.target.value;
		setState((prevState) => ({
			...prevState,
			[e.target.name]: value,
		}));
	};

	const handleFieldChange3 = (num, value) => {
		if (num == 3) {
			setSelectedOptions3(value);
		} else if (num == 4) {
			setSelectedOptions4(value);
		} else {
			setSelectedOptions5(value);
		}
	};

	const handleFieldChange4 = (value) => {
		setState((prevState) => ({
			...prevState,
			experience: value,
		}));
	};

	const handleOptionChange = (num, optionId) => {
		if (num == 1) {
			if (selectedOptions1.includes(optionId)) {
				setSelectedOptions1(selectedOptions1.filter((id) => id != optionId));
			} else {
				setSelectedOptions1([...selectedOptions1, optionId]);
			}
		} else {
			if (selectedOptions2.includes(optionId)) {
				setSelectedOptions2(selectedOptions2.filter((id) => id != optionId));
			} else {
				setSelectedOptions2([...selectedOptions2, optionId]);
			}
		}
	};

	const onClickConfirm = async () => {
		const formData = new FormData();

		formData.append("file", avatarFile);

		const answer1 = selectedOptions1.join();
		const answer2 = selectedOptions2.join();
		const temp = {
			birthday: state.birthday,
			phone: state.phone,
			gender: state.gender,
			address: state.address,
		};

		const userData = {
			birthday: state.birthday,
			phone: state.phone,
			gender: state.gender,
			address: state.address,

			occupation: state.occupation,
			idLabel: state.idLabel,
			companyName: state.companyName,
			siteURL: state.siteURL,
			companyEmail: state.companyEmail,
			companyPhone: state.companyPhone,
			companyAddress: state.companyAddress,
			experience: state.experience,
			workStatus: state.workStatus,

			industry: state.industry != "Others" ? state.industry : state.customIndustry,

			answer1,
			answer2,
			answer3: selectedOptions3,
			answer4: selectedOptions4,
			answer5: selectedOptions5,

			socialMedia: state.socialMedia
		};

		const avairableComE = localStorage.getItem("avairableComE");

		formData.append("data", JSON.stringify(userData));

		if (type == "person") {
			if (!isEmptyObject(temp)) {
				if (avatarFile) {
					dispatch(
						registUserDataWithImage(toast, formData, activeStep, setActiveStep)
					);
				} else {
					dispatch(registUserData(toast, userData, activeStep, setActiveStep));
				}
			} else {
				g2Toast({
					toast,
					status: "error",
					title: "A several input fields must be filled out!",
				});
			}
		} else {
			if (avatarFile) {
				dispatch(
					registUserDataWithImage(toast, formData, activeStep, setActiveStep)
				);
			} else {
				dispatch(registUserData(toast, userData, activeStep, setActiveStep));
			}
		}
	};

	const onClickSkip = () => {
		navigate("/congratulation");
	};

	const onClickContinue = () => {
		dispatch(updateSetting2(toast, {
			name: user.name,
			gender: null,
			birthday: null,
			phone: null,
			address: null,
			socialMedia: state.socialMedia
		}));
		navigate("/congratulation");
	};

	const buttons = {
		next: <G2Button2 widthVal="180px" text="Next" onClick={() => setActiveStep(activeStep + 1)} />,
		prev: <G2Button1 widthVal="180px" text="Previous" onClick={() => setActiveStep(activeStep - 1)} />,
		confirm: <G2Button2 widthVal="180px" text="Confirm" onClick={onClickConfirm} />,
		skip: <G2Button1 widthVal="200px" text="Skip" onClick={onClickSkip} />,
		continue: <G2Button2 widthVal="200px" text="Continue" onClick={onClickContinue} />
	};

	const steps = [
		{
			name: "Personal Details",
			small: stepperA,
			large: stepper1,
			buttons: [
				buttons.next
			]
		},
		{
			name: "Industry Details",
			small: stepperB,
			large: stepper2,
			buttons: [
				buttons.prev,
				buttons.next
			]
		},
		{
			name: "Goals and Objectives",
			small: stepperC,
			large: stepper3,
			buttons: [
				buttons.prev,
				buttons.next
			]
		},
		{
			name: "Confirm",
			small: stepperD,
			large: stepper4,
			buttons: [
				buttons.prev,
				buttons.confirm
			]
		},
		{
			name: "Socials",
			hideHeading: true,
			buttons: [
				buttons.skip,
				buttons.continue
			]
		},
	];

	return (
		<Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			{steps[activeStep].hideHeading != true && <Box>
				<Box className="title-text mt-20px mb-10">
					Set up your account profile
				</Box>
				<Box w="100%">
					<Image className="stepper_img" src={steps[activeStep][screenSize < 1024 ? 'small' : 'large']} alt="stepper" />
				</Box>
			</Box>}

			<Box>
				<Tabs index={activeStep}>
					<TabPanels>
						<TabPanel>
							<PersonalDetailsForm user={user} avatarUrl={avatarUrl} setAvatarFile={setAvatarFile} setAvatarUrl={setAvatarUrl} data={state} handleFieldChange1={handleFieldChange1} handleFieldChange2={handleFieldChange2} handleFieldChange4={handleFieldChange4} setAddress={setAddress} setComAddress={setComAddress} />
						</TabPanel>
						<TabPanel>
							<IndustryDetailsForm data={state} handleFieldChange1={handleFieldChange1} handleFieldChange2={handleFieldChange2} />
						</TabPanel>
						<TabPanel>
							<GoalsAndObjectivesForm selectedOptions1={selectedOptions1} selectedOptions2={selectedOptions2} selectedOptions3={selectedOptions3} selectedOptions4={selectedOptions4} selectedOptions5={selectedOptions5} setSelectedOptions3={setSelectedOptions3} setSelectedOptions4={setSelectedOptions4} setSelectedOptions5={setSelectedOptions5} handleFieldChange3={handleFieldChange3} handleOptionChange={handleOptionChange} />
						</TabPanel>
						<TabPanel>
							<ConfirmAndFinishForm user={user} data={state} selectedOptions1={selectedOptions1} selectedOptions2={selectedOptions2} selectedOptions3={selectedOptions3} selectedOptions4={selectedOptions4} selectedOptions5={selectedOptions5} setActiveStep={setActiveStep} />
						</TabPanel>
						<TabPanel>
							<Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
								<Box className="title-text" style={{ marginTop: "50px" }}>
									Sync your social media account
								</Box>
								<Box className="title-text">
									with <UTradieText />
								</Box>
								<Text className="text-center mt-10">
									So, that you can get more relevant recommendations!
								</Text>
								{
									//JSON.stringify(state)
								}
								<SocialConnectForm userData={state} setUserData={setState} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Box className=" mt-10 mb-5">
				<Box className="flex justify-center flex-wrap">
					{steps[activeStep].buttons.map((r, i) => <Box key={i}>{r}</Box>)}
				</Box>
			</Box>
		</Box>
	);
};

export default OnBoardingForm;
