import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  LockIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import { createJob } from "../../Store/Actions/academyAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// import AddModal from "./AddModal";

import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";

import event from "../../assets/academy/event.png";

const areas = [
  "Mental health support",
  "Physical health services",
  "Community health programs",
  "Substance abuse and addiction services",
  "Disability support",
  "Nutrition & food assistance",
  "Education & awareness",
  "Domestic violence support",
  "Crisis intervention services",
  "Finance support",
  "Law services",
];

const workType = ["Full-time", "Part-time"];

const JobCreateForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const fileInputRef = useRef(null);

  const [logoFile, setLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("Mental health support");
  const [type, setType] = useState("Full-time");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [expire, setExpire] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");

  const token = getToken();

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setExpire(formattedDate);
  }, []);

  const onClickFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (event.target.files.length == 0) {
      return;
    }

    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = "." + selectedFile.name.split(".").pop();

    if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
      g2Toast({ toast, status: "error", title: "Invalid file type!" });
      return;
    }

    setLogoFile(selectedFile);
    setLogoUrl(URL.createObjectURL(selectedFile));
  };

  const handleLogoDelete = () => {
    setLogoUrl(null);
    setLogoFile(null);
  };

  const handleBackClick = () => {
    navigate("/academy/job/edit");
  };

  const handleCreateJobClick = () => {
    if (!isEmptyValue(title)) {
      const formData = new FormData();

      formData.append("file", logoFile);

      const data = {
        companyName,
        companyWebsite,
        title,
        category,
        type,
        location,
        salary,
        experience,
        qualification,
        expire,
        description,
        email,
      };

      formData.append("data", JSON.stringify(data));

      dispatch(createJob(toast, navigate, formData));
    } else {
      g2Toast({
        toast,
        status: "error",
        title: "Job Title must be inputed!",
      });
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex centering-h px-[30px] shadow relative"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Jobs Boards
        </Text>
        <Box className="absolute right-[30px]">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Back"
            onClick={handleBackClick}
          />
        </Box>
      </Box>
      <Box w="100%" px="20px" py="20px" className="flex-box">
        <Text
          w="100%"
          fontSize="20px"
          className="mt-[10px] px-[10px] font-semibold text-center"
        >
          Post a Job
        </Text>
        <Box w="100%" className="px-[10px]">
          {logoUrl == null ? (
            <Box w="100%">
              <G2Button23
                widthVal="150px"
                heightVal="35px"
                text="Upload Logo"
                onClick={onClickFileInput}
              />
              <Input
                type="file"
                ref={fileInputRef}
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Box>
          ) : (
            <Box w="100%" h="200px" className="relative">
              <Image boxSize="200px" src={logoUrl} alt="uploadedImage" />
              <IconButton
                position="absolute"
                top="100px"
                left="100px"
                transform="translate(-50%, -50%)"
                variant="outline"
                isRound={true}
                color="black"
                fontSize="15px"
                icon={<DeleteIcon />}
                onClick={handleLogoDelete}
              />
            </Box>
          )}
        </Box>
        <Box w={boxW1} className="mt-[20px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Company Name
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Box>
        <Box w={boxW1} className="mt-[20px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Company Website
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={companyWebsite}
            onChange={(e) => setCompanyWebsite(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Job Title
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box w={boxW1} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Job Category
          </Text>
          <Select
            h="35px"
            className="centering-h"
            style={{
              background: `${G2_color_2}`,
              color: `${G2_color_3}`,
              borderRadius: "2.5px",
            }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {areas.map((val, i) => (
              <option
                key={i}
                value={val}
                style={{
                  background: `${G2_color_2}`,
                  color: `${G2_color_3}`,
                }}
              >
                {val}
              </option>
            ))}
          </Select>
        </Box>
        <Box w={boxW1} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Job Type
          </Text>
          <Select
            h="35px"
            className="centering-h"
            style={{
              background: `${G2_color_2}`,
              color: `${G2_color_3}`,
              borderRadius: "2.5px",
            }}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            {workType.map((val, i) => (
              <option
                key={i}
                value={val}
                style={{
                  background: `${G2_color_2}`,
                  color: `${G2_color_3}`,
                }}
              >
                {val}
              </option>
            ))}
          </Select>
        </Box>
        <Box w={boxW1} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Job Location
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Box>
        <Box w={boxW1} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Salary Range
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
          />
        </Box>
        <Box w={boxW2} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Experience
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          />
        </Box>
        <Box w={boxW2} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Qualification
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={qualification}
            onChange={(e) => setQualification(e.target.value)}
          />
        </Box>
        <Box w={boxW2} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Application Close
          </Text>
          <Input
            w="100%"
            h="35px"
            style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            type="date"
            borderRadius="2.5px"
            value={expire}
            onChange={(e) => setExpire(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Job Description
          </Text>
          <Textarea
            w="100%"
            bg={G2_color_2}
            color={G2_color_3}
            borderRadius="2.5px"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Email application to
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[20px] mb-20 px-[10px] flex justify-end">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Post"
            onClick={handleCreateJobClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default JobCreateForm;
