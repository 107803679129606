const initialState = {
    contentList: [],
    content: {},
    unitList: [],
    unit: {},
    cardList: [],
    quiz: [],
    currentCard: {},

    groupList: [],
    group: {},
    memberList: [],
    member: {},
    activityList: [],
    activity: {}
};
  
const createReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CREATE_GET_CONTENTLIST":
            return {
            ...state,
            contentList: action.payload
            };
        case "CREATE_GET_CONTENT":
            return {
            ...state,
            content: action.payload
            };
        case "CREATE_SET_CONTENT":
            return {
            ...state,
            content: action.payload
            };
        case "CREATE_GET_UNITLIST":
            return {
            ...state,
            unitList: action.payload
            };
        case "CREATE_GET_CARDLIST":
            return {
            ...state,
            cardList: action.payload
            };
        case "CREATE_SET_UNIT":
            return {
            ...state,
            unit: action.payload
            };
        case "CREATE_MAKE_QUIZ":
            return {
            ...state,
            quiz: action.payload
            };
        case "CREATE_SET_CURRENTCARD":
            return {
            ...state,
            currentCard: action.payload
            };

        case "CREATE_GET_GROUPLIST":
            return {
            ...state,
            groupList: action.payload
            };
        case "CREATE_GET_GROUP":
            return {
            ...state,
            group: action.payload
            };
        case "CREATE_SET_GROUP":
            return {
            ...state,
            group: action.payload
            };
        case "CREATE_GET_MEMBERLIST":
            return {
            ...state,
            memberList: action.payload
            };
        case "CREATE_GET_MEMBER":
            return {
            ...state,
            member: action.payload
            };
        case "CREATE_GET_ACTIVITYLIST":
            return {
            ...state,
            activityList: action.payload
            };
        case "CREATE_GET_ACTIVITY":
            return {
            ...state,
            activity: action.payload
            }; 
        default:
            return state;
    }
}

export default createReducer;