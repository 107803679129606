import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);

  const [G2_theme_mode, setG2_theme_mode] = useState("light");
  const [G2_color_1, setG2_color_1] = useState("#efefef");
  const [G2_color_2, setG2_color_2] = useState("#ffffff");
  const [G2_color_3, setG2_color_3] = useState("#000000");

  const setMode = () => {
    if (G2_theme_mode == "light") {
      setG2_theme_mode("dark");
      setG2_color_1("#1e1e26");
      setG2_color_2("#272a3d");
      setG2_color_3("#efefef");
    } else {
      setG2_theme_mode("light");
      setG2_color_1("#efefef");
      setG2_color_2("#ffffff");
      setG2_color_3("#000000");
    }
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  return (
    <StateContext.Provider
      value={{
        currentColor,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,

        G2_theme_mode,
        G2_color_1,
        G2_color_2,
        G2_color_3,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
