import { combineReducers } from "redux";

import authReducer from "./authReducer";
import onboardReducer from "./onboardReducer";
import dashboardReducer from "./dashboardReducer";
import goalReducer from "./goalReducer";
import createReducer from "./createReducer";
import learnReducer from "./learnReducer";
import marketplaceReducer from "./marketplaceReducer";
import lifeReducer from "./lifeReducer";
import academyReducer from "./academyReducer";
import otherReducer from "./otherReducer";
import chatReducer from "./chatReducer";

const reducers = combineReducers({
  Auth: authReducer,
  Onboard: onboardReducer,
  Dashboard: dashboardReducer,
  Goal: goalReducer,
  Create: createReducer,
  Learn: learnReducer,
  Marketplace: marketplaceReducer,
  Life: lifeReducer,
  Academy: academyReducer,
  Other: otherReducer,
  Chat: chatReducer,
});

export default reducers;
