import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  LockIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import { updateEvent1, updateEvent2 } from "../../Store/Actions/academyAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// import AddModal from "./AddModal";

import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";

import event from "../../assets/academy/event.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const areas = [
  "Mental health support",
  "Physical health services",
  "Community health programs",
  "Substance abuse and addiction services",
  "Disability support",
  "Nutrition & food assistance",
  "Education & awareness",
  "Domestic violence support",
  "Crisis intervention services",
  "Finance support",
  "Law services",
];

const EventUpdateForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const event = useSelector((state) => state.Academy.event);

  const fileInputRef = useRef(null);

  const [photoFile, setPhotoFile] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);

  const [name, setName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("Mental health support");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");

  const [delPhoto, setDelPhoto] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (isEmptyValue(event?._id)) {
      navigate("/academy/event");
    }

    setPhotoUrl(event.photo);
    setName(event.name);
    setCompanyWebsite(event.companyWebsite);
    setLocation(event.location);
    setCategory(event.category);
    setDate(event.date);
    setTime(event.time);
    setDescription(event.description);
    setEmail(event.email);
  }, [event]);

  const onClickFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (event.target.files.length == 0) {
      return;
    }

    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = "." + selectedFile.name.split(".").pop();

    if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
      g2Toast({ toast, status: "error", title: "Invalid file type!" });
      return;
    }

    setPhotoFile(selectedFile);
    setPhotoUrl(URL.createObjectURL(selectedFile));
  };

  const handlePhotoDelete = () => {
    setPhotoUrl(null);
    setPhotoFile(null);

    setDelPhoto(true);
  };

  const handleBackClick = () => {
    navigate("/academy/event");
  };

  const handleUpdateEventClick = () => {
    if (!isEmptyValue(name)) {
      const formData = new FormData();

      formData.append("file", photoFile);

      const data = {
        name,
        companyWebsite,
        location,
        category,
        date,
        time,
        description,
        email,
      };

      formData.append("data", JSON.stringify(data));

      if (photoFile) {
        dispatch(updateEvent1(toast, navigate, event._id, formData));
      } else {
        dispatch(updateEvent2(toast, navigate, event._id, data));
      }
    } else {
      g2Toast({
        toast,
        status: "error",
        title: "Event Name must be inputed!",
      });
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex centering-h px-[30px] shadow relative"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Events Boards
        </Text>
        <Box className="absolute right-[30px]">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Back"
            onClick={handleBackClick}
          />
        </Box>
      </Box>
      <Box w="100%" px="20px" py="20px" className="flex-box">
        <Text
          w="100%"
          fontSize="20px"
          className="mt-[10px] px-[10px] font-semibold text-center"
        >
          Update a Event
        </Text>
        <Box w="100%" className="px-[10px]">
          {!delPhoto && !isEmptyValue(photoUrl) ? (
            <Box w="300px" h="200px" className="relative">
              <Image
                boxSize="100%"
                src={getFileUrl(photoUrl)}
                alt="uploadedImage"
              />
              <IconButton
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                variant="outline"
                isRound={true}
                color="black"
                fontSize="15px"
                icon={<DeleteIcon />}
                onClick={handlePhotoDelete}
              />
            </Box>
          ) : (
            <Box w="100%">
              {photoUrl == null ? (
                <Box w="100%">
                  <G2Button23
                    widthVal="150px"
                    heightVal="35px"
                    text="Upload Logo"
                    onClick={onClickFileInput}
                  />
                  <Input
                    type="file"
                    ref={fileInputRef}
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Box>
              ) : (
                <Box w="300px" h="200px" className="relative">
                  <Image boxSize="100%" src={photoUrl} alt="uploadedImage" />
                  <IconButton
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    variant="outline"
                    isRound={true}
                    color="black"
                    fontSize="15px"
                    icon={<DeleteIcon />}
                    onClick={handlePhotoDelete}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box w={boxW1} className="mt-[20px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Name of Event
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box w={boxW1} className="mt-[20px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Company Website
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={companyWebsite}
            onChange={(e) => setCompanyWebsite(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Event Location
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Box>
        <Box w={boxW1} className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Job Category
          </Text>
          <Select
            h="35px"
            className="centering-h"
            style={{
              background: `${G2_color_2}`,
              color: `${G2_color_3}`,
              borderRadius: "2.5px",
            }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {areas.map((val, i) => (
              <option
                key={i}
                value={val}
                style={{
                  background: `${G2_color_2}`,
                  color: `${G2_color_3}`,
                }}
              >
                {val}
              </option>
            ))}
          </Select>
        </Box>
        <Box w={boxW1} className="mt-[10px] px-[10px]">
          <Box w="100%" className="flex-box">
            <Box w={boxW1} className="pr-[5px]">
              <Text w="100%" fontSize="16px" className="mb-1">
                Event Date
              </Text>
              <Input
                w="100%"
                h="35px"
                style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
                type="date"
                borderRadius="2.5px"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Box>
            <Box w={boxW1} className="pl-[5px]">
              <Text w="100%" fontSize="16px" className="mb-1">
                Event Time
              </Text>
              <Input
                w="100%"
                h="35px"
                style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
                type="time"
                borderRadius="2.5px"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
        <Box w="100%" className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Event Description
          </Text>
          <Textarea
            w="100%"
            bg={G2_color_2}
            color={G2_color_3}
            borderRadius="2.5px"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[10px] px-[10px]">
          <Text w="100%" fontSize="16px" className="mb-1">
            Email RSVP to
          </Text>
          <Input
            w="100%"
            h="35px"
            size="md"
            borderRadius="2.5px"
            bg={G2_color_2}
            color={G2_color_3}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box w="100%" className="mt-[20px] px-[10px] flex justify-end">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Update"
            onClick={handleUpdateEventClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EventUpdateForm;
