const initialState = {
  userData: {},
  systemContent: [],
  userTypes: [],
  subscriptions: [],
  availableSubscriptions: [],
  childUsers: [],
  parentUsers: [],
};

const onboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ONBOARD_GET_USERDATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "SYSTEM_GET_CONTENT":
      return {
        ...state,
        systemContent: action.payload,
      };
    case "SYSTEM_USER_TYPES":
      return {
        ...state,
        userTypes: action.payload,
      };
    case "SYSTEM_AVAILABLE_SUBSCRIPTIONS":
      return {
        ...state,
        availableSubscriptions: action.payload,
      };
    case "USER_SUBSCRIPTIONS":
      return {
        ...state,
        subscriptions: action.payload,
      };
    case "CHILD_USERS":
      return {
        ...state,
        childUsers: action.payload,
      };
    case "PARENT_USERS":
      return {
        ...state,
        parentUsers: action.payload,
      };
    default:
      return state;
  }
};

export default onboardReducer;
