import React from "react";
import { getFileUrl, isFile } from "../helpers/general";
import _ from "lodash";
import { Image, Text } from "@chakra-ui/react";

const DynamicFileViewer = ({ file, ...rest }) => {
    if (isFile(file)) {
        if (file.type.match(/application\/pdf/)) {
            return <embed {...rest} width="100%" height="auto" src={URL.createObjectURL(file)} />
        } else if (file.type.match(/application\/msword/) || file.type.match(/application\/vnd.openxmlformats-officedocument.wordprocessingml.document/)) {
            return <iframe  {...rest} src={'https://view.officeapps.live.com/op/embed.aspx?src=' + URL.createObjectURL(file)} width="1024" height="768" frameborder='0'></iframe>
        } else if (file.type.match(/image/)) {
            return <Image  {...rest} width="100%" height="auto" src={URL.createObjectURL(file)} alt="uploadedImage" />;
        } else if (file.type.match(/video/)) {
            return <video  {...rest} controls width="100%" height="auto" src={URL.createObjectURL(file)} />
        } else {
            return <Text>{file.name}</Text>
        }
    }

    if (_.isString(file)) {
        if (file.match(/(\.pdf)$/i)) {
            return <embed  {...rest} width="100%" height="auto" src={getFileUrl(file)} />

        } else if (file.match(/(\.doc)$/i)) {
            return <iframe  {...rest} src={'https://view.officeapps.live.com/op/embed.aspx?src=' + getFileUrl(file)} width="100%" height="auto" frameborder='0'></iframe>

        } else if (file.match(/((.png)|(.jpg))$/i)) {
            return <Image  {...rest} width="100%" height="auto" src={getFileUrl(file)} alt={file} />;
        } else if (file.match(/((.mp4)|(.mpg)|(.avi)|(.3gp))$/i)) {
            return <video  {...rest} controls width="100%" height="auto" src={getFileUrl(file)} />
        } else {
            return <Text>Unknown</Text>
        }
    }

    return <Text>Unknown</Text>;
}

export default DynamicFileViewer;