import React, { useState } from "react";
import { WarningTwoIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";
import isEmptyValue from "../../Custom/isEmptyValue";

const G2EmailInput = ({ label, name, type, value, onChange }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const [isValidEmail, setValidEmail] = useState(true);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(inputEmail));

    if (emailRegex.test(inputEmail)) {
      localStorage.setItem("avairableComE", "yes");
    } else {
      localStorage.setItem("avairableComE", "no");
    }
  };

  const handleInputFocus = () => {
    setValidEmail(true);
  };

  const handleEmailBlur = (e) => {
    const inputValue = e.target.value;

    if (isEmptyValue(inputValue)) {
      setValidEmail(true);
    } else {
      validateEmail(inputValue);
    }
  };

  return (
    <div className="mt-3" style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <label
          htmlFor="first-name"
          style={{ color: `${G2_color_3}` }}
          className="block text-sm font-semibold leading-6"
        >
          {label}
        </label>
        <div className="mt-1 relative">
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={handleInputFocus}
            onBlur={handleEmailBlur}
            id={name}
            style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
          />
          {!isValidEmail ? (
            <WarningTwoIcon
              boxSize="20px"
              color="red.500"
              className="absolute top-[10px] right-[10px]"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default G2EmailInput;
