import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Box,
	Text,
	Image,
	useToast,
	Input,
	AspectRatio,
	HStack,
	useDisclosure,
	UnorderedList,
	ListItem,
	IconButton,
	Avatar,
} from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import {
	getInvitation,
	uploadActivityPic,
	addActivity,
} from "../../Store/Actions/learnAction";
import { getToken, verifyToken } from "../../utils";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";

import InvitationModal from "./InvitationModal";
import G2Button20 from "../Common/G2Button20";
import notification1 from "../../assets/learn/notification1.png";
import notification2 from "../../assets/learn/notification2.png";
import upload1 from "../../assets/learn/upload1.png";
import upload2 from "../../assets/learn/upload2.png";
import { getServerUrl } from "../../helpers/location";
import QuizCard from "../Quiz/QuizCard";
import { getFileUrl } from "../../helpers/general";
import { ArrowBackIcon } from "@chakra-ui/icons";

const MainCardForm = ({ screenSize, boxW1, boxW2 }) => {
	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const toast = useToast();

	const fileInputRef = useRef(null);

	const invitation = useSelector((state) => state.Learn.invitation);
	const group = useSelector((state) => state.Learn.group);
	const card = useSelector((state) => state.Learn.card);
	const [isAIopen, setIsAIOpen] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [uploadShow, setUploadShow] = useState(true);
	const [quizResults, setQuizResults] = useState(null);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const token = getToken();

	useEffect(() => {
		if (verifyToken()) {
			dispatch(getInvitation(toast));
		}

		if (isEmptyValue(card._id)) {
			navigate("/learn");
		}
	}, []);

	const onClickFileInput = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];

		if (event.target.files.length == 0) {
			return;
		}

		const allowedFileTypes = [".png", ".jpg", ".jpeg"];
		const fileExtension = "." + selectedFile.name.split(".").pop();

		if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
			g2Toast({ toast, status: "error", title: "Invalid file type!" });
			return;
		}

		const formData = new FormData();

		formData.append("file", selectedFile);

		formData.append("data", JSON.stringify(card._id));

		dispatch(uploadActivityPic(toast, formData, setUploadShow));
	};

	const openInvitationModal = () => {
		setIsAIOpen(true);
	};

	const closeInvitationModal = () => {
		setIsAIOpen(false);
	};

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleNextClick = () => {
		dispatch(addActivity(toast, navigate, group._id, card._id, null, 1));
	};

	const handleQuizSubmit = (quizResults, isCompleted) => {
		setQuizResults(quizResults);
		dispatch(addActivity(toast, navigate, group._id, card._id, quizResults, null));
		if (isCompleted) {
			onClose();
		}
	};

	const hasQuestions = useMemo(() => {
		return card?.quiz?.questions?.length > 0;
	}, [card]);

	const needsQuizAnswered = useMemo(() => {
		const hasAnswers = card?.activity?.activeCards[card._id]?.quizResults?.length > 0;
		return hasQuestions && !hasAnswers;
	}, [card, quizResults, hasQuestions]);

	const needsTaskEvidenceAnswered = useMemo(() => {
		const hasPracticalTask = card?.cardTask != "";
		const hasUploadedTaskEvidence = card?.activity?.activeCards[card._id]?.tasks?.length > 0;
		return hasPracticalTask && !hasUploadedTaskEvidence;
	}, [card, quizResults]);

	const isReadyToFinish = useMemo(() => {
		return !needsQuizAnswered && !needsTaskEvidenceAnswered
	}, [needsQuizAnswered, needsTaskEvidenceAnswered]);

	return (
		<Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			<Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h shadow">
				<Box w="100%" className="flex-box">
					<Box p="3" className="text-left centering-h" w="100%">
						<IconButton isRound={true} className="normal-hover mx-[10px]" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label="Done" fontSize="20px" icon={<ArrowBackIcon />} onClick={() => navigate("/learn/content/unit")} />
						<Avatar size="md" src={getFileUrl(group?.userId?.avatar)} alt="icon" />
						{!isEmptyValue(group._id) && <Box w="100%">
							<Text fontSize="16px" className="font-semibold mx-[10px]">
								{card?.cardName}
							</Text>
							<Text fontSize="14px" className="mx-[10px]">
								{card?.unitTitle}, <i>{group.groupId.title}</i>
							</Text>
						</Box>}

						<Box w="70px" className="centering relative">
							<Box className="normal-hover invite-notification" onClick={openInvitationModal}>
								<Image width="30px" height="35px" src={isEmptyValue(invitation[0]?._id) ? notification1 : notification2} alt="notification" />
								{invitation?.length != 0 && <Box w="15px" h="15px" className="absolute top-[2px] right-[22px] centering">
									<Text fontSize="12px" className="text-white">
										{invitation?.length}
									</Text>
								</Box>}
							</Box>
							<InvitationModal isAIopen={isAIopen} closeInvitationModal={closeInvitationModal} />
						</Box>
					</Box>
				</Box>
			</Box>
			{card.video && (
				<Box w="100%" bg="#222222" className="centering-w learn-card-box">
					<AspectRatio w="100%" ratio={16 / 9}>
						<video controls width="100%" height="auto" src={getFileUrl(card.video)} />
					</AspectRatio>
				</Box>
			)}
			{card.audio && (
				<Box w="100%" className="learn-card-box mt-[20px]">
					<Box w="100%" bg={G2_color_2} className="p-[20px] rounded-[2.5px] shadow"          >
						<audio controls autoplay className="w-full" src={getFileUrl(card.audio)} />
					</Box>
				</Box>
			)}
			<Box w="100%" className="learn-card-box my-[30px]">
				{card.cardContent && (
					<Box w="100%" className="mb-[30px]">
						<Text w="100%" fontSize="16px" className="font-semibold text-[var(--orange)] mt-[30px] mb-[10px]">
							Content
						</Text>
						{
							//JSON.stringify(card.cardContent)
						}
						<Text w="100%" fontSize="16px" dangerouslySetInnerHTML={{ __html: card.cardContent }} />
					</Box>
				)}

				{
					//JSON.stringify(card)
				}
				{card?.attachments && card?.attachments?.length > 0 && (<>
					<Box w="100%">
						<Box w="100%" className="mb-[10px] flex centering-h">
							<Text fontSize="16px" className="font-semibold text-[#d86655] mr-[20px]">
								Attachments
							</Text>
						</Box>
					</Box>
					<UnorderedList w="100%" mb="15px">
						{(card?.attachments ?? []).map((r, i) => <ListItem key={i}>
							<HStack w="100%">
								<Text><a target="_blank" href={getFileUrl(r.file)}>{r.name}</a></Text>
							</HStack>
						</ListItem>)}
					</UnorderedList>
				</>
				)}

				{
					//JSON.stringify(card?.activity?.activeCards[card._id]?.tasks)
				}
				{card.cardTask && (<>
					<Box w="100%">
						<Box w="100%" className="mb-[10px] flex centering-h">
							<Text fontSize="16px" className="font-semibold text-[#d86655] mr-[20px]">
								Practical Task
							</Text>
						</Box>
						<Text w="100%" fontSize="16px" dangerouslySetInnerHTML={{ __html: card.cardTask }} className="mb-[30px]" />
					</Box>
					<HStack w="100%" pb="15px" wrap="wrap">
						{card?.activity?.activeCards[card._id]?.tasks?.map((r, i) => <Box key={i} w="30%">
							<Image src={getFileUrl(r.attachment)} />
							{r.outcome.name != null && <Text w="100%">{r.outcome.accepted != null ? "Accepted" : "Rejected"} by {r.outcome.name}</Text>}
							{
								//JSON.stringify(r)
							}
						</Box>)}

					</HStack>
				</>
				)}



				{
					//JSON.stringify(card?.activity?.activeCards[card._id]?.quizResults?.length)
				}
				{
					//JSON.stringify(card?.activity?.activeCards[card._id]?.tasks?.length)
				}
				{hasQuestions && <Box w="100%" pb="15px">
					<Box w="100%">
						<Box w="100%" className="mb-[10px] flex centering-h">
							<Text fontSize="16px" className="font-semibold text-[#d86655] mr-[20px]">
								Quiz
							</Text>
						</Box>
					</Box>
					{!needsQuizAnswered && <Text>Answered</Text>}
					<QuizCard isOpen={isOpen} onClose={onClose} quiz={card.quiz} completedCallback={handleQuizSubmit} />
				</Box>}

				{
					//<Image height="25px" src={isHovered ? upload2 : upload1} alt="upload" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClickFileInput} />
				}


				{card.cardTask != '' && <>
					<G2Button20 mb="15px" widthVal="100%" heightVal="40px" text={card?.activity?.activeCards[card._id]?.tasks?.length > 0 ? "Upload additional Evidence" : "Upload task evidence"} onClick={onClickFileInput} />
					<Input type="file" ref={fileInputRef} accept=".png, .jpg, .jpeg" onChange={handleFileChange} style={{ display: "none" }} />
				</>}

				{needsQuizAnswered && !isOpen && <G2Button20 widthVal="100%" heightVal="40px" text="Take quiz" onClick={onOpen} />}
				{isReadyToFinish == true && !isOpen && <G2Button20 widthVal="100%" heightVal="40px" text="Next card" onClick={handleNextClick} />}
			</Box>
		</Box>
	);
};

export default MainCardForm;
