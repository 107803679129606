import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { signUpUser } from "../../Store/Actions/authAction";
import g2Toast from "../../Custom/g2Toast";

const industryOptions = {
  "Agriculture and Farming": ["Farmer", "Agricultural Engineer"],
  "Construction and Building": [
    "Electrician",
    "Plumber",
    "Builder",
    "Carpenter",
    "Painter",
  ],
  "Mechanical Trades": ["Mechanic", "Technician"],
  "Electrical Trades": ["Electrician", "Lineworker"],
  "Culinary Trades": ["Chef", "Baker"],
  "Fabrication and Manufacturing": ["Welder", "Machinist"],
};

const PersonalForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const toast = useToast();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    phoneNumber: "",
    dateOfBirth: "",
    isEmployed: "",
    employerName: "",
    industry: "",
    trade: "",
    experienceYears: "",
    employmentStatus: "",
    joinReasons: [],
    tradeGoals: [],
    createContent: "",
    accessResources: "",
    notifications: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (name, values) => {
    setFormData((prev) => ({ ...prev, [name]: values }));
  };

  const validateStep = () => {
    if (step === 1) {
      // Step 1: Personal Information Validation
      if (
        !formData.fullName ||
        !formData.email ||
        !formData.password ||
        !formData.confirmPassword ||
        !formData.address ||
        !formData.phoneNumber
      ) {
        g2Toast({ toast, status: "error", title: "Missing required input!" });
        return false;
      }
      if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
        g2Toast({ toast, status: "error", title: "Invalid Email address!" });
        return false;
      }

      if (formData.password !== formData.confirmPassword) {
        g2Toast({ toast, status: "error", title: "Passwords do not match!" });
        return;
      }
    } else if (step === 2) {
      // Step 2: Trade and Industry Validation
      if (!formData.industry) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select an industry!",
        });
        return false;
      }
      if (!formData.trade) {
        g2Toast({ toast, status: "error", title: "Please select a trade!" });
        return false;
      }
    } else if (step === 3) {
      // Step 3: Work Information and Goals Validation
      if (!formData.experienceYears || !formData.employmentStatus) {
        g2Toast({
          toast,
          status: "error",
          title: "Please complete your work information!",
        });
        return false;
      }
      if (
        formData.joinReasons.length === 0 ||
        formData.tradeGoals.length === 0
      ) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select your reasons and goals!",
        });
        return false;
      }
    } else if (step === 4) {
      // Step 4: Tools and Learning Resources Validation
      if (formData.createContent === "") {
        g2Toast({
          toast,
          status: "error",
          title: "Please select content creation preference!",
        });
        return false;
      }
      if (formData.accessResources === "") {
        g2Toast({
          toast,
          status: "error",
          title: "Please select resource access preference!",
        });
        return false;
      }
    } else if (step === 5) {
      if (formData.notifications.length === 0) {
        g2Toast({
          toast,
          status: "error",
          title: "Please select at least one notification preference!",
        });
        return false;
      }
    }
    return true;
  };

  const nextStep = () => {
    if (validateStep()) setStep((prev) => prev + 1);
  };

  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = () => {
    const data = { ...formData, type: "Personal", userTypeId: 1 };
    dispatch(signUpUser(toast, navigate, data));
  };

  return (
    <>
      {/* Step 1: Personal Information */}
      {step === 1 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 1: Personal Information
          </Text>
          <FormControl id="fullName" isRequired>
            <FormLabel>Full Name</FormLabel>
            <Input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="confirmPassword" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="address" isRequired>
            <FormLabel>Address</FormLabel>
            <Input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="phoneNumber" isRequired>
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="dateOfBirth">
            <FormLabel>Date of Birth</FormLabel>
            <Input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="isEmployed" isRequired>
            <FormLabel>Are You Employed?</FormLabel>
            <RadioGroup
              name="isEmployed"
              value={formData.isEmployed}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, isEmployed: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          {formData.isEmployed === "Yes" && (
            <FormControl id="employerName">
              <FormLabel>Employer Name</FormLabel>
              <Input
                type="text"
                name="employerName"
                value={formData.employerName}
                onChange={handleInputChange}
              />
            </FormControl>
          )}
          <Box className="w-full flex items-end justify-end">
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 2: Select Trade and Industry */}
      {step === 2 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 2: Select Trade and Industry
          </Text>
          <FormControl id="industry" isRequired>
            <FormLabel>Select Industry</FormLabel>
            <Select
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
            >
              <option value="">Choose Industry</option>
              {Object.keys(industryOptions).map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </Select>
          </FormControl>
          {formData.industry && (
            <FormControl id="trade" isRequired>
              <FormLabel>Select Specific Trade</FormLabel>
              <Select
                name="trade"
                value={formData.trade}
                onChange={handleInputChange}
              >
                <option value="">Choose Trade</option>
                {industryOptions[formData.industry].map((trade) => (
                  <option key={trade} value={trade}>
                    {trade}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 3: Work Information and Goals */}
      {step === 3 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Work Information and Goals
          </Text>
          <FormControl id="experienceYears" isRequired>
            <FormLabel>Years of Experience</FormLabel>
            <Select
              name="experienceYears"
              value={formData.experienceYears}
              onChange={handleInputChange}
            >
              <option value="">Select Experience</option>
              <option value="1-4">1-4 years (Junior)</option>
              <option value="5-20">5-20 years (Experienced)</option>
              <option value="20+">20+ years (Master)</option>
            </Select>
          </FormControl>
          <FormControl id="employmentStatus" isRequired>
            <FormLabel>Current Employment Status</FormLabel>
            <Select
              name="employmentStatus"
              value={formData.employmentStatus}
              onChange={handleInputChange}
            >
              <option value="">Select Employment Status</option>
              <option value="Full-time">Employed (Full-time)</option>
              <option value="Part-time">Employed (Part-time)</option>
              <option value="Contractor">Self-employed/Contractor</option>
              <option value="Apprentice">Apprentice</option>
              <option value="Unemployed">Unemployed</option>
            </Select>
          </FormControl>
          <FormControl id="joinReasons" isRequired>
            <FormLabel>Why Did You Join UTRADIE?</FormLabel>
            <CheckboxGroup
              value={formData.joinReasons}
              onChange={(values) => handleCheckboxChange("joinReasons", values)}
            >
              <Stack direction="column">
                <Checkbox value="skills">
                  To improve my skills and knowledge
                </Checkbox>
                <Checkbox value="network">
                  To connect with other professionals
                </Checkbox>
                <Checkbox value="jobs">To find job opportunities</Checkbox>
                <Checkbox value="business">
                  To start or grow my business
                </Checkbox>
                <Checkbox value="creator">
                  To share my knowledge and become a creator
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <FormControl id="tradeGoals" isRequired>
            <FormLabel>Your Trade Goals</FormLabel>
            <CheckboxGroup
              value={formData.tradeGoals}
              onChange={(values) => handleCheckboxChange("tradeGoals", values)}
            >
              <Stack direction="column">
                <Checkbox value="certifications">
                  Gain new certifications
                </Checkbox>
                <Checkbox value="mentors">
                  Connect with mentors or apprentices
                </Checkbox>
                <Checkbox value="monetize">Monetize my knowledge</Checkbox>
                <Checkbox value="trends">Keep up with industry trends</Checkbox>
                <Checkbox value="network">
                  Grow my professional network
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 4: Tools and Learning Resources */}
      {step === 4 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 4: Tools and Learning Resources
          </Text>
          <FormControl id="createContent" isRequired>
            <FormLabel>
              Would You Like to Create and Sell Educational Content on UTRADIE?
            </FormLabel>
            <RadioGroup
              name="createContent"
              value={formData.createContent}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, createContent: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl id="accessResources" isRequired>
            <FormLabel>
              Would You Like to Access Learning Resources Now?
            </FormLabel>
            <RadioGroup
              name="accessResources"
              value={formData.accessResources}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, accessResources: value }))
              }
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button colorScheme="teal" onClick={nextStep}>
              Next
            </Button>
          </Box>
        </VStack>
      )}

      {/* Step 5: Confirmation and Customization */}
      {step === 5 && (
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Step 5: Confirmation and Customization
          </Text>
          <Text>Review Your Details:</Text>
          <Text>Full Name: {formData.fullName}</Text>
          <Text>Email: {formData.email}</Text>
          <Text>Phone Number: {formData.phoneNumber}</Text>
          <Text>Trade: {formData.trade}</Text>
          <Text>Experience: {formData.experienceYears}</Text>
          <FormControl id="notifications" isRequired>
            <FormLabel>Notification Preferences</FormLabel>
            <CheckboxGroup
              value={formData.notifications}
              onChange={(values) =>
                handleCheckboxChange("notifications", values)
              }
            >
              <Stack direction="column">
                <Checkbox value="email">Email</Checkbox>
                <Checkbox value="sms">SMS</Checkbox>
                <Checkbox value="push">Push Notifications</Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
          <Box className="w-full flex items-end justify-end gap-3">
            <Button colorScheme="gray" onClick={prevStep}>
              Back
            </Button>
            <Button
              colorScheme="orange"
              onClick={() => {
                if (validateStep()) {
                  handleSubmit();
                }
              }}
            >
              Sign up
            </Button>
          </Box>
        </VStack>
      )}
    </>
  );
};

export default PersonalForm;
