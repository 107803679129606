const initialState = {
  userData: {},
  passport: {},
  jobList: [],
  job: {},
  eventList: [],
  event: {},
  backPage: 0,
};

const academyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACADEMY_GET_USERDATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "ACADEMY_GET_PASSPORT":
      return {
        ...state,
        passport: action.payload,
      };
    case "ACADEMY_GET_JOBLIST":
      return {
        ...state,
        jobList: action.payload,
      };
    case "ACADEMY_GET_JOB":
      return {
        ...state,
        job: action.payload,
      };
    case "ACADEMY_GET_EVENTLIST":
      return {
        ...state,
        eventList: action.payload,
      };
    case "ACADEMY_GET_EVENT":
      return {
        ...state,
        event: action.payload,
      };
    case "ACADEMY_GET_BACKPAGE":
      return {
        ...state,
        backPage: action.payload,
      };
    default:
      return state;
  }
};

export default academyReducer;
